/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ReportAccountCreateModel } from '../models/report-account-create-model';
import { ReportAlbumAssetCreateModel } from '../models/report-album-asset-create-model';
import { ReportClubCreateModel } from '../models/report-club-create-model';
import { ReportCommentAlbumAssetCreateModel } from '../models/report-comment-album-asset-create-model';
import { ReportCommentPostCreateModel } from '../models/report-comment-post-create-model';
import { ReportFullUpdateModel } from '../models/report-full-update-model';
import { ReportListResponseModel } from '../models/report-list-response-model';
import { ReportModelAllWithDetailPaginationModelResponseModel } from '../models/report-model-all-with-detail-pagination-model-response-model';
import { ReportModelWithClubDetailResponseModel } from '../models/report-model-with-club-detail-response-model';
import { ReportModelWithCommentDetailResponseModel } from '../models/report-model-with-comment-detail-response-model';
import { ReportModelWithPostDetailResponseModel } from '../models/report-model-with-post-detail-response-model';
import { ReportPostCreateModel } from '../models/report-post-create-model';
import { ReportResponseModelResponseModel } from '../models/report-response-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithReportCreateReportCommentPostPost
   */
  static readonly InlivewithReportCreateReportCommentPostPostPath = '/inlivewith/Report/CreateReportCommentPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportCommentPostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportCommentPostPost$Plain$Response(params?: {
    body?: ReportCommentPostCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportCommentPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportCommentPostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportCommentPostPost$Plain(params?: {
    body?: ReportCommentPostCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportCommentPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportCommentPostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportCommentPostPost$Json$Response(params?: {
    body?: ReportCommentPostCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportCommentPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportCommentPostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportCommentPostPost$Json(params?: {
    body?: ReportCommentPostCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportCommentPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportCreateReportAccountPost
   */
  static readonly InlivewithReportCreateReportAccountPostPath = '/inlivewith/Report/CreateReportAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportAccountPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportAccountPost$Plain$Response(params?: {
    body?: ReportAccountCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportAccountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportAccountPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportAccountPost$Plain(params?: {
    body?: ReportAccountCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportAccountPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportAccountPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportAccountPost$Json$Response(params?: {
    body?: ReportAccountCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportAccountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportAccountPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportAccountPost$Json(params?: {
    body?: ReportAccountCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportAccountPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportCreateReportPostPost
   */
  static readonly InlivewithReportCreateReportPostPostPath = '/inlivewith/Report/CreateReportPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportPostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportPostPost$Plain$Response(params?: {
    body?: ReportPostCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportPostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportPostPost$Plain(params?: {
    body?: ReportPostCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportPostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportPostPost$Json$Response(params?: {
    body?: ReportPostCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportPostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportPostPost$Json(params?: {
    body?: ReportPostCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportCreateReportCommentAlbumAssetPost
   */
  static readonly InlivewithReportCreateReportCommentAlbumAssetPostPath = '/inlivewith/Report/CreateReportCommentAlbumAsset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportCommentAlbumAssetPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportCommentAlbumAssetPost$Plain$Response(params?: {
    body?: ReportCommentAlbumAssetCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportCommentAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportCommentAlbumAssetPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportCommentAlbumAssetPost$Plain(params?: {
    body?: ReportCommentAlbumAssetCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportCommentAlbumAssetPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportCommentAlbumAssetPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportCommentAlbumAssetPost$Json$Response(params?: {
    body?: ReportCommentAlbumAssetCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportCommentAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportCommentAlbumAssetPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportCommentAlbumAssetPost$Json(params?: {
    body?: ReportCommentAlbumAssetCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportCommentAlbumAssetPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportCreateReportAlbumAssetPost
   */
  static readonly InlivewithReportCreateReportAlbumAssetPostPath = '/inlivewith/Report/CreateReportAlbumAsset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportAlbumAssetPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportAlbumAssetPost$Plain$Response(params?: {
    body?: ReportAlbumAssetCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportAlbumAssetPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportAlbumAssetPost$Plain(params?: {
    body?: ReportAlbumAssetCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportAlbumAssetPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportAlbumAssetPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportAlbumAssetPost$Json$Response(params?: {
    body?: ReportAlbumAssetCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportAlbumAssetPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportAlbumAssetPost$Json(params?: {
    body?: ReportAlbumAssetCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportAlbumAssetPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportCreateReportClubPost
   */
  static readonly InlivewithReportCreateReportClubPostPath = '/inlivewith/Report/CreateReportClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportClubPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportClubPost$Plain$Response(params?: {
    body?: ReportClubCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportClubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportClubPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportClubPost$Plain(params?: {
    body?: ReportClubCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportClubPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportCreateReportClubPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportClubPost$Json$Response(params?: {
    body?: ReportClubCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportCreateReportClubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportCreateReportClubPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportCreateReportClubPost$Json(params?: {
    body?: ReportClubCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportCreateReportClubPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportUpdateReportPut
   */
  static readonly InlivewithReportUpdateReportPutPath = '/inlivewith/Report/UpdateReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportUpdateReportPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportUpdateReportPut$Plain$Response(params?: {
    body?: ReportFullUpdateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportUpdateReportPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportUpdateReportPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportUpdateReportPut$Plain(params?: {
    body?: ReportFullUpdateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportUpdateReportPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportUpdateReportPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportUpdateReportPut$Json$Response(params?: {
    body?: ReportFullUpdateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportUpdateReportPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportUpdateReportPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportUpdateReportPut$Json(params?: {
    body?: ReportFullUpdateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportUpdateReportPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportGetReportByIdReportIdGet
   */
  static readonly InlivewithReportGetReportByIdReportIdGetPath = '/inlivewith/Report/GetReportById/{reportId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportGetReportByIdReportIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetReportByIdReportIdGet$Plain$Response(params: {
    reportId: string;
    isDelete?: boolean;
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportGetReportByIdReportIdGetPath, 'get');
    if (params) {
      rb.path('reportId', params.reportId, {});
      rb.query('isDelete', params.isDelete, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportGetReportByIdReportIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetReportByIdReportIdGet$Plain(params: {
    reportId: string;
    isDelete?: boolean;
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportGetReportByIdReportIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportGetReportByIdReportIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetReportByIdReportIdGet$Json$Response(params: {
    reportId: string;
    isDelete?: boolean;
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportGetReportByIdReportIdGetPath, 'get');
    if (params) {
      rb.path('reportId', params.reportId, {});
      rb.query('isDelete', params.isDelete, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportGetReportByIdReportIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetReportByIdReportIdGet$Json(params: {
    reportId: string;
    isDelete?: boolean;
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportGetReportByIdReportIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportChangeRepostStatusReportStatusPut
   */
  static readonly InlivewithReportChangeRepostStatusReportStatusPutPath = '/inlivewith/Report/ChangeRepostStatus/{reportStatus}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportChangeRepostStatusReportStatusPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportChangeRepostStatusReportStatusPut$Plain$Response(params: {
    reportStatus: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportChangeRepostStatusReportStatusPutPath, 'put');
    if (params) {
      rb.path('reportStatus', params.reportStatus, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportChangeRepostStatusReportStatusPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportChangeRepostStatusReportStatusPut$Plain(params: {
    reportStatus: string;
    body?: Array<string>
  }): Observable<ReportListResponseModel> {

    return this.inlivewithReportChangeRepostStatusReportStatusPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportListResponseModel>) => r.body as ReportListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportChangeRepostStatusReportStatusPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportChangeRepostStatusReportStatusPut$Json$Response(params: {
    reportStatus: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportChangeRepostStatusReportStatusPutPath, 'put');
    if (params) {
      rb.path('reportStatus', params.reportStatus, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportChangeRepostStatusReportStatusPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportChangeRepostStatusReportStatusPut$Json(params: {
    reportStatus: string;
    body?: Array<string>
  }): Observable<ReportListResponseModel> {

    return this.inlivewithReportChangeRepostStatusReportStatusPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportListResponseModel>) => r.body as ReportListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportDeleteReportReportIdIsDeletePut
   */
  static readonly InlivewithReportDeleteReportReportIdIsDeletePutPath = '/inlivewith/Report/DeleteReport/{reportId}/{isDelete}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportDeleteReportReportIdIsDeletePut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportDeleteReportReportIdIsDeletePut$Plain$Response(params: {
    reportId: string;
    isDelete: boolean;
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportDeleteReportReportIdIsDeletePutPath, 'put');
    if (params) {
      rb.path('reportId', params.reportId, {});
      rb.path('isDelete', params.isDelete, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportDeleteReportReportIdIsDeletePut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportDeleteReportReportIdIsDeletePut$Plain(params: {
    reportId: string;
    isDelete: boolean;
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportDeleteReportReportIdIsDeletePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportDeleteReportReportIdIsDeletePut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportDeleteReportReportIdIsDeletePut$Json$Response(params: {
    reportId: string;
    isDelete: boolean;
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportDeleteReportReportIdIsDeletePutPath, 'put');
    if (params) {
      rb.path('reportId', params.reportId, {});
      rb.path('isDelete', params.isDelete, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportDeleteReportReportIdIsDeletePut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportDeleteReportReportIdIsDeletePut$Json(params: {
    reportId: string;
    isDelete: boolean;
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithReportDeleteReportReportIdIsDeletePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportGetAllReportByCommentPostCommentIdGet
   */
  static readonly InlivewithReportGetAllReportByCommentPostCommentIdGetPath = '/inlivewith/Report/GetAllReportByCommentPost/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportGetAllReportByCommentPostCommentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByCommentPostCommentIdGet$Plain$Response(params: {
    commentId: string;
  }): Observable<StrictHttpResponse<ReportModelWithCommentDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportGetAllReportByCommentPostCommentIdGetPath, 'get');
    if (params) {
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelWithCommentDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportGetAllReportByCommentPostCommentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByCommentPostCommentIdGet$Plain(params: {
    commentId: string;
  }): Observable<ReportModelWithCommentDetailResponseModel> {

    return this.inlivewithReportGetAllReportByCommentPostCommentIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelWithCommentDetailResponseModel>) => r.body as ReportModelWithCommentDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportGetAllReportByCommentPostCommentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByCommentPostCommentIdGet$Json$Response(params: {
    commentId: string;
  }): Observable<StrictHttpResponse<ReportModelWithCommentDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportGetAllReportByCommentPostCommentIdGetPath, 'get');
    if (params) {
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelWithCommentDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportGetAllReportByCommentPostCommentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByCommentPostCommentIdGet$Json(params: {
    commentId: string;
  }): Observable<ReportModelWithCommentDetailResponseModel> {

    return this.inlivewithReportGetAllReportByCommentPostCommentIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelWithCommentDetailResponseModel>) => r.body as ReportModelWithCommentDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportGetAllReportByPostPostIdGet
   */
  static readonly InlivewithReportGetAllReportByPostPostIdGetPath = '/inlivewith/Report/GetAllReportByPost/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportGetAllReportByPostPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByPostPostIdGet$Plain$Response(params: {
    postId: string;
  }): Observable<StrictHttpResponse<ReportModelWithPostDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportGetAllReportByPostPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelWithPostDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportGetAllReportByPostPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByPostPostIdGet$Plain(params: {
    postId: string;
  }): Observable<ReportModelWithPostDetailResponseModel> {

    return this.inlivewithReportGetAllReportByPostPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelWithPostDetailResponseModel>) => r.body as ReportModelWithPostDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportGetAllReportByPostPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByPostPostIdGet$Json$Response(params: {
    postId: string;
  }): Observable<StrictHttpResponse<ReportModelWithPostDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportGetAllReportByPostPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelWithPostDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportGetAllReportByPostPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByPostPostIdGet$Json(params: {
    postId: string;
  }): Observable<ReportModelWithPostDetailResponseModel> {

    return this.inlivewithReportGetAllReportByPostPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelWithPostDetailResponseModel>) => r.body as ReportModelWithPostDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportGetAllReportByClubClubIdGet
   */
  static readonly InlivewithReportGetAllReportByClubClubIdGetPath = '/inlivewith/Report/GetAllReportByClub/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportGetAllReportByClubClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByClubClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ReportModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportGetAllReportByClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportGetAllReportByClubClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByClubClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<ReportModelWithClubDetailResponseModel> {

    return this.inlivewithReportGetAllReportByClubClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelWithClubDetailResponseModel>) => r.body as ReportModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportGetAllReportByClubClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByClubClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ReportModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportGetAllReportByClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportGetAllReportByClubClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithReportGetAllReportByClubClubIdGet$Json(params: {
    clubId: string;
  }): Observable<ReportModelWithClubDetailResponseModel> {

    return this.inlivewithReportGetAllReportByClubClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelWithClubDetailResponseModel>) => r.body as ReportModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost
   */
  static readonly InlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPostPath = '/inlivewith/Report/GetAllReportByAccountAndClub/{accountAdminClubId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Plain$Response(params: {
    accountAdminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPostPath, 'post');
    if (params) {
      rb.path('accountAdminClubId', params.accountAdminClubId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Plain(params: {
    accountAdminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Json$Response(params: {
    accountAdminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPostPath, 'post');
    if (params) {
      rb.path('accountAdminClubId', params.accountAdminClubId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Json(params: {
    accountAdminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithReportGetAllReportByAccountAndClubAccountAdminClubIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

}
