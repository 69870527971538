/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountClubListResponseModel } from '../models/account-club-list-response-model';
import { AccountClubResponseModel } from '../models/account-club-response-model';
import { AccountPaginationModelResponseModel } from '../models/account-pagination-model-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { ClubGuidUpdateInputModel } from '../models/club-guid-update-input-model';
import { ClubGuidsUpdateInputModel } from '../models/club-guids-update-input-model';
import { ClubHashtagListResponseModel } from '../models/club-hashtag-list-response-model';
import { ClubInputModel } from '../models/club-input-model';
import { ClubObjectCreateInputModel } from '../models/club-object-create-input-model';
import { ClubPaginationModelResponseModel } from '../models/club-pagination-model-response-model';
import { ClubResponseModel } from '../models/club-response-model';
import { ClubStringUpdateInputModel } from '../models/club-string-update-input-model';
import { ClubTypesEnumResponseModel } from '../models/club-types-enum-response-model';
import { HashtagPaginationModelResponseModel } from '../models/hashtag-pagination-model-response-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class ClubService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithClubsPageGet
   */
  static readonly InlivewithClubsPageGetPath = '/inlivewith/Clubs/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPageGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPageGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsPrivateGet
   */
  static readonly InlivewithClubsPrivateGetPath = '/inlivewith/Clubs/Private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPrivateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPrivateGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPrivateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPrivateGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsPrivateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPrivateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPrivateGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPrivateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPrivateGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsPrivateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsPublicGet
   */
  static readonly InlivewithClubsPublicGetPath = '/inlivewith/Clubs/Public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPublicGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPublicGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPublicGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPublicGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsPublicGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPublicGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPublicGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPublicGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPublicGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsPublicGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsActivatedGet
   */
  static readonly InlivewithClubsActivatedGetPath = '/inlivewith/Clubs/Activated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsActivatedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsActivatedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsActivatedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsActivatedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsActivatedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsActivatedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsActivatedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsActivatedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsActivatedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsActivatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsDeletedGet
   */
  static readonly InlivewithClubsDeletedGetPath = '/inlivewith/Clubs/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsDeletedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsDeletedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsDeletedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsDeletedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsDeletedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsDeletedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsDeletedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsDeletedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsDeletedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsDeletedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsLikeNameNameGet
   */
  static readonly InlivewithClubsLikeNameNameGetPath = '/inlivewith/Clubs/LikeName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsLikeNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Plain$Response(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsLikeNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Plain(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsLikeNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsLikeNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Json$Response(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsLikeNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Json(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsLikeNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsCategoryCategoryGet
   */
  static readonly InlivewithClubsCategoryCategoryGetPath = '/inlivewith/Clubs/Category/{category}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsCategoryCategoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsCategoryCategoryGet$Plain$Response(params: {
    category: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsCategoryCategoryGetPath, 'get');
    if (params) {
      rb.path('category', params.category, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsCategoryCategoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsCategoryCategoryGet$Plain(params: {
    category: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsCategoryCategoryGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsCategoryCategoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsCategoryCategoryGet$Json$Response(params: {
    category: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsCategoryCategoryGetPath, 'get');
    if (params) {
      rb.path('category', params.category, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsCategoryCategoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsCategoryCategoryGet$Json(params: {
    category: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsCategoryCategoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsTypeTypeGet
   */
  static readonly InlivewithClubsTypeTypeGetPath = '/inlivewith/Clubs/Type/{type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsTypeTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsTypeTypeGet$Plain$Response(params: {
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsTypeTypeGetPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsTypeTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsTypeTypeGet$Plain(params: {
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsTypeTypeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsTypeTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsTypeTypeGet$Json$Response(params: {
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsTypeTypeGetPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsTypeTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsTypeTypeGet$Json(params: {
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsTypeTypeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsAdminAccountIdGet
   */
  static readonly InlivewithClubsAdminAccountIdGetPath = '/inlivewith/Clubs/Admin/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsAdminAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAdminAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsAdminAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsAdminAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAdminAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsAdminAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsAdminAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAdminAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsAdminAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsAdminAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAdminAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsAdminAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsAccountAccountIdGet
   */
  static readonly InlivewithClubsAccountAccountIdGetPath = '/inlivewith/Clubs/Account/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsAccountAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccountAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsAccountAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccountAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsAccountAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsAccountAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccountAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsAccountAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccountAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithClubsAccountAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubByIdClubIdGet
   */
  static readonly InlivewithClubByIdClubIdGetPath = '/inlivewith/Club/ById/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubByIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Plain$Response(params: {
    clubId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubByIdClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubByIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Plain(params: {
    clubId: string;
    details?: boolean;
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubByIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubByIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Json$Response(params: {
    clubId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubByIdClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubByIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Json(params: {
    clubId: string;
    details?: boolean;
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubByIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAdministratorsClubIdGet
   */
  static readonly InlivewithClubAdministratorsClubIdGetPath = '/inlivewith/Club/Administrators/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAdministratorsClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAdministratorsClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAdministratorsClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithClubAdministratorsClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAdministratorsClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAdministratorsClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAdministratorsClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithClubAdministratorsClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHasAccessAccountIdClubIdGet
   */
  static readonly InlivewithClubHasAccessAccountIdClubIdGetPath = '/inlivewith/Club/HasAccess/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHasAccessAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHasAccessAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHasAccessAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHasAccessAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHasAccessAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubHasAccessAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHasAccessAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHasAccessAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHasAccessAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHasAccessAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHasAccessAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubHasAccessAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCreateAccountIdPost
   */
  static readonly InlivewithClubCreateAccountIdPostPath = '/inlivewith/Club/Create/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCreateAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountIdPost$Plain$Response(params: {
    accountId: string;
    body?: ClubObjectCreateInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCreateAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCreateAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountIdPost$Plain(params: {
    accountId: string;
    body?: ClubObjectCreateInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubCreateAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCreateAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountIdPost$Json$Response(params: {
    accountId: string;
    body?: ClubObjectCreateInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCreateAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCreateAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountIdPost$Json(params: {
    accountId: string;
    body?: ClubObjectCreateInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubCreateAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubNameClubIdGet
   */
  static readonly InlivewithClubNameClubIdGetPath = '/inlivewith/Club/Name/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubNameClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Json(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubNameClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubNameAdminIdClubIdPut
   */
  static readonly InlivewithClubNameAdminIdClubIdPutPath = '/inlivewith/Club/Name/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubNameAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubNameAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubDescriptionClubIdGet
   */
  static readonly InlivewithClubDescriptionClubIdGetPath = '/inlivewith/Club/Description/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDescriptionClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubDescriptionClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDescriptionClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDescriptionClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubDescriptionClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubDescriptionClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDescriptionClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubDescriptionClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDescriptionClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDescriptionClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubDescriptionClubIdGet$Json(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubDescriptionClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubDescriptionAdminIdClubIdPut
   */
  static readonly InlivewithClubDescriptionAdminIdClubIdPutPath = '/inlivewith/Club/Description/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDescriptionAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDescriptionAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDescriptionAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubDescriptionAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDescriptionAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDescriptionAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDescriptionAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubDescriptionAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubTypeClubIdGet
   */
  static readonly InlivewithClubTypeClubIdGetPath = '/inlivewith/Club/Type/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubTypeClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubTypeClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubTypesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubTypeClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubTypeClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubTypeClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<ClubTypesEnumResponseModel> {

    return this.inlivewithClubTypeClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumResponseModel>) => r.body as ClubTypesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubTypeClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubTypeClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubTypesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubTypeClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubTypeClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubTypeClubIdGet$Json(params: {
    clubId: string;
  }): Observable<ClubTypesEnumResponseModel> {

    return this.inlivewithClubTypeClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumResponseModel>) => r.body as ClubTypesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubTypeAdminIdClubIdPut
   */
  static readonly InlivewithClubTypeAdminIdClubIdPutPath = '/inlivewith/Club/Type/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubTypeAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubTypeAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubTypeAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubTypeAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubTypeAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubTypeAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubTypeAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubTypeAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCategoryClubIdGet
   */
  static readonly InlivewithClubCategoryClubIdGetPath = '/inlivewith/Club/Category/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCategoryClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCategoryClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubTypesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCategoryClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCategoryClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCategoryClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<ClubTypesEnumResponseModel> {

    return this.inlivewithClubCategoryClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumResponseModel>) => r.body as ClubTypesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCategoryClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCategoryClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubTypesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCategoryClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCategoryClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCategoryClubIdGet$Json(params: {
    clubId: string;
  }): Observable<ClubTypesEnumResponseModel> {

    return this.inlivewithClubCategoryClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumResponseModel>) => r.body as ClubTypesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCategoryAdminIdClubIdPut
   */
  static readonly InlivewithClubCategoryAdminIdClubIdPutPath = '/inlivewith/Club/Category/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCategoryAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCategoryAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCategoryAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubCategoryAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCategoryAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCategoryAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCategoryAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubCategoryAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAvatarClubIdGet
   */
  static readonly InlivewithClubAvatarClubIdGetPath = '/inlivewith/Club/Avatar/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAvatarClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAvatarClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubAvatarClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAvatarClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAvatarClubIdGet$Json(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubAvatarClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAvatarUploadAdminIdClubIdPut
   */
  static readonly InlivewithClubAvatarUploadAdminIdClubIdPutPath = '/inlivewith/Club/Avatar/Upload/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarUploadAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarUploadAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarUploadAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarUploadAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarUploadAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubAvatarUploadAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarUploadAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarUploadAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarUploadAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarUploadAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarUploadAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubAvatarUploadAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAvatarAdminIdClubIdDelete
   */
  static readonly InlivewithClubAvatarAdminIdClubIdDeletePath = '/inlivewith/Club/Avatar/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAvatarAdminIdClubIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAvatarAdminIdClubIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAvatarAdminIdClubIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAvatarAdminIdClubIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubIsPublicClubIdGet
   */
  static readonly InlivewithClubIsPublicClubIdGetPath = '/inlivewith/Club/IsPublic/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubIsPublicClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPublicClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubIsPublicClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubIsPublicClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPublicClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubIsPublicClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubIsPublicClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPublicClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubIsPublicClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubIsPublicClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPublicClubIdGet$Json(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubIsPublicClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubPrivateAdminIdClubIdPut
   */
  static readonly InlivewithClubPrivateAdminIdClubIdPutPath = '/inlivewith/Club/Private/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPrivateAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPrivateAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPrivateAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubPrivateAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPrivateAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPrivateAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPrivateAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubPrivateAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubPublicAdminIdClubIdPut
   */
  static readonly InlivewithClubPublicAdminIdClubIdPutPath = '/inlivewith/Club/Public/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPublicAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPublicAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPublicAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubPublicAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPublicAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPublicAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPublicAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubPublicAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubUsersBlockedClubIdGet
   */
  static readonly InlivewithClubUsersBlockedClubIdGetPath = '/inlivewith/Club/Users/Blocked/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUsersBlockedClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubUsersBlockedClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUsersBlockedClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUsersBlockedClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubUsersBlockedClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithClubUsersBlockedClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUsersBlockedClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubUsersBlockedClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUsersBlockedClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUsersBlockedClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubUsersBlockedClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithClubUsersBlockedClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubUserBlockAdminIdClubIdPut
   */
  static readonly InlivewithClubUserBlockAdminIdClubIdPutPath = '/inlivewith/Club/User/Block/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUserBlockAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUserBlockAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidUpdateInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUserBlockAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUserBlockAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUserBlockAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidUpdateInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithClubUserBlockAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUserBlockAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUserBlockAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidUpdateInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUserBlockAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUserBlockAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUserBlockAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidUpdateInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithClubUserBlockAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubUserUnblockAdminIdClubIdPut
   */
  static readonly InlivewithClubUserUnblockAdminIdClubIdPutPath = '/inlivewith/Club/User/Unblock/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUserUnblockAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUserUnblockAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidUpdateInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUserUnblockAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUserUnblockAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUserUnblockAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidUpdateInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithClubUserUnblockAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUserUnblockAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUserUnblockAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidUpdateInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUserUnblockAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUserUnblockAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUserUnblockAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidUpdateInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithClubUserUnblockAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHashtagsClubIdGet
   */
  static readonly InlivewithClubHashtagsClubIdGetPath = '/inlivewith/Club/Hashtags/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithClubHashtagsClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithClubHashtagsClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHashtagsAdminIdClubIdPut
   */
  static readonly InlivewithClubHashtagsAdminIdClubIdPutPath = '/inlivewith/Club/Hashtags/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHashtagsAdminIdClubIdDelete
   */
  static readonly InlivewithClubHashtagsAdminIdClubIdDeletePath = '/inlivewith/Club/Hashtags/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHashtagsAddAdminIdClubIdPost
   */
  static readonly InlivewithClubHashtagsAddAdminIdClubIdPostPath = '/inlivewith/Club/Hashtags/Add/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAddAdminIdClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAddAdminIdClubIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAddAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAddAdminIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAddAdminIdClubIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAddAdminIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAddAdminIdClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAddAdminIdClubIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAddAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAddAdminIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAddAdminIdClubIdPost$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAddAdminIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccessLevelIsAnonymousClubIdGet
   */
  static readonly InlivewithClubAccessLevelIsAnonymousClubIdGetPath = '/inlivewith/Club/AccessLevel/IsAnonymous/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelIsAnonymousClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsAnonymousClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelIsAnonymousClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelIsAnonymousClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsAnonymousClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccessLevelIsAnonymousClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelIsAnonymousClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsAnonymousClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelIsAnonymousClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelIsAnonymousClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsAnonymousClubIdGet$Json(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccessLevelIsAnonymousClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccessLevelIsPlusClubIdGet
   */
  static readonly InlivewithClubAccessLevelIsPlusClubIdGetPath = '/inlivewith/Club/AccessLevel/IsPlus/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelIsPlusClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsPlusClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelIsPlusClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelIsPlusClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsPlusClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccessLevelIsPlusClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelIsPlusClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsPlusClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelIsPlusClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelIsPlusClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsPlusClubIdGet$Json(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccessLevelIsPlusClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccessLevelAnonymousAdminIdClubIdPut
   */
  static readonly InlivewithClubAccessLevelAnonymousAdminIdClubIdPutPath = '/inlivewith/Club/AccessLevel/Anonymous/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelAnonymousAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelAnonymousAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccessLevelPlusAdminIdClubIdPut
   */
  static readonly InlivewithClubAccessLevelPlusAdminIdClubIdPutPath = '/inlivewith/Club/AccessLevel/Plus/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelPlusAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelPlusAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelPlusAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelPlusAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelPlusAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubAccessLevelPlusAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelPlusAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelPlusAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelPlusAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelPlusAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelPlusAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubAccessLevelPlusAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubMembersClubIdGet
   */
  static readonly InlivewithClubMembersClubIdGetPath = '/inlivewith/Club/Members/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithClubMembersClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithClubMembersClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubMembersAdminIdClubIdPost
   */
  static readonly InlivewithClubMembersAdminIdClubIdPostPath = '/inlivewith/Club/Members/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersAdminIdClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAdminIdClubIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersAdminIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAdminIdClubIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithClubMembersAdminIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersAdminIdClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAdminIdClubIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersAdminIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAdminIdClubIdPost$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithClubMembersAdminIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubIsDeletedClubIdGet
   */
  static readonly InlivewithClubIsDeletedClubIdGetPath = '/inlivewith/Club/IsDeleted/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubIsDeletedClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsDeletedClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubIsDeletedClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubIsDeletedClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsDeletedClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubIsDeletedClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubIsDeletedClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsDeletedClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubIsDeletedClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubIsDeletedClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsDeletedClubIdGet$Json(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubIsDeletedClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubDeleteAdminIdClubIdDelete
   */
  static readonly InlivewithClubDeleteAdminIdClubIdDeletePath = '/inlivewith/Club/Delete/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDeleteAdminIdClubIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDeleteAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDeleteAdminIdClubIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubDeleteAdminIdClubIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDeleteAdminIdClubIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDeleteAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDeleteAdminIdClubIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseModel> {

    return this.inlivewithClubDeleteAdminIdClubIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseModel>) => r.body as ClubResponseModel)
    );
  }

}
