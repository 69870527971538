<div class="w-100 flex align-items-start">
  <div class="post-zone cursor-pointer col-8" *ngIf="post" [ngClass]="{'post-selected': selectedPost && checkClicked}"
    [ngClass]="{'post-celeb': post.author?.accessLevelInClub == 'ACCESSLEVEL_CELEBRITY'}" (click)="selectedPostEvent()">
    <div class="flex flex-row justify-content-between" style="box-sizing: border-box;">
      <div class="flex align-items-center">
        <div class="zone-avatar">
          <img [src]="post.author?.avatar! ? post.author?.avatar : defaultAvatar" class="fit-size cursor-pointer">
        </div>
        <div class="flex flex-column ml-2">
          <div class="flex align-items-center">
            <span class="mr-2 name-author cursor-pointer" (click)="openAccountInfo()">{{post.author?.nickName!}} </span>
            <span class="ml-2 label-role" *ngIf="roleOfPostAuthor == 'ROLE_ADMIN'">
              {{'ADMIN' | translate }}
            </span>
            <span class="ml-2 label-role-creator" *ngIf="roleOfPostAuthor == 'ROLE_CREATOR'">
              {{'CREATOR' | translate }}
            </span>
            <span class="ml-2 label-role-celeb" *ngIf="post.author?.accessLevelInClub! == 'ACCESSLEVEL_CELEBRITY'">
              {{'CELEBRITY' | translate }}
            </span>
          </div>
          <span class="text-disabled fs-11 mt-2">{{timeAgo(post.dateCreated!)}}</span>
        </div>
      </div>
      <div>
        <span class="text-red cursor-pointer">
          {{'DELETE' | translate}}
        </span>
      </div>
    </div>
    <div class="mb-3 mt-3">
      <span class="stage">
        {{post.stage?.name!}}
      </span>
    </div>
    <div class="mt-3 mb-2" [class.show]="show">
      <div id="content" class="show overflow-wrap-breakword" style="white-space: break-spaces;"
        [innerHTML]="urlify(post!.message! | limitString:completeText)"></div>
    </div>
    <div class="text-right cursor-pointer" *ngIf="post?.message! && post?.message!.length! > 400">
      <span style="color:#3b82f6" (click)="handleText()">{{(show ? 'SHOW_LESS' : 'SEE_MORE') | translate}}</span>
    </div>

    <div class="flex w-100 h-100 cursor-pointer"
      [ngStyle]="post!.mediaPosts?.length == 1 ? {'max-height':'50rem'}:   post!.mediaPosts?.length == 2 ?  {'max-height':'40rem'}: {'max-height':'30rem'}"
      *ngIf="post?.mediaPosts?.length! > 0 && checkTypeMedia(post?.mediaPosts![0]) !== 'AUDIO'">

      <div class="flex justify-content-center m-2 media position-relative"
        *ngFor="let asset of post.mediaPosts; let i = index">
        <img loading="lazy"
          *ngIf="(checkTypeMedia(asset) == 'IMAGE' || checkTypeMedia(asset) == 'VIDEO') && checkTypeMedia(asset) != 'RECORD'"
          [src]="getUrl(asset)" alt="" class="fit-size" (load)="onLoad($event, i)" />
        <video *ngIf="getUrl(asset) == asset?.url! && checkTypeMedia(asset) == 'RECORD'" [id]="asset"
          [src]="getUrl(asset)" class="fit-size" controls [muted]="true"></video>
        <div *ngIf="checkTypeMedia(asset) == 'VIDEO'" class="wrap-video">
          <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
        </div>
        <div class="flex w-100 h-100 justify-content-center align-items-center"
          *ngIf="checkTypeMedia(asset) == 'UNKNOWN'">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem; margin: 3rem;"></i>
        </div>
      </div>
    </div>


    <div *ngIf="post?.mediaPosts?.length! > 0">
      <div class="flex justify-content-center media w-100" *ngFor="let asset of post.mediaPosts; let i = index">
        <div class="flex flex-column w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
          <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio>
        </div>
      </div>
    </div>

    <div class="flex justify-content-between align-items-center mt-5">
      <div class="flex">
        <div class="flex" style="width: 72px">
          <i class="pi pi-heart-fill cursor-pointer" style="font-size: 24px; color: #D1DEFB;"></i>
          <span class="fs-12 ml-2 cursor-pointer">{{post!.totalReact!}}</span>
        </div>
        <div class="flex ml-5 cursor-pointer align-items-center" style="height: 23px;">
          <img src="../../../assets/images/svgs/comment-fill.svg">
          <span class="fs-12 ml-2">{{post!.totalComment!}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4 ml-3 mt-3" style="background-color: white; border-radius: 10px;" *ngIf="post && checkClicked">
    <div class="zone-content-asset">
      <div class="pt-3 zone-comment-asset">
        <div class="flex ml-3 mb-3">
          <div *ngFor="let control of initZoneControl()">
            <span class="mr-5 cursor-pointer" (click)="control.command()"
              [ngStyle]="currentControl.id === control.id ? {'color': '#0058FF'} : {'color':'#A1A7C4'}">
              {{control.title}} ({{control.total}})
            </span>
          </div>
        </div>
        <div *ngIf="currentControl.id == 1">
          <div class="login-modal">
            <div class="content">
              <div class="list-comment">
                <div class="comments">
                  <ng-container *ngFor="let item of listCommentsPost">
                    <div class="item">
                      <div class="avatar">
                        <img class="fit-size cursor-pointer"
                          [src]="item?.author?.avatar! ? item.author?.avatar! : defaultAvatar" alt="">
                      </div>
                      <div class="detail">
                        <div class="name">
                          {{item?.author?.nickName!}}
                        </div>
                        <div class="content">
                          {{item?.message}}
                        </div>
                        <div style="position: relative">

                          <div class="flex justify-content-between zone-arrow" *ngIf="checkOverflow(item?.id!)">
                            <i class="pi pi-arrow-left arrow cursor-pointer" (click)="scrollTo(item.id!)"></i>
                            <i class="pi pi-arrow-right arrow cursor-pointer" (click)="scrollTo(item.id!, false)"></i>
                          </div>

                          <div class="flex list-media" *ngIf="item?.mediaCommentPosts!.length! > 0" [id]="item.id">
                            <div class="mr-1 w-100" *ngFor="let asset of item?.mediaCommentPosts!">

                              <div class="flex justify-content-center align-items-center ml-5"
                                *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')" style="height: 70px">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                              </div>

                              <div class="mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')"
                                style="height: 100px; width: auto;">
                                <p-image [preview]="true" [src]="getUrl(asset)" alt=""></p-image>
                              </div>

                              <div class="w-100" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                <!-- <app-video-layer *ngIf="asset?.url!" [layout]="'full'" [url]="asset?.url!"
                                [poster]="getUrl(asset)">
                              </app-video-layer> -->
                              </div>

                              <div class="w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio>
                              </div>

                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                    <div style="margin-left: 3.5rem;
                  border-left: 8px solid #D1DEFB;" *ngIf="item && item?.inverseParent?.length! > 0">
                      <div *ngFor="let itemChild of item?.inverseParent">
                        <div class="item-child">
                          <div class="avatar">
                            <img class="fit-size cursor-pointer"
                              [src]="itemChild?.author?.avatar! ? itemChild?.author?.avatar! : defaultAvatar" alt="">
                          </div>
                          <div class="detail">
                            <div class="name">
                              {{itemChild?.author?.nickName!}}
                            </div>
                            <div class="content">
                              {{itemChild?.message}}
                            </div>
                            <div class="mt-5 mb-5" *ngIf="itemChild?.mediaCommentPosts!.length! > 0"
                              style="position: relative">
                              <div class="flex justify-content-between zone-arrow"
                                *ngIf="checkOverflow(itemChild?.id!)">
                                <i class="pi pi-arrow-left arrow cursor-pointer" (click)="scrollTo(itemChild.id!)"></i>
                                <i class="pi pi-arrow-right arrow cursor-pointer"
                                  (click)="scrollTo(itemChild.id!, false)"></i>
                              </div>

                              <div class="flex list-media" *ngIf="itemChild?.mediaCommentPosts!.length! > 0"
                                [id]="itemChild.id">
                                <div class="w-100" *ngFor="let asset of itemChild?.mediaCommentPosts!">

                                  <div class="flex justify-content-center align-items-center ml-5"
                                    *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')" style="height: 70px">
                                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                  </div>

                                  <div class="flex mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')"
                                    style="height: 100px">
                                    <p-image [preview]="true" [src]="getUrl(asset)" alt=""></p-image>
                                  </div>

                                  <div class="flex mr-4" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                    <!-- <app-video-layer *ngIf="asset?.url!" [layout]="'full'" [url]="asset?.url!"
                                    [poster]="getUrl(asset)">
                                  </app-video-layer> -->
                                  </div>

                                  <div class="flex w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                    <!-- <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio> -->
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="currentControl.id == 0">
          <div class="zone-react">
            <div *ngFor="let liker of listReactsPost">
              <div class="flex align-items-center">
                <div class="avatar">
                  <img class="fit-size" [src]="liker.account?.avatar! ? liker.account?.avatar! : defaultAvatar">
                </div>
                <div class="ml-3">
                  <span>
                    {{liker.account?.nickName!}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <ng-container *ngIf="showConfirmDeletePost">
    <app-confirm-delete-post [(showDialog)]="showConfirmDeletePost" [post]="post" (isDelete)="deletePost()">
    </app-confirm-delete-post>
</ng-container> -->

</div>
