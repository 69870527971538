/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountClubBannedCreateInputModel } from '../models/account-club-banned-create-input-model';
import { AccountClubBannedResponseModelPaginationModelResponseModel } from '../models/account-club-banned-response-model-pagination-model-response-model';
import { AccountClubBannedResponseModelResponseModel } from '../models/account-club-banned-response-model-response-model';
import { AccountClubListResponseModel } from '../models/account-club-list-response-model';
import { AccountResponseGeneralPaginationModelResponseModel } from '../models/account-response-general-pagination-model-response-model';
import { AccountResponseWithRoleInCLubModelPaginationModelResponseModel } from '../models/account-response-with-role-in-c-lub-model-pagination-model-response-model';
import { ApprovalRequestAccessLevelModel } from '../models/approval-request-access-level-model';
import { ApprovalRequestModel } from '../models/approval-request-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { CelebrityAliasCreateModel } from '../models/celebrity-alias-create-model';
import { CelebrityAliasResponseModelPaginationModelResponseModel } from '../models/celebrity-alias-response-model-pagination-model-response-model';
import { CelebrityAliasResponseModelResponseModel } from '../models/celebrity-alias-response-model-response-model';
import { CelebrityAliasUpdateModel } from '../models/celebrity-alias-update-model';
import { ClubCategoriesEnumResponseModel } from '../models/club-categories-enum-response-model';
import { ClubFilterInputModel } from '../models/club-filter-input-model';
import { ClubGuidsUpdateInputModel } from '../models/club-guids-update-input-model';
import { ClubHashtagListResponseModel } from '../models/club-hashtag-list-response-model';
import { ClubInputModel } from '../models/club-input-model';
import { ClubMemberRequestModel } from '../models/club-member-request-model';
import { ClubNotificationModelAdmin } from '../models/club-notification-model-admin';
import { ClubNotificationModelAdminResponseModel } from '../models/club-notification-model-admin-response-model';
import { ClubNotificationModelMember } from '../models/club-notification-model-member';
import { ClubNotificationModelMemberResponseModel } from '../models/club-notification-model-member-response-model';
import { ClubObjectCreateInputModel } from '../models/club-object-create-input-model';
import { ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel } from '../models/club-response-with-total-member-and-go-inlive-model-pagination-model-response-model';
import { ClubResponseWithTotalMemberAndGoInliveModelResponseModel } from '../models/club-response-with-total-member-and-go-inlive-model-response-model';
import { ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel } from '../models/club-response-with-total-member-and-role-model-pagination-model-response-model';
import { ClubResponseWithTotalMemberModelPaginationModelResponseModel } from '../models/club-response-with-total-member-model-pagination-model-response-model';
import { ClubResponseWithTotalMemberModelResponseModel } from '../models/club-response-with-total-member-model-response-model';
import { ClubStringUpdateInputModel } from '../models/club-string-update-input-model';
import { ClubTypesEnumResponseModel } from '../models/club-types-enum-response-model';
import { ClubUpdatePolicy } from '../models/club-update-policy';
import { HashtagPaginationModelResponseModel } from '../models/hashtag-pagination-model-response-model';
import { Int64ResponseModel } from '../models/int-64-response-model';
import { InvitationAccountClubListResponseModel } from '../models/invitation-account-club-list-response-model';
import { InvitationClubResponseModelPaginationModelResponseModel } from '../models/invitation-club-response-model-pagination-model-response-model';
import { MultipleSearchCriteriaOfClub } from '../models/multiple-search-criteria-of-club';
import { MultipleSearchCriteriaWithCheckAccountOfClub } from '../models/multiple-search-criteria-with-check-account-of-club';
import { ProcessFileModel } from '../models/process-file-model';
import { RequestAccessLevelInClubPaginationModelResponseModel } from '../models/request-access-level-in-club-pagination-model-response-model';
import { RequestJoinOfClubPaginationModelResponseModel } from '../models/request-join-of-club-pagination-model-response-model';
import { SetRoleAccountInClubModel } from '../models/set-role-account-in-club-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class ClubService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithClubAccountUnBannedAdminClubIdClubIdPut
   */
  static readonly InlivewithClubAccountUnBannedAdminClubIdClubIdPutPath = '/inlivewith/Club/Account/UnBanned/{adminClubId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Plain$Response(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountUnBannedAdminClubIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminClubId', params.adminClubId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Plain(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountClubBannedResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>) => r.body as AccountClubBannedResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json$Response(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountUnBannedAdminClubIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminClubId', params.adminClubId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountClubBannedResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>) => r.body as AccountClubBannedResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountCheckMemberClubIdAccountIdGet
   */
  static readonly InlivewithClubAccountCheckMemberClubIdAccountIdGetPath = '/inlivewith/Club/Account/CheckMember/{clubId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountCheckMemberClubIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCheckMemberClubIdAccountIdGet$Plain$Response(params: {
    clubId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountCheckMemberClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountCheckMemberClubIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCheckMemberClubIdAccountIdGet$Plain(params: {
    clubId: string;
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccountCheckMemberClubIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountCheckMemberClubIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCheckMemberClubIdAccountIdGet$Json$Response(params: {
    clubId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountCheckMemberClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountCheckMemberClubIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCheckMemberClubIdAccountIdGet$Json(params: {
    clubId: string;
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccountCheckMemberClubIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet
   */
  static readonly InlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGetPath = '/inlivewith/Club/Account/CheckMembersIsBanned/{clubId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet$Plain$Response(params: {
    clubId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet$Plain(params: {
    clubId: string;
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet$Json$Response(params: {
    clubId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet$Json(params: {
    clubId: string;
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccountCheckMembersIsBannedClubIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet
   */
  static readonly InlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGetPath = '/inlivewith/Club/Account/GetInvitationAccountClub/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Plain$Response(params: {
    adminId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<InvitationClubResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationClubResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Plain(params: {
    adminId: string;
    clubId: string;
  }): Observable<InvitationClubResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationClubResponseModelPaginationModelResponseModel>) => r.body as InvitationClubResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Json$Response(params: {
    adminId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<InvitationClubResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationClubResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Json(params: {
    adminId: string;
    clubId: string;
  }): Observable<InvitationClubResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountGetInvitationAccountClubAdminIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationClubResponseModelPaginationModelResponseModel>) => r.body as InvitationClubResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsPageGet
   */
  static readonly InlivewithClubsPageGetPath = '/inlivewith/Clubs/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPageGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPageGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsByAdministratorAdminIdGet
   */
  static readonly InlivewithClubsByAdministratorAdminIdGetPath = '/inlivewith/Clubs/ByAdministrator/{adminId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByAdministratorAdminIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAdministratorAdminIdGet$Plain$Response(params: {
    adminId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByAdministratorAdminIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByAdministratorAdminIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAdministratorAdminIdGet$Plain(params: {
    adminId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel> {

    return this.inlivewithClubsByAdministratorAdminIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByAdministratorAdminIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAdministratorAdminIdGet$Json$Response(params: {
    adminId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByAdministratorAdminIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByAdministratorAdminIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAdministratorAdminIdGet$Json(params: {
    adminId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel> {

    return this.inlivewithClubsByAdministratorAdminIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsByAccountAccountIdGet
   */
  static readonly InlivewithClubsByAccountAccountIdGetPath = '/inlivewith/Clubs/ByAccount/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByAccountAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAccountAccountIdGet$Plain$Response(params: {
    accountId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByAccountAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAccountAccountIdGet$Plain(params: {
    accountId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel> {

    return this.inlivewithClubsByAccountAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByAccountAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAccountAccountIdGet$Json$Response(params: {
    accountId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByAccountAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAccountAccountIdGet$Json(params: {
    accountId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel> {

    return this.inlivewithClubsByAccountAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndGoInliveModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet
   */
  static readonly InlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGetPath = '/inlivewith/Clubs/SearchNameClubsThatAccountRoleIsAdmin/{name}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet$Plain$Response(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet$Plain(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet$Json$Response(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet$Json(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsSearchNameClubsThatAccountRoleIsAdminNameAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet
   */
  static readonly InlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGetPath = '/inlivewith/Clubs/SearchNameClubsThatAccountRoleIsAnymous/{name}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet$Plain$Response(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet$Plain(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet$Json$Response(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet$Json(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsSearchNameClubsThatAccountRoleIsAnymousNameAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubByIdClubIdGet
   */
  static readonly InlivewithClubByIdClubIdGetPath = '/inlivewith/Club/ById/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubByIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubByIdClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubByIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<ClubResponseWithTotalMemberAndGoInliveModelResponseModel> {

    return this.inlivewithClubByIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndGoInliveModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubByIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubByIdClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubByIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Json(params: {
    clubId: string;
  }): Observable<ClubResponseWithTotalMemberAndGoInliveModelResponseModel> {

    return this.inlivewithClubByIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndGoInliveModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHasAccessAccountIdClubIdGet
   */
  static readonly InlivewithClubHasAccessAccountIdClubIdGetPath = '/inlivewith/Club/HasAccess/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHasAccessAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHasAccessAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHasAccessAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHasAccessAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHasAccessAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubHasAccessAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHasAccessAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHasAccessAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHasAccessAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHasAccessAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHasAccessAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubHasAccessAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsByMultipleFilterPost
   */
  static readonly InlivewithClubsByMultipleFilterPostPath = '/inlivewith/Clubs/ByMultipleFilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByMultipleFilterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsByMultipleFilterPost$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
    body?: MultipleSearchCriteriaOfClub
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByMultipleFilterPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByMultipleFilterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsByMultipleFilterPost$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
    body?: MultipleSearchCriteriaOfClub
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsByMultipleFilterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByMultipleFilterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsByMultipleFilterPost$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
    body?: MultipleSearchCriteriaOfClub
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByMultipleFilterPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByMultipleFilterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsByMultipleFilterPost$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
    body?: MultipleSearchCriteriaOfClub
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsByMultipleFilterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsByMultipleFilterWithCheckAccountInClubPost
   */
  static readonly InlivewithClubsByMultipleFilterWithCheckAccountInClubPostPath = '/inlivewith/Clubs/ByMultipleFilterWithCheckAccountInClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByMultipleFilterWithCheckAccountInClubPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsByMultipleFilterWithCheckAccountInClubPost$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
    body?: MultipleSearchCriteriaWithCheckAccountOfClub
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByMultipleFilterWithCheckAccountInClubPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByMultipleFilterWithCheckAccountInClubPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsByMultipleFilterWithCheckAccountInClubPost$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
    body?: MultipleSearchCriteriaWithCheckAccountOfClub
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsByMultipleFilterWithCheckAccountInClubPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByMultipleFilterWithCheckAccountInClubPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsByMultipleFilterWithCheckAccountInClubPost$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
    body?: MultipleSearchCriteriaWithCheckAccountOfClub
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByMultipleFilterWithCheckAccountInClubPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByMultipleFilterWithCheckAccountInClubPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsByMultipleFilterWithCheckAccountInClubPost$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
    body?: MultipleSearchCriteriaWithCheckAccountOfClub
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsByMultipleFilterWithCheckAccountInClubPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsGetTheSuitableClubAccountRequestIdGet
   */
  static readonly InlivewithClubsGetTheSuitableClubAccountRequestIdGetPath = '/inlivewith/Clubs/GetTheSuitableClub/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsGetTheSuitableClubAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsGetTheSuitableClubAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsGetTheSuitableClubAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsGetTheSuitableClubAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsGetTheSuitableClubAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsGetTheSuitableClubAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsGetTheSuitableClubAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsGetTheSuitableClubAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsGetTheSuitableClubAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsGetTheSuitableClubAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsGetTheSuitableClubAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsGetTheSuitableClubAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsSearchAccountRequestIdPost
   */
  static readonly InlivewithClubsSearchAccountRequestIdPostPath = '/inlivewith/Clubs/search/{accountRequestId}';

  /**
   * accountRequestId: The Account that being use on the app, AccountId: That profile Account you want to show.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsSearchAccountRequestIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsSearchAccountRequestIdPost$Plain$Response(params: {
    accountRequestId: string;
    accountId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
    body?: ClubFilterInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsSearchAccountRequestIdPostPath, 'post');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * accountRequestId: The Account that being use on the app, AccountId: That profile Account you want to show.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsSearchAccountRequestIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsSearchAccountRequestIdPost$Plain(params: {
    accountRequestId: string;
    accountId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
    body?: ClubFilterInputModel
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsSearchAccountRequestIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * accountRequestId: The Account that being use on the app, AccountId: That profile Account you want to show.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsSearchAccountRequestIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsSearchAccountRequestIdPost$Json$Response(params: {
    accountRequestId: string;
    accountId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
    body?: ClubFilterInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsSearchAccountRequestIdPostPath, 'post');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * accountRequestId: The Account that being use on the app, AccountId: That profile Account you want to show.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsSearchAccountRequestIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsSearchAccountRequestIdPost$Json(params: {
    accountRequestId: string;
    accountId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
    body?: ClubFilterInputModel
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsSearchAccountRequestIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost
   */
  static readonly InlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPostPath = '/inlivewith/Club/SearchMemberNotInClubByEmail/{clubId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost$Plain$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPostPath, 'post');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost$Plain(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost$Json$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPostPath, 'post');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost$Json(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithClubSearchMemberNotInClubByEmailClubIdAccountRequestIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCreateAccountIdPost
   */
  static readonly InlivewithClubCreateAccountIdPostPath = '/inlivewith/Club/Create/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCreateAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountIdPost$Plain$Response(params: {
    accountId: string;
    body?: ClubObjectCreateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCreateAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCreateAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountIdPost$Plain(params: {
    accountId: string;
    body?: ClubObjectCreateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubCreateAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCreateAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountIdPost$Json$Response(params: {
    accountId: string;
    body?: ClubObjectCreateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCreateAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCreateAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountIdPost$Json(params: {
    accountId: string;
    body?: ClubObjectCreateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubCreateAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubUpdatePolicyClubIdPut
   */
  static readonly InlivewithClubUpdatePolicyClubIdPutPath = '/inlivewith/Club/UpdatePolicy/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUpdatePolicyClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdatePolicyClubIdPut$Plain$Response(params: {
    clubId: string;
    body?: ClubUpdatePolicy
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUpdatePolicyClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUpdatePolicyClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdatePolicyClubIdPut$Plain(params: {
    clubId: string;
    body?: ClubUpdatePolicy
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubUpdatePolicyClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUpdatePolicyClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdatePolicyClubIdPut$Json$Response(params: {
    clubId: string;
    body?: ClubUpdatePolicy
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUpdatePolicyClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUpdatePolicyClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdatePolicyClubIdPut$Json(params: {
    clubId: string;
    body?: ClubUpdatePolicy
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubUpdatePolicyClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubUpdateSuggestionCelebritiesClubIdPut
   */
  static readonly InlivewithClubUpdateSuggestionCelebritiesClubIdPutPath = '/inlivewith/Club/UpdateSuggestionCelebrities/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Plain$Response(params: {
    clubId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUpdateSuggestionCelebritiesClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Plain(params: {
    clubId: string;
    body?: Array<string>
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json$Response(params: {
    clubId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUpdateSuggestionCelebritiesClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json(params: {
    clubId: string;
    body?: Array<string>
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAddCelebrityAliasPost
   */
  static readonly InlivewithClubAddCelebrityAliasPostPath = '/inlivewith/Club/AddCelebrityAlias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAddCelebrityAliasPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAddCelebrityAliasPost$Plain$Response(params?: {
    body?: CelebrityAliasCreateModel
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAddCelebrityAliasPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAddCelebrityAliasPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAddCelebrityAliasPost$Plain(params?: {
    body?: CelebrityAliasCreateModel
  }): Observable<CelebrityAliasResponseModelResponseModel> {

    return this.inlivewithClubAddCelebrityAliasPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelResponseModel>) => r.body as CelebrityAliasResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAddCelebrityAliasPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAddCelebrityAliasPost$Json$Response(params?: {
    body?: CelebrityAliasCreateModel
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAddCelebrityAliasPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAddCelebrityAliasPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAddCelebrityAliasPost$Json(params?: {
    body?: CelebrityAliasCreateModel
  }): Observable<CelebrityAliasResponseModelResponseModel> {

    return this.inlivewithClubAddCelebrityAliasPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelResponseModel>) => r.body as CelebrityAliasResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubUpdateCelebrityAliasPut
   */
  static readonly InlivewithClubUpdateCelebrityAliasPutPath = '/inlivewith/Club/UpdateCelebrityAlias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUpdateCelebrityAliasPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateCelebrityAliasPut$Plain$Response(params?: {
    body?: CelebrityAliasUpdateModel
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUpdateCelebrityAliasPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUpdateCelebrityAliasPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateCelebrityAliasPut$Plain(params?: {
    body?: CelebrityAliasUpdateModel
  }): Observable<CelebrityAliasResponseModelResponseModel> {

    return this.inlivewithClubUpdateCelebrityAliasPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelResponseModel>) => r.body as CelebrityAliasResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUpdateCelebrityAliasPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateCelebrityAliasPut$Json$Response(params?: {
    body?: CelebrityAliasUpdateModel
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUpdateCelebrityAliasPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUpdateCelebrityAliasPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateCelebrityAliasPut$Json(params?: {
    body?: CelebrityAliasUpdateModel
  }): Observable<CelebrityAliasResponseModelResponseModel> {

    return this.inlivewithClubUpdateCelebrityAliasPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelResponseModel>) => r.body as CelebrityAliasResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubGetCelebrityAliasLikeNameNameGet
   */
  static readonly InlivewithClubGetCelebrityAliasLikeNameNameGetPath = '/inlivewith/Club/GetCelebrityAliasLikeName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubGetCelebrityAliasLikeNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubGetCelebrityAliasLikeNameNameGet$Plain$Response(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubGetCelebrityAliasLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubGetCelebrityAliasLikeNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubGetCelebrityAliasLikeNameNameGet$Plain(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubGetCelebrityAliasLikeNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubGetCelebrityAliasLikeNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubGetCelebrityAliasLikeNameNameGet$Json$Response(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubGetCelebrityAliasLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubGetCelebrityAliasLikeNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubGetCelebrityAliasLikeNameNameGet$Json(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubGetCelebrityAliasLikeNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubEditNotificationInClubForMemberPut
   */
  static readonly InlivewithClubEditNotificationInClubForMemberPutPath = '/inlivewith/Club/EditNotificationInClubForMember';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubEditNotificationInClubForMemberPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubEditNotificationInClubForMemberPut$Plain$Response(params?: {
    body?: ClubNotificationModelMember
  }): Observable<StrictHttpResponse<ClubNotificationModelMemberResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubEditNotificationInClubForMemberPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubNotificationModelMemberResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubEditNotificationInClubForMemberPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubEditNotificationInClubForMemberPut$Plain(params?: {
    body?: ClubNotificationModelMember
  }): Observable<ClubNotificationModelMemberResponseModel> {

    return this.inlivewithClubEditNotificationInClubForMemberPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubNotificationModelMemberResponseModel>) => r.body as ClubNotificationModelMemberResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubEditNotificationInClubForMemberPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubEditNotificationInClubForMemberPut$Json$Response(params?: {
    body?: ClubNotificationModelMember
  }): Observable<StrictHttpResponse<ClubNotificationModelMemberResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubEditNotificationInClubForMemberPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubNotificationModelMemberResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubEditNotificationInClubForMemberPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubEditNotificationInClubForMemberPut$Json(params?: {
    body?: ClubNotificationModelMember
  }): Observable<ClubNotificationModelMemberResponseModel> {

    return this.inlivewithClubEditNotificationInClubForMemberPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubNotificationModelMemberResponseModel>) => r.body as ClubNotificationModelMemberResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet
   */
  static readonly InlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGetPath = '/inlivewith/Club/CheckNotificationInClubForMember/{clubId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Plain$Response(params: {
    clubId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<ClubNotificationModelMemberResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubNotificationModelMemberResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Plain(params: {
    clubId: string;
    accountId: string;
  }): Observable<ClubNotificationModelMemberResponseModel> {

    return this.inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubNotificationModelMemberResponseModel>) => r.body as ClubNotificationModelMemberResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Json$Response(params: {
    clubId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<ClubNotificationModelMemberResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubNotificationModelMemberResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Json(params: {
    clubId: string;
    accountId: string;
  }): Observable<ClubNotificationModelMemberResponseModel> {

    return this.inlivewithClubCheckNotificationInClubForMemberClubIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubNotificationModelMemberResponseModel>) => r.body as ClubNotificationModelMemberResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubEditNotificationInClubForAdminPut
   */
  static readonly InlivewithClubEditNotificationInClubForAdminPutPath = '/inlivewith/Club/EditNotificationInClubForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubEditNotificationInClubForAdminPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubEditNotificationInClubForAdminPut$Plain$Response(params?: {
    body?: ClubNotificationModelAdmin
  }): Observable<StrictHttpResponse<ClubNotificationModelAdminResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubEditNotificationInClubForAdminPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubNotificationModelAdminResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubEditNotificationInClubForAdminPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubEditNotificationInClubForAdminPut$Plain(params?: {
    body?: ClubNotificationModelAdmin
  }): Observable<ClubNotificationModelAdminResponseModel> {

    return this.inlivewithClubEditNotificationInClubForAdminPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubNotificationModelAdminResponseModel>) => r.body as ClubNotificationModelAdminResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubEditNotificationInClubForAdminPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubEditNotificationInClubForAdminPut$Json$Response(params?: {
    body?: ClubNotificationModelAdmin
  }): Observable<StrictHttpResponse<ClubNotificationModelAdminResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubEditNotificationInClubForAdminPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubNotificationModelAdminResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubEditNotificationInClubForAdminPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubEditNotificationInClubForAdminPut$Json(params?: {
    body?: ClubNotificationModelAdmin
  }): Observable<ClubNotificationModelAdminResponseModel> {

    return this.inlivewithClubEditNotificationInClubForAdminPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubNotificationModelAdminResponseModel>) => r.body as ClubNotificationModelAdminResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet
   */
  static readonly InlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGetPath = '/inlivewith/Club/CheckNotificationInClubForAdmin/{clubId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Plain$Response(params: {
    clubId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<ClubNotificationModelAdminResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubNotificationModelAdminResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Plain(params: {
    clubId: string;
    accountId: string;
  }): Observable<ClubNotificationModelAdminResponseModel> {

    return this.inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubNotificationModelAdminResponseModel>) => r.body as ClubNotificationModelAdminResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Json$Response(params: {
    clubId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<ClubNotificationModelAdminResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubNotificationModelAdminResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Json(params: {
    clubId: string;
    accountId: string;
  }): Observable<ClubNotificationModelAdminResponseModel> {

    return this.inlivewithClubCheckNotificationInClubForAdminClubIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubNotificationModelAdminResponseModel>) => r.body as ClubNotificationModelAdminResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsLikeNameNameGet
   */
  static readonly InlivewithClubsLikeNameNameGetPath = '/inlivewith/Clubs/LikeName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsLikeNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Plain$Response(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsLikeNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Plain(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsLikeNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsLikeNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Json$Response(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsLikeNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Json(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsLikeNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet
   */
  static readonly InlivewithClubsLikeNameWithBannedNameAccountBannedIdGetPath = '/inlivewith/Clubs/LikeNameWithBanned/{name}/{accountBannedId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet$Plain$Response(params: {
    name: string;
    accountBannedId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsLikeNameWithBannedNameAccountBannedIdGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('accountBannedId', params.accountBannedId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet$Plain(params: {
    name: string;
    accountBannedId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet$Json$Response(params: {
    name: string;
    accountBannedId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsLikeNameWithBannedNameAccountBannedIdGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('accountBannedId', params.accountBannedId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet$Json(params: {
    name: string;
    accountBannedId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsLikeNameWithBannedNameAccountBannedIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubByNameNameGet
   */
  static readonly InlivewithClubByNameNameGetPath = '/inlivewith/Club/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByNameNameGet$Plain$Response(params: {
    name: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByNameNameGet$Plain(params: {
    name: string;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByNameNameGet$Json$Response(params: {
    name: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByNameNameGet$Json(params: {
    name: string;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubNameClubIdGet
   */
  static readonly InlivewithClubNameClubIdGetPath = '/inlivewith/Club/Name/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubNameClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Json(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubNameClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubNameAdminIdClubIdPut
   */
  static readonly InlivewithClubNameAdminIdClubIdPutPath = '/inlivewith/Club/Name/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubNameAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubNameAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubDescriptionClubIdGet
   */
  static readonly InlivewithClubDescriptionClubIdGetPath = '/inlivewith/Club/Description/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDescriptionClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubDescriptionClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDescriptionClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDescriptionClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubDescriptionClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubDescriptionClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDescriptionClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubDescriptionClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDescriptionClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDescriptionClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubDescriptionClubIdGet$Json(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubDescriptionClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubDescriptionAdminIdClubIdPut
   */
  static readonly InlivewithClubDescriptionAdminIdClubIdPutPath = '/inlivewith/Club/Description/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDescriptionAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDescriptionAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDescriptionAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubDescriptionAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDescriptionAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDescriptionAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDescriptionAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubDescriptionAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsByTypeTypeGet
   */
  static readonly InlivewithClubsByTypeTypeGetPath = '/inlivewith/Clubs/ByType/{type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByTypeTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByTypeTypeGet$Plain$Response(params: {
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByTypeTypeGetPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByTypeTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByTypeTypeGet$Plain(params: {
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsByTypeTypeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByTypeTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByTypeTypeGet$Json$Response(params: {
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByTypeTypeGetPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByTypeTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByTypeTypeGet$Json(params: {
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsByTypeTypeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet
   */
  static readonly InlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGetPath = '/inlivewith/Clubs/ByTypeWasNotJoinedYet/{type}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsByTypeWasNotJoinedYetTypeAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubTypeClubIdGet
   */
  static readonly InlivewithClubTypeClubIdGetPath = '/inlivewith/Club/Type/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubTypeClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubTypeClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubTypesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubTypeClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubTypeClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubTypeClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<ClubTypesEnumResponseModel> {

    return this.inlivewithClubTypeClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumResponseModel>) => r.body as ClubTypesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubTypeClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubTypeClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubTypesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubTypeClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubTypeClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubTypeClubIdGet$Json(params: {
    clubId: string;
  }): Observable<ClubTypesEnumResponseModel> {

    return this.inlivewithClubTypeClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumResponseModel>) => r.body as ClubTypesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubTypeAdminIdClubIdPut
   */
  static readonly InlivewithClubTypeAdminIdClubIdPutPath = '/inlivewith/Club/Type/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubTypeAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubTypeAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubTypeAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubTypeAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubTypeAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubTypeAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubTypeAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubTypeAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsByCategoryCategoryGet
   */
  static readonly InlivewithClubsByCategoryCategoryGetPath = '/inlivewith/Clubs/ByCategory/{category}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByCategoryCategoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByCategoryCategoryGet$Plain$Response(params: {
    category: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByCategoryCategoryGetPath, 'get');
    if (params) {
      rb.path('category', params.category, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByCategoryCategoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByCategoryCategoryGet$Plain(params: {
    category: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsByCategoryCategoryGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByCategoryCategoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByCategoryCategoryGet$Json$Response(params: {
    category: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByCategoryCategoryGetPath, 'get');
    if (params) {
      rb.path('category', params.category, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByCategoryCategoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByCategoryCategoryGet$Json(params: {
    category: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsByCategoryCategoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCategoryClubIdGet
   */
  static readonly InlivewithClubCategoryClubIdGetPath = '/inlivewith/Club/Category/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCategoryClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCategoryClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubCategoriesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCategoryClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubCategoriesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCategoryClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCategoryClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<ClubCategoriesEnumResponseModel> {

    return this.inlivewithClubCategoryClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubCategoriesEnumResponseModel>) => r.body as ClubCategoriesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCategoryClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCategoryClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubCategoriesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCategoryClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubCategoriesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCategoryClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCategoryClubIdGet$Json(params: {
    clubId: string;
  }): Observable<ClubCategoriesEnumResponseModel> {

    return this.inlivewithClubCategoryClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubCategoriesEnumResponseModel>) => r.body as ClubCategoriesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCategoryAdminIdClubIdPut
   */
  static readonly InlivewithClubCategoryAdminIdClubIdPutPath = '/inlivewith/Club/Category/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCategoryAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCategoryAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCategoryAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubCategoryAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCategoryAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCategoryAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCategoryAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubCategoryAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAvatarClubIdGet
   */
  static readonly InlivewithClubAvatarClubIdGetPath = '/inlivewith/Club/Avatar/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAvatarClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAvatarClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubAvatarClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAvatarClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAvatarClubIdGet$Json(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubAvatarClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAvatarAdminIdClubIdPut
   */
  static readonly InlivewithClubAvatarAdminIdClubIdPutPath = '/inlivewith/Club/Avatar/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StringResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StringResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAvatarAdminIdClubIdPost
   */
  static readonly InlivewithClubAvatarAdminIdClubIdPostPath = '/inlivewith/Club/Avatar/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPost$Json(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAvatarAdminIdClubIdDelete
   */
  static readonly InlivewithClubAvatarAdminIdClubIdDeletePath = '/inlivewith/Club/Avatar/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAvatarAdminIdClubIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAvatarAdminIdClubIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAvatarAdminIdClubIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAvatarAdminIdClubIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCheckBlockedMemberGet
   */
  static readonly InlivewithClubCheckBlockedMemberGetPath = '/inlivewith/Club/CheckBlockedMember';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCheckBlockedMemberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckBlockedMemberGet$Plain$Response(params?: {
    clubId?: string;
    accountId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCheckBlockedMemberGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCheckBlockedMemberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckBlockedMemberGet$Plain(params?: {
    clubId?: string;
    accountId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubCheckBlockedMemberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCheckBlockedMemberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckBlockedMemberGet$Json$Response(params?: {
    clubId?: string;
    accountId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCheckBlockedMemberGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCheckBlockedMemberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubCheckBlockedMemberGet$Json(params?: {
    clubId?: string;
    accountId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubCheckBlockedMemberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHashtagsClubIdGet
   */
  static readonly InlivewithClubHashtagsClubIdGetPath = '/inlivewith/Club/Hashtags/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithClubHashtagsClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithClubHashtagsClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHashtagsAdminIdClubIdPut
   */
  static readonly InlivewithClubHashtagsAdminIdClubIdPutPath = '/inlivewith/Club/Hashtags/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHashtagsAdminIdClubIdPost
   */
  static readonly InlivewithClubHashtagsAdminIdClubIdPostPath = '/inlivewith/Club/Hashtags/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPost$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHashtagsAdminIdClubIdDelete
   */
  static readonly InlivewithClubHashtagsAdminIdClubIdDeletePath = '/inlivewith/Club/Hashtags/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsPublicGet
   */
  static readonly InlivewithClubsPublicGetPath = '/inlivewith/Clubs/Public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPublicGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPublicGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPublicGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPublicGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsPublicGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPublicGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPublicGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPublicGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPublicGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsPublicGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsPrivateGet
   */
  static readonly InlivewithClubsPrivateGetPath = '/inlivewith/Clubs/Private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPrivateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPrivateGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPrivateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPrivateGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsPrivateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsPrivateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPrivateGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsPrivateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsPrivateGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsPrivateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubIsPublicClubIdGet
   */
  static readonly InlivewithClubIsPublicClubIdGetPath = '/inlivewith/Club/IsPublic/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubIsPublicClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPublicClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubIsPublicClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubIsPublicClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPublicClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubIsPublicClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubIsPublicClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPublicClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubIsPublicClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubIsPublicClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPublicClubIdGet$Json(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubIsPublicClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubIsPrivateClubIdGet
   */
  static readonly InlivewithClubIsPrivateClubIdGetPath = '/inlivewith/Club/IsPrivate/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubIsPrivateClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPrivateClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubIsPrivateClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubIsPrivateClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPrivateClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubIsPrivateClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubIsPrivateClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPrivateClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubIsPrivateClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubIsPrivateClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsPrivateClubIdGet$Json(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubIsPrivateClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubPublicAdminIdClubIdPut
   */
  static readonly InlivewithClubPublicAdminIdClubIdPutPath = '/inlivewith/Club/Public/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPublicAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPublicAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPublicAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubPublicAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPublicAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPublicAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPublicAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubPublicAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubPrivateAdminIdClubIdPut
   */
  static readonly InlivewithClubPrivateAdminIdClubIdPutPath = '/inlivewith/Club/Private/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPrivateAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPrivateAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPrivateAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubPrivateAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPrivateAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPrivateAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPrivateAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubPrivateAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsAccessLevelAnonymousGet
   */
  static readonly InlivewithClubsAccessLevelAnonymousGetPath = '/inlivewith/Clubs/AccessLevel/Anonymous';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsAccessLevelAnonymousGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccessLevelAnonymousGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsAccessLevelAnonymousGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsAccessLevelAnonymousGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccessLevelAnonymousGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsAccessLevelAnonymousGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsAccessLevelAnonymousGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccessLevelAnonymousGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsAccessLevelAnonymousGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsAccessLevelAnonymousGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccessLevelAnonymousGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsAccessLevelAnonymousGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsAccessLevelPlusGet
   */
  static readonly InlivewithClubsAccessLevelPlusGetPath = '/inlivewith/Clubs/AccessLevel/Plus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsAccessLevelPlusGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccessLevelPlusGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsAccessLevelPlusGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsAccessLevelPlusGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccessLevelPlusGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsAccessLevelPlusGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsAccessLevelPlusGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccessLevelPlusGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsAccessLevelPlusGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsAccessLevelPlusGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccessLevelPlusGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsAccessLevelPlusGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccessLevelIsAnonymousClubIdGet
   */
  static readonly InlivewithClubAccessLevelIsAnonymousClubIdGetPath = '/inlivewith/Club/AccessLevel/IsAnonymous/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelIsAnonymousClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsAnonymousClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelIsAnonymousClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelIsAnonymousClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsAnonymousClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccessLevelIsAnonymousClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelIsAnonymousClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsAnonymousClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelIsAnonymousClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelIsAnonymousClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsAnonymousClubIdGet$Json(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccessLevelIsAnonymousClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccessLevelIsPlusClubIdGet
   */
  static readonly InlivewithClubAccessLevelIsPlusClubIdGetPath = '/inlivewith/Club/AccessLevel/IsPlus/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelIsPlusClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsPlusClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelIsPlusClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelIsPlusClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsPlusClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccessLevelIsPlusClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelIsPlusClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsPlusClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelIsPlusClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelIsPlusClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccessLevelIsPlusClubIdGet$Json(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccessLevelIsPlusClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccessLevelAnonymousAdminIdClubIdPut
   */
  static readonly InlivewithClubAccessLevelAnonymousAdminIdClubIdPutPath = '/inlivewith/Club/AccessLevel/Anonymous/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelAnonymousAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelAnonymousAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubAccessLevelAnonymousAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccessLevelPlusAdminIdClubIdPut
   */
  static readonly InlivewithClubAccessLevelPlusAdminIdClubIdPutPath = '/inlivewith/Club/AccessLevel/Plus/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelPlusAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelPlusAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelPlusAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelPlusAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelPlusAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubAccessLevelPlusAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccessLevelPlusAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelPlusAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccessLevelPlusAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccessLevelPlusAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccessLevelPlusAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubAccessLevelPlusAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet
   */
  static readonly InlivewithClubsAccountGetSuggestAccountJoinClubClubIdGetPath = '/inlivewith/Clubs/Account/GetSuggestAccountJoinClub/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsAccountGetSuggestAccountJoinClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsAccountGetSuggestAccountJoinClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithClubsAccountGetSuggestAccountJoinClubClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet
   */
  static readonly InlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGetPath = '/inlivewith/Club/Accounts/AccessLevel/Plus/{clubId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Plain$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Plain(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Json$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Json(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountsAccessLevelPlusClubIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet
   */
  static readonly InlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGetPath = '/inlivewith/Club/Accounts/{accessLevel}/{role}/{clubId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet$Plain$Response(params: {
    clubId: string;
    accountRequestId: string;
    role: string;
    accessLevel: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('role', params.role, {});
      rb.path('accessLevel', params.accessLevel, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet$Plain(params: {
    clubId: string;
    accountRequestId: string;
    role: string;
    accessLevel: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet$Json$Response(params: {
    clubId: string;
    accountRequestId: string;
    role: string;
    accessLevel: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('role', params.role, {});
      rb.path('accessLevel', params.accessLevel, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet$Json(params: {
    clubId: string;
    accountRequestId: string;
    role: string;
    accessLevel: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountsAccessLevelRoleClubIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet
   */
  static readonly InlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGetPath = '/inlivewith/Club/Accounts/AccessLevel/Anonymus/{clubId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet$Plain$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet$Plain(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet$Json$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet$Json(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountsAccessLevelAnonymusClubIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountCountClubIdGet
   */
  static readonly InlivewithClubAccountCountClubIdGetPath = '/inlivewith/Club/Account/Count/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountCountClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCountClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<Int64ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountCountClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountCountClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCountClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<Int64ResponseModel> {

    return this.inlivewithClubAccountCountClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ResponseModel>) => r.body as Int64ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountCountClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCountClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<Int64ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountCountClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountCountClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountCountClubIdGet$Json(params: {
    clubId: string;
  }): Observable<Int64ResponseModel> {

    return this.inlivewithClubAccountCountClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ResponseModel>) => r.body as Int64ResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubGetAllMembersClubIdGet
   */
  static readonly InlivewithClubGetAllMembersClubIdGetPath = '/inlivewith/Club/GetAllMembers/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubGetAllMembersClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubGetAllMembersClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubGetAllMembersClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubGetAllMembersClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubGetAllMembersClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubGetAllMembersClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubGetAllMembersClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubGetAllMembersClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubGetAllMembersClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubGetAllMembersClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubGetAllMembersClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubGetAllMembersClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubGetMemberByFilterPost
   */
  static readonly InlivewithClubGetMemberByFilterPostPath = '/inlivewith/Club/GetMemberByFilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubGetMemberByFilterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubGetMemberByFilterPost$Plain$Response(params?: {
    page?: number;
    limit?: number;
    body?: ClubMemberRequestModel
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubGetMemberByFilterPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubGetMemberByFilterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubGetMemberByFilterPost$Plain(params?: {
    page?: number;
    limit?: number;
    body?: ClubMemberRequestModel
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubGetMemberByFilterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubGetMemberByFilterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubGetMemberByFilterPost$Json$Response(params?: {
    page?: number;
    limit?: number;
    body?: ClubMemberRequestModel
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubGetMemberByFilterPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubGetMemberByFilterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubGetMemberByFilterPost$Json(params?: {
    page?: number;
    limit?: number;
    body?: ClubMemberRequestModel
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubGetMemberByFilterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubMembersClubIdGet
   */
  static readonly InlivewithClubMembersClubIdGetPath = '/inlivewith/Club/Members/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Plain$Response(params: {
    clubId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Plain(params: {
    clubId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubMembersClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Json$Response(params: {
    clubId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Json(params: {
    clubId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubMembersClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubMembersLikeNameClubIdCriteriaGet
   */
  static readonly InlivewithClubMembersLikeNameClubIdCriteriaGetPath = '/inlivewith/Club/MembersLikeName/{clubId}/{criteria}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersLikeNameClubIdCriteriaGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersLikeNameClubIdCriteriaGet$Plain$Response(params: {
    clubId: string;
    criteria: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersLikeNameClubIdCriteriaGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('criteria', params.criteria, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersLikeNameClubIdCriteriaGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersLikeNameClubIdCriteriaGet$Plain(params: {
    clubId: string;
    criteria: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubMembersLikeNameClubIdCriteriaGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersLikeNameClubIdCriteriaGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersLikeNameClubIdCriteriaGet$Json$Response(params: {
    clubId: string;
    criteria: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersLikeNameClubIdCriteriaGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('criteria', params.criteria, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersLikeNameClubIdCriteriaGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersLikeNameClubIdCriteriaGet$Json(params: {
    clubId: string;
    criteria: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubMembersLikeNameClubIdCriteriaGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubMembersByRoleClubIdRoleGet
   */
  static readonly InlivewithClubMembersByRoleClubIdRoleGetPath = '/inlivewith/Club/MembersByRole/{clubId}/{role}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersByRoleClubIdRoleGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersByRoleClubIdRoleGet$Plain$Response(params: {
    clubId: string;
    accountRequestId?: string;
    role: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersByRoleClubIdRoleGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.path('role', params.role, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersByRoleClubIdRoleGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersByRoleClubIdRoleGet$Plain(params: {
    clubId: string;
    accountRequestId?: string;
    role: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubMembersByRoleClubIdRoleGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersByRoleClubIdRoleGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersByRoleClubIdRoleGet$Json$Response(params: {
    clubId: string;
    accountRequestId?: string;
    role: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersByRoleClubIdRoleGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.path('role', params.role, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersByRoleClubIdRoleGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersByRoleClubIdRoleGet$Json(params: {
    clubId: string;
    accountRequestId?: string;
    role: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubMembersByRoleClubIdRoleGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAdministratorsClubIdAccountRequestIdGet
   */
  static readonly InlivewithClubAdministratorsClubIdAccountRequestIdGetPath = '/inlivewith/Club/Administrators/{clubId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAdministratorsClubIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdAccountRequestIdGet$Plain$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAdministratorsClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAdministratorsClubIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdAccountRequestIdGet$Plain(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubAdministratorsClubIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAdministratorsClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubMembersAddMembersAdminIdClubIdPost
   */
  static readonly InlivewithClubMembersAddMembersAdminIdClubIdPostPath = '/inlivewith/Club/Members/AddMembers/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersAddMembersAdminIdClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAddMembersAdminIdClubIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<InvitationAccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersAddMembersAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationAccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersAddMembersAdminIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAddMembersAdminIdClubIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<InvitationAccountClubListResponseModel> {

    return this.inlivewithClubMembersAddMembersAdminIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationAccountClubListResponseModel>) => r.body as InvitationAccountClubListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersAddMembersAdminIdClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAddMembersAdminIdClubIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<InvitationAccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersAddMembersAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationAccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersAddMembersAdminIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAddMembersAdminIdClubIdPost$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<InvitationAccountClubListResponseModel> {

    return this.inlivewithClubMembersAddMembersAdminIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationAccountClubListResponseModel>) => r.body as InvitationAccountClubListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubMembersAdminIdClubIdDelete
   */
  static readonly InlivewithClubMembersAdminIdClubIdDeletePath = '/inlivewith/Club/Members/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersAdminIdClubIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAdminIdClubIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersAdminIdClubIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAdminIdClubIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithClubMembersAdminIdClubIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersAdminIdClubIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAdminIdClubIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersAdminIdClubIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubMembersAdminIdClubIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithClubMembersAdminIdClubIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsActivatedGet
   */
  static readonly InlivewithClubsActivatedGetPath = '/inlivewith/Clubs/Activated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsActivatedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsActivatedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsActivatedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsActivatedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsActivatedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsActivatedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsActivatedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsActivatedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsActivatedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsActivatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsDeletedGet
   */
  static readonly InlivewithClubsDeletedGetPath = '/inlivewith/Clubs/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsDeletedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsDeletedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsDeletedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsDeletedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsDeletedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsDeletedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsDeletedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsDeletedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsDeletedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsDeletedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubIsDeletedClubIdGet
   */
  static readonly InlivewithClubIsDeletedClubIdGetPath = '/inlivewith/Club/IsDeleted/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubIsDeletedClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsDeletedClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubIsDeletedClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubIsDeletedClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsDeletedClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubIsDeletedClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubIsDeletedClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsDeletedClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubIsDeletedClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubIsDeletedClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubIsDeletedClubIdGet$Json(params: {
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubIsDeletedClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubDeleteAdminIdClubIdDelete
   */
  static readonly InlivewithClubDeleteAdminIdClubIdDeletePath = '/inlivewith/Club/Delete/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDeleteAdminIdClubIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDeleteAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDeleteAdminIdClubIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubDeleteAdminIdClubIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDeleteAdminIdClubIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDeleteAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDeleteAdminIdClubIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubDeleteAdminIdClubIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountsRequestAccessLevelClubIdGet
   */
  static readonly InlivewithClubAccountsRequestAccessLevelClubIdGetPath = '/inlivewith/Club/Accounts/RequestAccessLevel/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountsRequestAccessLevelClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsRequestAccessLevelClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountsRequestAccessLevelClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountsRequestAccessLevelClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsRequestAccessLevelClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithClubAccountsRequestAccessLevelClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountsRequestAccessLevelClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsRequestAccessLevelClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountsRequestAccessLevelClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountsRequestAccessLevelClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsRequestAccessLevelClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithClubAccountsRequestAccessLevelClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet
   */
  static readonly InlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGetPath = '/inlivewith/Club/Account/RequestAccessLevelOfEachClub/{adminId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet$Plain$Response(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<RequestAccessLevelInClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestAccessLevelInClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet$Plain(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<RequestAccessLevelInClubPaginationModelResponseModel> {

    return this.inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RequestAccessLevelInClubPaginationModelResponseModel>) => r.body as RequestAccessLevelInClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet$Json$Response(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<RequestAccessLevelInClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestAccessLevelInClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet$Json(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<RequestAccessLevelInClubPaginationModelResponseModel> {

    return this.inlivewithClubAccountRequestAccessLevelOfEachClubAdminIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RequestAccessLevelInClubPaginationModelResponseModel>) => r.body as RequestAccessLevelInClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountApprovalAccessLevelPut
   */
  static readonly InlivewithClubAccountApprovalAccessLevelPutPath = '/inlivewith/Club/Account/ApprovalAccessLevel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountApprovalAccessLevelPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountApprovalAccessLevelPut$Plain$Response(params?: {
    body?: ApprovalRequestAccessLevelModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountApprovalAccessLevelPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountApprovalAccessLevelPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountApprovalAccessLevelPut$Plain(params?: {
    body?: ApprovalRequestAccessLevelModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccountApprovalAccessLevelPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountApprovalAccessLevelPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountApprovalAccessLevelPut$Json$Response(params?: {
    body?: ApprovalRequestAccessLevelModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountApprovalAccessLevelPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountApprovalAccessLevelPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountApprovalAccessLevelPut$Json(params?: {
    body?: ApprovalRequestAccessLevelModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccountApprovalAccessLevelPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountsRequestClubIdGet
   */
  static readonly InlivewithClubAccountsRequestClubIdGetPath = '/inlivewith/Club/Accounts/Request/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountsRequestClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsRequestClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountsRequestClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountsRequestClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsRequestClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithClubAccountsRequestClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountsRequestClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsRequestClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountsRequestClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountsRequestClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountsRequestClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithClubAccountsRequestClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountRequestsOfEachClubAdminIdGet
   */
  static readonly InlivewithClubAccountRequestsOfEachClubAdminIdGetPath = '/inlivewith/Club/Account/RequestsOfEachClub/{adminId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountRequestsOfEachClubAdminIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountRequestsOfEachClubAdminIdGet$Plain$Response(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<RequestJoinOfClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountRequestsOfEachClubAdminIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestJoinOfClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountRequestsOfEachClubAdminIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountRequestsOfEachClubAdminIdGet$Plain(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<RequestJoinOfClubPaginationModelResponseModel> {

    return this.inlivewithClubAccountRequestsOfEachClubAdminIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RequestJoinOfClubPaginationModelResponseModel>) => r.body as RequestJoinOfClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountRequestsOfEachClubAdminIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountRequestsOfEachClubAdminIdGet$Json$Response(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<RequestJoinOfClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountRequestsOfEachClubAdminIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestJoinOfClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountRequestsOfEachClubAdminIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountRequestsOfEachClubAdminIdGet$Json(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<RequestJoinOfClubPaginationModelResponseModel> {

    return this.inlivewithClubAccountRequestsOfEachClubAdminIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RequestJoinOfClubPaginationModelResponseModel>) => r.body as RequestJoinOfClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountApprovalPut
   */
  static readonly InlivewithClubAccountApprovalPutPath = '/inlivewith/Club/Account/Approval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountApprovalPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountApprovalPut$Plain$Response(params?: {
    body?: ApprovalRequestModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountApprovalPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountApprovalPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountApprovalPut$Plain(params?: {
    body?: ApprovalRequestModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccountApprovalPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountApprovalPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountApprovalPut$Json$Response(params?: {
    body?: ApprovalRequestModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountApprovalPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountApprovalPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountApprovalPut$Json(params?: {
    body?: ApprovalRequestModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubAccountApprovalPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithRoleSetRoleAccountPut
   */
  static readonly InlivewithRoleSetRoleAccountPutPath = '/inlivewith/Role/SetRoleAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithRoleSetRoleAccountPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithRoleSetRoleAccountPut$Plain$Response(params?: {
    body?: SetRoleAccountInClubModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithRoleSetRoleAccountPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithRoleSetRoleAccountPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithRoleSetRoleAccountPut$Plain(params?: {
    body?: SetRoleAccountInClubModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithRoleSetRoleAccountPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithRoleSetRoleAccountPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithRoleSetRoleAccountPut$Json$Response(params?: {
    body?: SetRoleAccountInClubModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithRoleSetRoleAccountPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithRoleSetRoleAccountPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithRoleSetRoleAccountPut$Json(params?: {
    body?: SetRoleAccountInClubModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithRoleSetRoleAccountPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubProcessClubAvatarClubIdPut
   */
  static readonly InlivewithClubProcessClubAvatarClubIdPutPath = '/inlivewith/Club/ProcessClubAvatar/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubProcessClubAvatarClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubProcessClubAvatarClubIdPut$Plain$Response(params: {
    clubId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubProcessClubAvatarClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubProcessClubAvatarClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubProcessClubAvatarClubIdPut$Plain(params: {
    clubId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubProcessClubAvatarClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubProcessClubAvatarClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubProcessClubAvatarClubIdPut$Json$Response(params: {
    clubId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubProcessClubAvatarClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubProcessClubAvatarClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubProcessClubAvatarClubIdPut$Json(params: {
    clubId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubProcessClubAvatarClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCreateAccountClubBannedPost
   */
  static readonly InlivewithClubCreateAccountClubBannedPostPath = '/inlivewith/Club/CreateAccountClubBanned';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCreateAccountClubBannedPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountClubBannedPost$Plain$Response(params?: {
    body?: AccountClubBannedCreateInputModel
  }): Observable<StrictHttpResponse<AccountClubBannedResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCreateAccountClubBannedPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubBannedResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCreateAccountClubBannedPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountClubBannedPost$Plain(params?: {
    body?: AccountClubBannedCreateInputModel
  }): Observable<AccountClubBannedResponseModelResponseModel> {

    return this.inlivewithClubCreateAccountClubBannedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubBannedResponseModelResponseModel>) => r.body as AccountClubBannedResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCreateAccountClubBannedPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountClubBannedPost$Json$Response(params?: {
    body?: AccountClubBannedCreateInputModel
  }): Observable<StrictHttpResponse<AccountClubBannedResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCreateAccountClubBannedPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubBannedResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCreateAccountClubBannedPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountClubBannedPost$Json(params?: {
    body?: AccountClubBannedCreateInputModel
  }): Observable<AccountClubBannedResponseModelResponseModel> {

    return this.inlivewithClubCreateAccountClubBannedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubBannedResponseModelResponseModel>) => r.body as AccountClubBannedResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountBannedAdminClubIdClubIdGet
   */
  static readonly InlivewithClubAccountBannedAdminClubIdClubIdGetPath = '/inlivewith/Club/Account/Banned/{adminClubId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountBannedAdminClubIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountBannedAdminClubIdClubIdGet$Plain$Response(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountBannedAdminClubIdClubIdGetPath, 'get');
    if (params) {
      rb.path('adminClubId', params.adminClubId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountBannedAdminClubIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountBannedAdminClubIdClubIdGet$Plain(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountClubBannedResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountBannedAdminClubIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>) => r.body as AccountClubBannedResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountBannedAdminClubIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountBannedAdminClubIdClubIdGet$Json$Response(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountBannedAdminClubIdClubIdGetPath, 'get');
    if (params) {
      rb.path('adminClubId', params.adminClubId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountBannedAdminClubIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAccountBannedAdminClubIdClubIdGet$Json(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountClubBannedResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountBannedAdminClubIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>) => r.body as AccountClubBannedResponseModelPaginationModelResponseModel)
    );
  }

}
