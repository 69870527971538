<div class="card mt-3" [ngClass]="{'post-selected': postSelected.id == post.id}" >
  <div class="flex justify-content-between">
    <div class="flex">
      <div class="flex zone-avatar">
        <img class="fit-size" [src]="post.author!.avatar ?? defaultAvatar" alt="" srcset="">
      </div>
      <div class="flex flex-column ml-2">
        <div class="flex align-items-center">

          <span class="text-gray name-author"> {{post.author!.nickName}} </span>
          <span class="ml-2 mr-2">

            <ng-container *ngIf="roleOfPostAuthor == 'ROLE_ADMIN'">
              <span class="label-role-admin">
                {{'ADMIN' | translate }}
              </span>
            </ng-container>
            <ng-container *ngIf="roleOfPostAuthor == 'ROLE_CREATOR'">
              <span class="label-role-creator">
                {{'CREATOR' | translate }}
              </span>
            </ng-container>
            <ng-container *ngIf="post.author?.accessLevelInClub! == 'ACCESSLEVEL_CELEBRITY'">
              <span class="label-role-celeb">
                {{'CELEBRITY' | translate }}
              </span>
            </ng-container>
          </span>
        </div>

        <div class="flex time-post">
          {{timeAgo(post.dateCreated!)}}
        </div>

      </div>
    </div>
    <div class="flex">
      <i class="pi pi-ellipsis-h cursor-pointer"></i>
    </div>
  </div>

  <div class="mt-3">
    <span class="stage-name">
      {{post.stage!.name}}
    </span>
  </div>

  <div class="mt-2 flex align-items-center">
    <span class="title-discussion">
      {{post!.title!}}
    </span>
    <div class="btn-discussion" *ngIf="duration && post.isDiscussing; else btnEndDiscuss">
      <div class="text-discuss ml-3 mr-3">
        En discussion
      </div>
    </div>
    <ng-template #btnEndDiscuss>
      <div class="btn-end-discussion">
        <div class="text-discuss ml-3 mr-3">
          Terminée
        </div>
      </div>
    </ng-template>
  </div>

  <div class="mt-2">
    <span class="text-content">
      {{post!.message!}}
    </span>
  </div>

  <div class="flex align-item-center mt-3">
    <img src="../../../../assets/images/svgs/heart-fill.svg" alt="" srcset=""> <span class="text ml-2 mr-5">
      {{post!.totalReact}}</span>
    <img src="../../../../assets/images/svgs/comment-fill.svg" alt="" srcset=""> <span class="text ml-2 mr-5">
      {{post!.totalComment}}</span>
    <img src="../../../../assets/images/svgs/user-fill.svg" alt="" srcset=""> <span class="text ml-2">
      {{post!.totalMemberDiscussion}}</span>
  </div>

  <div class="mt-5" *ngIf="duration && post.isDiscussing">
    <span class="flex text-remaining-time">
      <div class="time">
        Temps restant
        <span class="ml-3"> {{getNumberWithZero(duration.hours()) + ":" + getNumberWithZero(duration.minutes()) + ":" +
          getNumberWithZero(duration.seconds())}} </span>
      </div>
    </span>
  </div>

</div>
