<div class="col-12">
  <div class="flex justify-content-between align-items-center">
    <div class="flex align-items-center">
      <i class="pi pi-chevron-left fs-20 mr-2 cursor-pointer" (click)="goBack()"
        style="border: 1px solid #A1A7C4; padding: 5px; border-radius: 10px;"></i>
      <span class="fs-20" style="font-weight: bold;">
        {{album.name}}
      </span>
    </div>
    <div class="flex w-4 align-items-center justify-content-end">
      <div class="fs-14">
        <button pButton label="{{isEdit ? ('CANCEL' | translate) : 'DELETE' | translate}}" (click)="deleteAlbum()"
          class="p-button-outlined p-button-secondary mr-2" [ngClass]="isEdit ? 'white' : 'red'"></button>
        <button pButton [disabled]="isViewAssetDetail"
          label="{{isEdit ? ('REGISTER' | translate) : 'EDIT' | translate}}" (click)="editAlbum()"
          class="p-button-outlined blue p-button-secondary ml-2"></button>
      </div>
    </div>
  </div>
  <div class="zone-content">
    <div class="flex align-items-start p-4" *ngIf="!isEdit && !isViewAssetDetail">
      <div class="flex flex-column">
        <div class="text-bold fs-16">
          {{'DESCRIPTION' | translate}}
        </div>
        <div class="fs-12 mt-2">
          {{album.description}}
        </div>
        <div class="fs-12 mt-3">
          {{'CREATED_AT' | translate}} {{album.dateCreated | date:'dd/MM/yyyy'}} {{'BY' | translate}} @levi
        </div>
      </div>
    </div>
    <div class="w-100 p-4" *ngIf="isEdit">
      <div class="flex align-items-start sp-4">
        <div class="flex flex-column" style="margin-right: 20rem;">
          <div class="flex align-items-start mt-3">
            <div class="zone-avatar">
              <img class="fit-size" [src]="album.coverImage">
            </div>
            <div class="flex flex-column ml-3">
              <span class="text-bold">
                {{'LIST_CLUB_NAME' | translate}}
              </span>
              <div class="fs-14 mt-1">
                <input style="width:350px" type="text" pInputText [(ngModel)]="albumName" [placeholder]="album.name" />
              </div>
            </div>
          </div>
          <div class="flex align-items-center mt-3">
            <span class="text-bold">
              {{'LINKED_A_SCENE' | translate}}
            </span>
            <div class="fs-14 ml-2">
              <input style="width:250px" id="sceneName" type="text" pInputText [(ngModel)]="albumSceneName"
                [placeholder]="album.stage!.name!" />
            </div>
          </div>
        </div>
        <div class="flex flex-column">
          <span class="text-bold">
            {{'DETAIL_CLUB_PRIVACY' | translate}}
          </span>
          <div class="flex align-items-center mt-2 fs-12">
            <p-radioButton class="mr-5" name="groupname" label="{{'PRIVATE' | translate}}" [value]="true"
              [(ngModel)]="isPrivate"></p-radioButton>
            <p-radioButton class="ml-5" name="groupname" label="{{'PUBLIC' | translate}}" [value]="false"
              [(ngModel)]="isPrivate"></p-radioButton>
          </div>
        </div>
      </div>
      <div class="flex flex-column mt-3">
        <span class="text-bold">
          {{'DESCRIPTION' | translate}}
        </span>
        <div class="fs-14 mt-2">
          <textarea [rows]="3" style="resize: none; width: 100%;" type="text" pInputText [(ngModel)]="albumDescription"
            [placeholder]="album.description!"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap" *ngIf="!isViewAssetDetail">
    <div class="flex file ml-3 mt-3 cursor-pointer" *ngFor="let asset of album.albumAssets ; let i = index"
      (click)="openViewAsset(asset)">
      <img *ngIf="(checkTypeMedia(asset) == 'IMAGE' || checkTypeMedia(asset) == 'VIDEO')" [src]="getUrl(asset)" alt=""
        class="fit-size">
      <div class="wrap-video" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
        <img class="icon-play" width="70" height="70" src="../../../assets/images/svgs/play-button.png">
      </div>
      <div class="flex w-100 h-100 justify-content-center align-items-center"
        *ngIf="checkTypeMedia(asset) == 'UNKNOWN'">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem; margin: 3rem;"></i>
      </div>
    </div>
    <div class="file add-media ml-3 mt-3 cursor-pointer" *ngIf="isEdit">
      <span>
        {{'ADD_MEDIA' | translate}}
      </span>
    </div>
  </div>
  <div *ngIf="isViewAssetDetail && currentAsset && currentAuthor">
    <div class="w-100 flex">
      <div class="col-8">
        <div class="zone-assets">
          <img *ngIf="currentAsset.url!.includes('?i=')" [src]="currentAsset!.url!" alt="" class="original-size">
          <div class="video" *ngIf="currentAsset.url!.includes('?v=')">
            <video [id]="currentAsset.id" class="original-size" [src]="currentAsset!.url!" controls></video>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="zone-content-asset">
          <div class="zone-info-author">
            <div class="zone-avatar mr-2">
              <img class="fit-size" [src]="currentAsset.author!.avatar! ? currentAsset.author!.avatar! : defaultAvatar">
            </div>
            <div class="flex flex-column align-items-start">
              <div class="flex align-items-center">
                <span class="text-author">
                  {{currentAsset.author!.nickName!}}
                </span>
                <span class="ml-3 fs-14"
                  [ngStyle]="currentAuthor.roleInClub == 'ROLE_ADMIN' ? {'color': '#D3B652'} : {'color': '#808080'}">
                  {{getRoleInClub(currentAuthor)}}
                </span>
              </div>
              <span class="mt-1">
                {{timeAgo(currentAsset.dateCreated!)}}
              </span>
            </div>
          </div>
          <div class="content-asset">
            <span class="pl-3">
              {{currentAsset.content}}
            </span>
          </div>
          <div class="pt-3 zone-comment-asset">
            <div class="flex ml-3 mb-3">
              <div *ngFor="let control of initZoneControl()">
                <span class="mr-5 cursor-pointer" (click)="control.command()"
                  [ngStyle]="currentControl.id === control.id ? {'color': '#0058FF'} : {'color':'#A1A7C4'}">
                  {{control.title}} ({{control.total}})
                </span>
              </div>
            </div>
            <div *ngIf="currentControl.id == 1">
              <div class="login-modal">
                <div class="content">
                  <div class="list-comment">
                    <div class="comments">
                      <ng-container *ngFor="let item of commentsAsset">
                        <div class="item">
                          <div class="avatar">
                            <img class="fit-size cursor-pointer"
                              [src]="item?.author?.avatar! ? item.author?.avatar! : defaultAvatar" alt="">
                          </div>
                          <div class="detail">
                            <div class="name">
                              {{item?.author?.nickName!}}
                            </div>
                            <div class="content">
                              {{item?.message}}
                            </div>
                            <div style="position: relative">

                              <div class="flex justify-content-between zone-arrow" *ngIf="checkOverflow(item?.id!)">
                                <i class="pi pi-arrow-left arrow cursor-pointer" (click)="scrollTo(item.id!)"></i>
                                <i class="pi pi-arrow-right arrow cursor-pointer"
                                  (click)="scrollTo(item.id!, false)"></i>
                              </div>

                              <div class="flex list-media" *ngIf="item?.mediaCommentAlbumAssets!.length! > 0"
                                [id]="item.id">
                                <div class="mr-1 w-100" *ngFor="let asset of item?.mediaCommentAlbumAssets!">

                                  <div class="flex justify-content-center align-items-center ml-5"
                                    *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')" style="height: 70px">
                                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                  </div>

                                  <div class="mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')"
                                    style="height: 100px; width: auto;">
                                    <p-image [preview]="true" [src]="getUrl(asset)" alt=""></p-image>
                                  </div>

                                  <div class="w-100" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                    <!-- <app-video-layer *ngIf="asset?.url!" [layout]="'full'" [url]="asset?.url!"
                                      [poster]="getUrl(asset)">
                                    </app-video-layer> -->
                                  </div>

                                  <div class="w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                    <!-- <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio> -->
                                  </div>

                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                        <div style="margin-left: 3.5rem;
                        border-left: 8px solid #D1DEFB;" *ngIf="item && item?.inverseParent?.length! > 0">
                          <div *ngFor="let itemChild of item?.inverseParent">
                            <div class="item-child">
                              <div class="avatar">
                                <img class="fit-size cursor-pointer"
                                  [src]="itemChild?.author?.avatar! ? itemChild?.author?.avatar! : defaultAvatar"
                                  alt="">
                              </div>
                              <div class="detail">
                                <div class="name">
                                  {{itemChild?.author?.nickName!}}
                                </div>
                                <div class="content">
                                  {{itemChild?.message}}
                                </div>
                                <div class="mt-5 mb-5" *ngIf="itemChild?.mediaCommentAlbumAssets!.length! > 0" style="position: relative">
                                  <div class="flex justify-content-between zone-arrow"
                                    *ngIf="checkOverflow(itemChild?.id!)">
                                    <i class="pi pi-arrow-left arrow cursor-pointer"
                                      (click)="scrollTo(itemChild.id!)"></i>
                                    <i class="pi pi-arrow-right arrow cursor-pointer"
                                      (click)="scrollTo(itemChild.id!, false)"></i>
                                  </div>

                                  <div class="flex list-media" *ngIf="itemChild?.mediaCommentAlbumAssets!.length! > 0"
                                    [id]="itemChild.id">
                                    <div class="w-100" *ngFor="let asset of itemChild?.mediaCommentAlbumAssets!">

                                      <div class="flex justify-content-center align-items-center ml-5"
                                        *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')" style="height: 70px">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                      </div>

                                      <div class="flex mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')"
                                        style="height: 100px">
                                        <p-image [preview]="true" [src]="getUrl(asset)" alt=""></p-image>
                                      </div>

                                      <div class="flex mr-4" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                                        <!-- <app-video-layer *ngIf="asset?.url!" [layout]="'full'" [url]="asset?.url!"
                                          [poster]="getUrl(asset)">
                                        </app-video-layer> -->
                                      </div>

                                      <div class="flex w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                                        <!-- <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio> -->
                                      </div>

                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="currentControl.id == 0">
              <div class="zone-react">
                <div *ngFor="let liker of reactsAsset">
                  <div class="flex align-items-center">
                    <div class="avatar">
                      <img class="fit-size" [src]="liker.account?.avatar! ? liker.account?.avatar! : defaultAvatar">
                    </div>
                    <div class="ml-3">
                      <span>
                        {{liker.account?.nickName!}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
