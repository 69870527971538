import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import { AccountService } from './core/api/services';
import { AuthService } from './core/services/auth.service';
import { LoadingService } from './core/services/loading.service';
import { LocalStorageService } from './core/services/local-storage.service';
import {
  AppEnumStateService,
  IAppEnumStateModel,
} from './core/states/app-enum-state.service';
import { tap } from 'rxjs/operators';
import { ILWAppEnum } from './core/constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  email!: string;
  @Select(AppEnumStateService) _state$!: Observable<IAppEnumStateModel>;

  constructor(private _accountService: AccountService) {
    this._state$
      .pipe(
        tap((res) => {
          ILWAppEnum.ENUM_ILW = res?.appEnum!;
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.handleReceipeDataFromIdentity();
  }

  handleReceipeDataFromIdentity() {
    window.addEventListener('message', (event) => {
      let data: {
        accountId: string;
        token: string;
        email: string;
        isCreated: string;
        confirmEmailUrl: string;
        action: string;
      } = event.data;
      if (data && data.accountId) {
        data.token
          ? LocalStorageService.AddLocalStorage('access_token', data.token!)
          : '';
        data.email
          ? LocalStorageService.AddLocalStorage('email', data.email!)
          : '';
        this.email = LocalStorageService.GetLocalStorage('email');

        this._accountService
          .inlivewithAccountByEmailEmailGet$Json({
            email: data.email,
            details: false,
          })
          .subscribe((res) => {
            if (res.res == 0) {
              AuthService.SetAccountInfo(res?.data!);
              setTimeout(() => {
                window.open(window.origin, '_self');
              }, 500);
            }
          });
      }
    });
  }
}
