<div class="flex h-100">

  <div class="layout">
    <div class="menu">
      <ng-container>
        <app-sidebar></app-sidebar>
      </ng-container>
    </div>
  </div>


  <ng-container>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
</div>
