/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccessLevelsEnumResponseModel } from '../models/access-levels-enum-response-model';
import { AccountAlbumInputModel } from '../models/account-album-input-model';
import { AccountAlbumListResponseModel } from '../models/account-album-list-response-model';
import { AccountResponseGeneralPaginationModelResponseModel } from '../models/account-response-general-pagination-model-response-model';
import { AlbumAssetResponseGeneralListResponseModel } from '../models/album-asset-response-general-list-response-model';
import { AlbumAssetResponseGeneralPaginationModelResponseModel } from '../models/album-asset-response-general-pagination-model-response-model';
import { AlbumAssetResponseGeneralResponseModel } from '../models/album-asset-response-general-response-model';
import { AlbumDeleteFilesInputModel } from '../models/album-delete-files-input-model';
import { AlbumInputModel } from '../models/album-input-model';
import { AlbumObjectCreateInputModel } from '../models/album-object-create-input-model';
import { AlbumResponseWithTotalAssetModelPaginationModelResponseModel } from '../models/album-response-with-total-asset-model-pagination-model-response-model';
import { AlbumResponseWithTotalAssetModelResponseModel } from '../models/album-response-with-total-asset-model-response-model';
import { AlbumStringInputModel } from '../models/album-string-input-model';
import { AlbumUpdateStageModel } from '../models/album-update-stage-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { CommentAlbumAssetInputEditContentModel } from '../models/comment-album-asset-input-edit-content-model';
import { CommentAlbumAssetResponseModelPaginationModelResponseModel } from '../models/comment-album-asset-response-model-pagination-model-response-model';
import { CommentAlbumAssetResponseModelResponseModel } from '../models/comment-album-asset-response-model-response-model';
import { Int64ResponseModel } from '../models/int-64-response-model';
import { MediaCommentAlbumAssetDeleteModel } from '../models/media-comment-album-asset-delete-model';
import { ProcessFileModel } from '../models/process-file-model';
import { ReactAlbumAssetResponseModelPaginationModelResponseModel } from '../models/react-album-asset-response-model-pagination-model-response-model';
import { ReactCommentAlbumAssetResponseModelPaginationModelResponseModel } from '../models/react-comment-album-asset-response-model-pagination-model-response-model';
import { ReactInputModel } from '../models/react-input-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class AlbumService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithAlbumsPageGet
   */
  static readonly InlivewithAlbumsPageGetPath = '/inlivewith/Albums/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPageGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPageGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsByClubClubIdGet
   */
  static readonly InlivewithAlbumsByClubClubIdGetPath = '/inlivewith/Albums/ByClub/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsByClubClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsByClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsByClubClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsByClubClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsByClubClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsByClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsByClubClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsByClubClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet
   */
  static readonly InlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGetPath = '/inlivewith/Albums/ByClubAndAcceptViewPrivate/{clubId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Plain$Response(params: {
    clubId: string;
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Plain(params: {
    clubId: string;
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Json$Response(params: {
    clubId: string;
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Json(params: {
    clubId: string;
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsByClubAndAcceptViewPrivateClubIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumByIdAlbumIdGet
   */
  static readonly InlivewithAlbumByIdAlbumIdGetPath = '/inlivewith/Album/ById/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumByIdAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumByIdAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumByIdAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumByIdAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumByIdAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumByIdAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumByIdAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumByIdAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumByIdAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumByIdAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumByIdAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumByIdAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumCreateAdminIdClubIdPost
   */
  static readonly InlivewithAlbumCreateAdminIdClubIdPostPath = '/inlivewith/Album/Create/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCreateAdminIdClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumCreateAdminIdClubIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: AlbumObjectCreateInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCreateAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCreateAdminIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumCreateAdminIdClubIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    body?: AlbumObjectCreateInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumCreateAdminIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCreateAdminIdClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumCreateAdminIdClubIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: AlbumObjectCreateInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCreateAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCreateAdminIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumCreateAdminIdClubIdPost$Json(params: {
    adminId: string;
    clubId: string;
    body?: AlbumObjectCreateInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumCreateAdminIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsByStageStageIdGet
   */
  static readonly InlivewithAlbumsByStageStageIdGetPath = '/inlivewith/Albums/ByStage/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsByStageStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByStageStageIdGet$Plain$Response(params: {
    stageId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsByStageStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsByStageStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByStageStageIdGet$Plain(params: {
    stageId: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsByStageStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsByStageStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByStageStageIdGet$Json$Response(params: {
    stageId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsByStageStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsByStageStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByStageStageIdGet$Json(params: {
    stageId: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsByStageStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumUpdateStagePut
   */
  static readonly InlivewithAlbumUpdateStagePutPath = '/inlivewith/Album/UpdateStage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumUpdateStagePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumUpdateStagePut$Plain$Response(params?: {
    body?: AlbumUpdateStageModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumUpdateStagePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumUpdateStagePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumUpdateStagePut$Plain(params?: {
    body?: AlbumUpdateStageModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumUpdateStagePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumUpdateStagePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumUpdateStagePut$Json$Response(params?: {
    body?: AlbumUpdateStageModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumUpdateStagePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumUpdateStagePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumUpdateStagePut$Json(params?: {
    body?: AlbumUpdateStageModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumUpdateStagePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumCoverImageAlbumIdPut
   */
  static readonly InlivewithAlbumCoverImageAlbumIdPutPath = '/inlivewith/Album/CoverImage/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCoverImageAlbumIdPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCoverImageAlbumIdPut$Plain$Response(params: {
    albumId: string;
    body?: {
'AlbumId'?: string;
'CoverImage'?: Blob;
}
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCoverImageAlbumIdPutPath, 'put');
    if (params) {
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCoverImageAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCoverImageAlbumIdPut$Plain(params: {
    albumId: string;
    body?: {
'AlbumId'?: string;
'CoverImage'?: Blob;
}
  }): Observable<StringResponseModel> {

    return this.inlivewithAlbumCoverImageAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCoverImageAlbumIdPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCoverImageAlbumIdPut$Json$Response(params: {
    albumId: string;
    body?: {
'AlbumId'?: string;
'CoverImage'?: Blob;
}
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCoverImageAlbumIdPutPath, 'put');
    if (params) {
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCoverImageAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCoverImageAlbumIdPut$Json(params: {
    albumId: string;
    body?: {
'AlbumId'?: string;
'CoverImage'?: Blob;
}
  }): Observable<StringResponseModel> {

    return this.inlivewithAlbumCoverImageAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumCoverImageAlbumIdPost
   */
  static readonly InlivewithAlbumCoverImageAlbumIdPostPath = '/inlivewith/Album/CoverImage/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCoverImageAlbumIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCoverImageAlbumIdPost$Plain$Response(params: {
    albumId: string;
    body?: {
'AlbumId'?: string;
'CoverImage'?: Blob;
}
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCoverImageAlbumIdPostPath, 'post');
    if (params) {
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCoverImageAlbumIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCoverImageAlbumIdPost$Plain(params: {
    albumId: string;
    body?: {
'AlbumId'?: string;
'CoverImage'?: Blob;
}
  }): Observable<StringResponseModel> {

    return this.inlivewithAlbumCoverImageAlbumIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCoverImageAlbumIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCoverImageAlbumIdPost$Json$Response(params: {
    albumId: string;
    body?: {
'AlbumId'?: string;
'CoverImage'?: Blob;
}
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCoverImageAlbumIdPostPath, 'post');
    if (params) {
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCoverImageAlbumIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCoverImageAlbumIdPost$Json(params: {
    albumId: string;
    body?: {
'AlbumId'?: string;
'CoverImage'?: Blob;
}
  }): Observable<StringResponseModel> {

    return this.inlivewithAlbumCoverImageAlbumIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsLikeNameNameGet
   */
  static readonly InlivewithAlbumsLikeNameNameGetPath = '/inlivewith/Albums/LikeName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsLikeNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsLikeNameNameGet$Plain$Response(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsLikeNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsLikeNameNameGet$Plain(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsLikeNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsLikeNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsLikeNameNameGet$Json$Response(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsLikeNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsLikeNameNameGet$Json(params: {
    name: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsLikeNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumByNameNameGet
   */
  static readonly InlivewithAlbumByNameNameGetPath = '/inlivewith/Album/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumByNameNameGet$Plain$Response(params: {
    name: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumByNameNameGet$Plain(params: {
    name: string;
    details?: boolean;
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumByNameNameGet$Json$Response(params: {
    name: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumByNameNameGet$Json(params: {
    name: string;
    details?: boolean;
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumNameAlbumIdGet
   */
  static readonly InlivewithAlbumNameAlbumIdGetPath = '/inlivewith/Album/Name/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumNameAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumNameAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumNameAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumNameAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumNameAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAlbumNameAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumNameAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumNameAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumNameAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumNameAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumNameAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAlbumNameAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumNameAdminIdClubIdAlbumIdPut
   */
  static readonly InlivewithAlbumNameAdminIdClubIdAlbumIdPutPath = '/inlivewith/Album/Name/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumStringInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumNameAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumStringInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumStringInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumNameAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumStringInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumNameAdminIdClubIdAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumDescriptionAlbumIdGet
   */
  static readonly InlivewithAlbumDescriptionAlbumIdGetPath = '/inlivewith/Album/Description/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDescriptionAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumDescriptionAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDescriptionAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDescriptionAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumDescriptionAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAlbumDescriptionAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDescriptionAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumDescriptionAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDescriptionAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDescriptionAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumDescriptionAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAlbumDescriptionAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut
   */
  static readonly InlivewithAlbumDescriptionAdminIdClubIdAlbumIdPutPath = '/inlivewith/Album/Description/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumStringInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDescriptionAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumStringInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumStringInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDescriptionAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumStringInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumDescriptionAdminIdClubIdAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsIsForAllGet
   */
  static readonly InlivewithAlbumsIsForAllGetPath = '/inlivewith/Albums/IsForAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsIsForAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsForAllGet$Plain$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsIsForAllGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsIsForAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsForAllGet$Plain(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsIsForAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsIsForAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsForAllGet$Json$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsIsForAllGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsIsForAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsForAllGet$Json(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsIsForAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsIsNotForAllGet
   */
  static readonly InlivewithAlbumsIsNotForAllGetPath = '/inlivewith/Albums/IsNotForAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsIsNotForAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsNotForAllGet$Plain$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsIsNotForAllGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsIsNotForAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsNotForAllGet$Plain(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsIsNotForAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsIsNotForAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsNotForAllGet$Json$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsIsNotForAllGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsIsNotForAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsNotForAllGet$Json(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsIsNotForAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumIsForAllAlbumIdGet
   */
  static readonly InlivewithAlbumIsForAllAlbumIdGetPath = '/inlivewith/Album/IsForAll/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumIsForAllAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsForAllAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumIsForAllAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumIsForAllAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsForAllAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumIsForAllAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumIsForAllAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsForAllAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumIsForAllAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumIsForAllAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsForAllAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumIsForAllAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumForAllAdminIdClubIdAlbumIdPut
   */
  static readonly InlivewithAlbumForAllAdminIdClubIdAlbumIdPutPath = '/inlivewith/Album/ForAll/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumForAllAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumForAllAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumForAllAdminIdClubIdAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut
   */
  static readonly InlivewithAlbumNotForAllAdminIdClubIdAlbumIdPutPath = '/inlivewith/Album/NotForAll/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumNotForAllAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumNotForAllAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumNotForAllAdminIdClubIdAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsPublicGet
   */
  static readonly InlivewithAlbumsPublicGetPath = '/inlivewith/Albums/Public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPublicGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPublicGet$Plain$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPublicGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPublicGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPublicGet$Plain(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsPublicGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPublicGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPublicGet$Json$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPublicGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPublicGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPublicGet$Json(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsPublicGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsPrivateGet
   */
  static readonly InlivewithAlbumsPrivateGetPath = '/inlivewith/Albums/Private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPrivateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPrivateGet$Plain$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPrivateGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPrivateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPrivateGet$Plain(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsPrivateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPrivateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPrivateGet$Json$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPrivateGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPrivateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPrivateGet$Json(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsPrivateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumIsPublicAlbumIdGet
   */
  static readonly InlivewithAlbumIsPublicAlbumIdGetPath = '/inlivewith/Album/IsPublic/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumIsPublicAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPublicAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumIsPublicAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumIsPublicAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPublicAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumIsPublicAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumIsPublicAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPublicAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumIsPublicAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumIsPublicAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPublicAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumIsPublicAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumIsPrivateAlbumIdGet
   */
  static readonly InlivewithAlbumIsPrivateAlbumIdGetPath = '/inlivewith/Album/IsPrivate/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumIsPrivateAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPrivateAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumIsPrivateAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumIsPrivateAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPrivateAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumIsPrivateAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumIsPrivateAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPrivateAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumIsPrivateAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumIsPrivateAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPrivateAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumIsPrivateAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut
   */
  static readonly InlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePutPath = '/inlivewith/Album/Public/{adminId}/{clubId}/{albumId}/{isAcceptViewPrivate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    isAcceptViewPrivate: boolean;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.path('isAcceptViewPrivate', params.isAcceptViewPrivate, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    isAcceptViewPrivate: boolean;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    isAcceptViewPrivate: boolean;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.path('isAcceptViewPrivate', params.isAcceptViewPrivate, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    isAcceptViewPrivate: boolean;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumPublicAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut
   */
  static readonly InlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePutPath = '/inlivewith/Album/Private/{adminId}/{clubId}/{albumId}/{isAcceptViewPrivate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    isAcceptViewPrivate: boolean;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.path('isAcceptViewPrivate', params.isAcceptViewPrivate, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    isAcceptViewPrivate: boolean;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    isAcceptViewPrivate: boolean;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.path('isAcceptViewPrivate', params.isAcceptViewPrivate, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    isAcceptViewPrivate: boolean;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumPrivateAdminIdClubIdAlbumIdIsAcceptViewPrivatePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsAccessLevelAnonymousGet
   */
  static readonly InlivewithAlbumsAccessLevelAnonymousGetPath = '/inlivewith/Albums/AccessLevel/Anonymous';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsAccessLevelAnonymousGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsAccessLevelAnonymousGet$Plain$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsAccessLevelAnonymousGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsAccessLevelAnonymousGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsAccessLevelAnonymousGet$Plain(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsAccessLevelAnonymousGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsAccessLevelAnonymousGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsAccessLevelAnonymousGet$Json$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsAccessLevelAnonymousGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsAccessLevelAnonymousGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsAccessLevelAnonymousGet$Json(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsAccessLevelAnonymousGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsAccessLevelPlusGet
   */
  static readonly InlivewithAlbumsAccessLevelPlusGetPath = '/inlivewith/Albums/AccessLevel/Plus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsAccessLevelPlusGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsAccessLevelPlusGet$Plain$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsAccessLevelPlusGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsAccessLevelPlusGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsAccessLevelPlusGet$Plain(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsAccessLevelPlusGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsAccessLevelPlusGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsAccessLevelPlusGet$Json$Response(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsAccessLevelPlusGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsAccessLevelPlusGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsAccessLevelPlusGet$Json(params?: {
    clubId?: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsAccessLevelPlusGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet
   */
  static readonly InlivewithAlbumAccessLevelIsAnonymousAlbumIdGetPath = '/inlivewith/Album/AccessLevel/IsAnonymous/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAccessLevelIsAnonymousAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAccessLevelIsAnonymousAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumAccessLevelIsAnonymousAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumAccessLevelIsPlusAlbumIdGet
   */
  static readonly InlivewithAlbumAccessLevelIsPlusAlbumIdGetPath = '/inlivewith/Album/AccessLevel/IsPlus/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAccessLevelIsPlusAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumAccessLevelIsPlusAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAccessLevelIsPlusAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAccessLevelIsPlusAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumAccessLevelIsPlusAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumAccessLevelIsPlusAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAccessLevelIsPlusAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumAccessLevelIsPlusAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAccessLevelIsPlusAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAccessLevelIsPlusAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumAccessLevelIsPlusAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumAccessLevelIsPlusAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut
   */
  static readonly InlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPutPath = '/inlivewith/Album/AccessLevel/Anonymous/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumAccessLevelAnonymousAdminIdClubIdAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut
   */
  static readonly InlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPutPath = '/inlivewith/Album/AccessLevel/Plus/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumAccessLevelPlusAdminIdClubIdAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumFilesCountAlbumIdGet
   */
  static readonly InlivewithAlbumFilesCountAlbumIdGetPath = '/inlivewith/Album/Files/Count/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesCountAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesCountAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<Int64ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesCountAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesCountAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesCountAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<Int64ResponseModel> {

    return this.inlivewithAlbumFilesCountAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ResponseModel>) => r.body as Int64ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesCountAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesCountAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<Int64ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesCountAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesCountAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesCountAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<Int64ResponseModel> {

    return this.inlivewithAlbumFilesCountAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ResponseModel>) => r.body as Int64ResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumFilesAccountIdClubIdAlbumIdGet
   */
  static readonly InlivewithAlbumFilesAccountIdClubIdAlbumIdGetPath = '/inlivewith/Album/Files/{accountId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
    albumId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdClubIdAlbumIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Plain(params: {
    accountId: string;
    clubId: string;
    albumId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumAssetResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>) => r.body as AlbumAssetResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
    albumId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdClubIdAlbumIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Json(params: {
    accountId: string;
    clubId: string;
    albumId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumAssetResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>) => r.body as AlbumAssetResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumFilesAccountIdAlbumIdPut
   */
  static readonly InlivewithAlbumFilesAccountIdAlbumIdPutPath = '/inlivewith/Album/Files/{accountId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdAlbumIdPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdPut$Plain$Response(params: {
    accountId: string;
    albumId: string;
    body?: {
'AccountId'?: string;
'AlbumId'?: string;
'FileId'?: string;
'File'?: Blob;
'Content'?: string;
}
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdPut$Plain(params: {
    accountId: string;
    albumId: string;
    body?: {
'AccountId'?: string;
'AlbumId'?: string;
'FileId'?: string;
'File'?: Blob;
'Content'?: string;
}
  }): Observable<AlbumAssetResponseGeneralResponseModel> {

    return this.inlivewithAlbumFilesAccountIdAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>) => r.body as AlbumAssetResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdAlbumIdPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdPut$Json$Response(params: {
    accountId: string;
    albumId: string;
    body?: {
'AccountId'?: string;
'AlbumId'?: string;
'FileId'?: string;
'File'?: Blob;
'Content'?: string;
}
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdPut$Json(params: {
    accountId: string;
    albumId: string;
    body?: {
'AccountId'?: string;
'AlbumId'?: string;
'FileId'?: string;
'File'?: Blob;
'Content'?: string;
}
  }): Observable<AlbumAssetResponseGeneralResponseModel> {

    return this.inlivewithAlbumFilesAccountIdAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>) => r.body as AlbumAssetResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumFilesAccountIdAlbumIdPost
   */
  static readonly InlivewithAlbumFilesAccountIdAlbumIdPostPath = '/inlivewith/Album/Files/{accountId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdAlbumIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdPost$Plain$Response(params: {
    accountId: string;
    albumId: string;
    body?: {
'AccountId'?: string;
'AlbumId'?: string;
'Files'?: Array<Blob>;
'Contents'?: Array<string>;
}
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdAlbumIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdAlbumIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdPost$Plain(params: {
    accountId: string;
    albumId: string;
    body?: {
'AccountId'?: string;
'AlbumId'?: string;
'Files'?: Array<Blob>;
'Contents'?: Array<string>;
}
  }): Observable<AlbumAssetResponseGeneralListResponseModel> {

    return this.inlivewithAlbumFilesAccountIdAlbumIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>) => r.body as AlbumAssetResponseGeneralListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdAlbumIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdPost$Json$Response(params: {
    accountId: string;
    albumId: string;
    body?: {
'AccountId'?: string;
'AlbumId'?: string;
'Files'?: Array<Blob>;
'Contents'?: Array<string>;
}
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdAlbumIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdAlbumIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdPost$Json(params: {
    accountId: string;
    albumId: string;
    body?: {
'AccountId'?: string;
'AlbumId'?: string;
'Files'?: Array<Blob>;
'Contents'?: Array<string>;
}
  }): Observable<AlbumAssetResponseGeneralListResponseModel> {

    return this.inlivewithAlbumFilesAccountIdAlbumIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>) => r.body as AlbumAssetResponseGeneralListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumFilesAccountIdAlbumIdDelete
   */
  static readonly InlivewithAlbumFilesAccountIdAlbumIdDeletePath = '/inlivewith/Album/Files/{accountId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdAlbumIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdDelete$Plain$Response(params: {
    accountId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdAlbumIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdAlbumIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdDelete$Plain(params: {
    accountId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<AlbumAssetResponseGeneralListResponseModel> {

    return this.inlivewithAlbumFilesAccountIdAlbumIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>) => r.body as AlbumAssetResponseGeneralListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdAlbumIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdDelete$Json$Response(params: {
    accountId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdAlbumIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdAlbumIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdDelete$Json(params: {
    accountId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<AlbumAssetResponseGeneralListResponseModel> {

    return this.inlivewithAlbumFilesAccountIdAlbumIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>) => r.body as AlbumAssetResponseGeneralListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsDeletedGet
   */
  static readonly InlivewithAlbumsDeletedGetPath = '/inlivewith/Albums/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsDeletedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsDeletedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsDeletedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsDeletedGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsDeletedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsDeletedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsDeletedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsDeletedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsDeletedGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsDeletedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsActivatedGet
   */
  static readonly InlivewithAlbumsActivatedGetPath = '/inlivewith/Albums/Activated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsActivatedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsActivatedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsActivatedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsActivatedGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsActivatedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsActivatedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsActivatedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsActivatedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsActivatedGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsActivatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumIsDeletedAlbumIdGet
   */
  static readonly InlivewithAlbumIsDeletedAlbumIdGetPath = '/inlivewith/Album/IsDeleted/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumIsDeletedAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsDeletedAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumIsDeletedAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumIsDeletedAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsDeletedAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumIsDeletedAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumIsDeletedAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsDeletedAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumIsDeletedAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumIsDeletedAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsDeletedAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumIsDeletedAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumDeleteDelete
   */
  static readonly InlivewithAlbumDeleteDeletePath = '/inlivewith/Album/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDeleteDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteDelete$Plain$Response(params?: {
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDeleteDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDeleteDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteDelete$Plain(params?: {
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumDeleteDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDeleteDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteDelete$Json$Response(params?: {
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDeleteDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDeleteDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteDelete$Json(params?: {
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumDeleteDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumAddMemberToAlbumPost
   */
  static readonly InlivewithAlbumAddMemberToAlbumPostPath = '/inlivewith/Album/AddMemberToAlbum';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAddMemberToAlbumPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAddMemberToAlbumPost$Plain$Response(params?: {
    body?: AccountAlbumInputModel
  }): Observable<StrictHttpResponse<AccountAlbumListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAddMemberToAlbumPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountAlbumListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAddMemberToAlbumPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAddMemberToAlbumPost$Plain(params?: {
    body?: AccountAlbumInputModel
  }): Observable<AccountAlbumListResponseModel> {

    return this.inlivewithAlbumAddMemberToAlbumPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountAlbumListResponseModel>) => r.body as AccountAlbumListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAddMemberToAlbumPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAddMemberToAlbumPost$Json$Response(params?: {
    body?: AccountAlbumInputModel
  }): Observable<StrictHttpResponse<AccountAlbumListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAddMemberToAlbumPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountAlbumListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAddMemberToAlbumPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAddMemberToAlbumPost$Json(params?: {
    body?: AccountAlbumInputModel
  }): Observable<AccountAlbumListResponseModel> {

    return this.inlivewithAlbumAddMemberToAlbumPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountAlbumListResponseModel>) => r.body as AccountAlbumListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumChangeMemberToAlbumPut
   */
  static readonly InlivewithAlbumChangeMemberToAlbumPutPath = '/inlivewith/Album/ChangeMemberToAlbum';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumChangeMemberToAlbumPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumChangeMemberToAlbumPut$Plain$Response(params?: {
    body?: AccountAlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumChangeMemberToAlbumPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumChangeMemberToAlbumPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumChangeMemberToAlbumPut$Plain(params?: {
    body?: AccountAlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumChangeMemberToAlbumPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumChangeMemberToAlbumPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumChangeMemberToAlbumPut$Json$Response(params?: {
    body?: AccountAlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumChangeMemberToAlbumPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumChangeMemberToAlbumPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumChangeMemberToAlbumPut$Json(params?: {
    body?: AccountAlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumChangeMemberToAlbumPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete
   */
  static readonly InlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDeletePath = '/inlivewith/Album/RemoveMemberInAlbum/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: Array<string>
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: Array<string>
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumRemoveMemberInAlbumAdminIdClubIdAlbumIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet
   */
  static readonly InlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGetPath = '/inlivewith/Album/GetAlbumAssetById/{albumAssetId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Plain$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Plain(params: {
    albumAssetId: string;
    accountRequestId: string;
  }): Observable<AlbumAssetResponseGeneralResponseModel> {

    return this.inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>) => r.body as AlbumAssetResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json(params: {
    albumAssetId: string;
    accountRequestId: string;
  }): Observable<AlbumAssetResponseGeneralResponseModel> {

    return this.inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>) => r.body as AlbumAssetResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet
   */
  static readonly InlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGetPath = '/inlivewith/Album/CheckAccessLevelMemberInAlbum/{accountId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet$Plain$Response(params: {
    accountId: string;
    albumId: string;
  }): Observable<StrictHttpResponse<AccessLevelsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessLevelsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet$Plain(params: {
    accountId: string;
    albumId: string;
  }): Observable<AccessLevelsEnumResponseModel> {

    return this.inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccessLevelsEnumResponseModel>) => r.body as AccessLevelsEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet$Json$Response(params: {
    accountId: string;
    albumId: string;
  }): Observable<StrictHttpResponse<AccessLevelsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessLevelsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet$Json(params: {
    accountId: string;
    albumId: string;
  }): Observable<AccessLevelsEnumResponseModel> {

    return this.inlivewithAlbumCheckAccessLevelMemberInAlbumAccountIdAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccessLevelsEnumResponseModel>) => r.body as AccessLevelsEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet
   */
  static readonly InlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGetPath = '/inlivewith/Album/GetMemberInAlbum/{albumId}/{accountRequestId}/{accessLevel}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Plain$Response(params: {
    albumId: string;
    accountRequestId: string;
    accessLevel: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('accessLevel', params.accessLevel, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Plain(params: {
    albumId: string;
    accountRequestId: string;
    accessLevel: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Json$Response(params: {
    albumId: string;
    accountRequestId: string;
    accessLevel: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('accessLevel', params.accessLevel, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Json(params: {
    albumId: string;
    accountRequestId: string;
    accessLevel: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAlbumGetMemberInAlbumAlbumIdAccountRequestIdAccessLevelGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet
   */
  static readonly InlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGetPath = '/inlivewith/Album/GetCommentAlbumAssetByAlbumAssetId/{albumAssetId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Plain$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Plain(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentAlbumAssetResponseModelPaginationModelResponseModel> {

    return this.inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>) => r.body as CommentAlbumAssetResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentAlbumAssetResponseModelPaginationModelResponseModel> {

    return this.inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>) => r.body as CommentAlbumAssetResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet
   */
  static readonly InlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGetPath = '/inlivewith/Album/GetCommentAlbumAssetById/{commentAlbumAssetId}/{accountRequestId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Plain$Response(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGetPath, 'get');
    if (params) {
      rb.path('commentAlbumAssetId', params.commentAlbumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Plain(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    clubId: string;
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json$Response(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGetPath, 'get');
    if (params) {
      rb.path('commentAlbumAssetId', params.commentAlbumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    clubId: string;
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumCreateCommentOnAlbumAssetPost
   */
  static readonly InlivewithAlbumCreateCommentOnAlbumAssetPostPath = '/inlivewith/Album/CreateCommentOnAlbumAsset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCreateCommentOnAlbumAssetPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCreateCommentOnAlbumAssetPost$Plain$Response(params?: {
    body?: {
'AlbumId'?: string;
'AlbumAssetId'?: string;
'AuthorId'?: string;
'Message'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCreateCommentOnAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCreateCommentOnAlbumAssetPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCreateCommentOnAlbumAssetPost$Plain(params?: {
    body?: {
'AlbumId'?: string;
'AlbumAssetId'?: string;
'AuthorId'?: string;
'Message'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumCreateCommentOnAlbumAssetPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCreateCommentOnAlbumAssetPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCreateCommentOnAlbumAssetPost$Json$Response(params?: {
    body?: {
'AlbumId'?: string;
'AlbumAssetId'?: string;
'AuthorId'?: string;
'Message'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCreateCommentOnAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCreateCommentOnAlbumAssetPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCreateCommentOnAlbumAssetPost$Json(params?: {
    body?: {
'AlbumId'?: string;
'AlbumAssetId'?: string;
'AuthorId'?: string;
'Message'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumCreateCommentOnAlbumAssetPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumCreateCommentReplyOnAlbumAssetPost
   */
  static readonly InlivewithAlbumCreateCommentReplyOnAlbumAssetPostPath = '/inlivewith/Album/CreateCommentReplyOnAlbumAsset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Plain$Response(params?: {
    body?: {
'AlbumId'?: string;
'AlbumAssetId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCreateCommentReplyOnAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Plain(params?: {
    body?: {
'AlbumId'?: string;
'AlbumAssetId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Json$Response(params?: {
    body?: {
'AlbumId'?: string;
'AlbumAssetId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCreateCommentReplyOnAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Json(params?: {
    body?: {
'AlbumId'?: string;
'AlbumAssetId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumCreateCommentReplyOnAlbumAssetPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete
   */
  static readonly InlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDeletePath = '/inlivewith/Album/DeleteCommentOnAlbumAsset/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Plain$Response(params: {
    commentId: string;
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDeletePath, 'delete');
    if (params) {
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Plain(params: {
    commentId: string;
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Json$Response(params: {
    commentId: string;
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDeletePath, 'delete');
    if (params) {
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Json(params: {
    commentId: string;
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumDeleteCommentOnAlbumAssetCommentIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet
   */
  static readonly InlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGetPath = '/inlivewith/Album/GetReactOnAlbumAsset/{albumAssetId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Plain$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Plain(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactAlbumAssetResponseModelPaginationModelResponseModel> {

    return this.inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>) => r.body as ReactAlbumAssetResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactAlbumAssetResponseModelPaginationModelResponseModel> {

    return this.inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>) => r.body as ReactAlbumAssetResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet
   */
  static readonly InlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGetPath = '/inlivewith/Album/GetReactOnCommentAlbumAsset/{commentAlbumAssetId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet$Plain$Response(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactCommentAlbumAssetResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('commentAlbumAssetId', params.commentAlbumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactCommentAlbumAssetResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet$Plain(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactCommentAlbumAssetResponseModelPaginationModelResponseModel> {

    return this.inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactCommentAlbumAssetResponseModelPaginationModelResponseModel>) => r.body as ReactCommentAlbumAssetResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet$Json$Response(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactCommentAlbumAssetResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('commentAlbumAssetId', params.commentAlbumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactCommentAlbumAssetResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet$Json(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactCommentAlbumAssetResponseModelPaginationModelResponseModel> {

    return this.inlivewithAlbumGetReactOnCommentAlbumAssetCommentAlbumAssetIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactCommentAlbumAssetResponseModelPaginationModelResponseModel>) => r.body as ReactCommentAlbumAssetResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumAssetReactReactOnAlbumAssetPost
   */
  static readonly InlivewithAlbumAssetReactReactOnAlbumAssetPostPath = '/inlivewith/AlbumAsset/React/ReactOnAlbumAsset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAssetReactReactOnAlbumAssetPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAssetReactReactOnAlbumAssetPost$Plain$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAssetReactReactOnAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAssetReactReactOnAlbumAssetPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAssetReactReactOnAlbumAssetPost$Plain(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumAssetReactReactOnAlbumAssetPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAssetReactReactOnAlbumAssetPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAssetReactReactOnAlbumAssetPost$Json$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAssetReactReactOnAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAssetReactReactOnAlbumAssetPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAssetReactReactOnAlbumAssetPost$Json(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumAssetReactReactOnAlbumAssetPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost
   */
  static readonly InlivewithAlbumAssetReactReactOnCommentAlbumAssetPostPath = '/inlivewith/AlbumAsset/React/ReactOnCommentAlbumAsset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost$Plain$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAssetReactReactOnCommentAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost$Plain(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost$Json$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAssetReactReactOnCommentAlbumAssetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost$Json(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumAssetReactReactOnCommentAlbumAssetPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumEditContentCommentOnAlbumAssetPut
   */
  static readonly InlivewithAlbumEditContentCommentOnAlbumAssetPutPath = '/inlivewith/Album/EditContentCommentOnAlbumAsset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumEditContentCommentOnAlbumAssetPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumEditContentCommentOnAlbumAssetPut$Plain$Response(params?: {
    body?: CommentAlbumAssetInputEditContentModel
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumEditContentCommentOnAlbumAssetPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumEditContentCommentOnAlbumAssetPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumEditContentCommentOnAlbumAssetPut$Plain(params?: {
    body?: CommentAlbumAssetInputEditContentModel
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumEditContentCommentOnAlbumAssetPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumEditContentCommentOnAlbumAssetPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumEditContentCommentOnAlbumAssetPut$Json$Response(params?: {
    body?: CommentAlbumAssetInputEditContentModel
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumEditContentCommentOnAlbumAssetPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumEditContentCommentOnAlbumAssetPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumEditContentCommentOnAlbumAssetPut$Json(params?: {
    body?: CommentAlbumAssetInputEditContentModel
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumEditContentCommentOnAlbumAssetPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumAddMediaCommentOnAlbumAssetPut
   */
  static readonly InlivewithAlbumAddMediaCommentOnAlbumAssetPutPath = '/inlivewith/Album/AddMediaCommentOnAlbumAsset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAddMediaCommentOnAlbumAssetPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumAddMediaCommentOnAlbumAssetPut$Plain$Response(params?: {
    body?: {
'AlbumId'?: string;
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAddMediaCommentOnAlbumAssetPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAddMediaCommentOnAlbumAssetPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumAddMediaCommentOnAlbumAssetPut$Plain(params?: {
    body?: {
'AlbumId'?: string;
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumAddMediaCommentOnAlbumAssetPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumAddMediaCommentOnAlbumAssetPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumAddMediaCommentOnAlbumAssetPut$Json$Response(params?: {
    body?: {
'AlbumId'?: string;
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumAddMediaCommentOnAlbumAssetPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumAddMediaCommentOnAlbumAssetPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumAddMediaCommentOnAlbumAssetPut$Json(params?: {
    body?: {
'AlbumId'?: string;
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumAddMediaCommentOnAlbumAssetPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete
   */
  static readonly InlivewithAlbumDeleteMediaCommentOnAlbumAssetDeletePath = '/inlivewith/Album/DeleteMediaCommentOnAlbumAsset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Plain$Response(params?: {
    body?: MediaCommentAlbumAssetDeleteModel
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDeleteMediaCommentOnAlbumAssetDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Plain(params?: {
    body?: MediaCommentAlbumAssetDeleteModel
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Json$Response(params?: {
    body?: MediaCommentAlbumAssetDeleteModel
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDeleteMediaCommentOnAlbumAssetDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Json(params?: {
    body?: MediaCommentAlbumAssetDeleteModel
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumDeleteMediaCommentOnAlbumAssetDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumProcessAvatarAlbumIdPut
   */
  static readonly InlivewithAlbumProcessAvatarAlbumIdPutPath = '/inlivewith/Album/ProcessAvatar/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumProcessAvatarAlbumIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessAvatarAlbumIdPut$Plain$Response(params: {
    albumId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumProcessAvatarAlbumIdPutPath, 'put');
    if (params) {
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumProcessAvatarAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessAvatarAlbumIdPut$Plain(params: {
    albumId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumProcessAvatarAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumProcessAvatarAlbumIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessAvatarAlbumIdPut$Json$Response(params: {
    albumId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumProcessAvatarAlbumIdPutPath, 'put');
    if (params) {
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumProcessAvatarAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessAvatarAlbumIdPut$Json(params: {
    albumId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumProcessAvatarAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut
   */
  static readonly InlivewithAlbumProcessAlbumAssetAlbumAssetIdPutPath = '/inlivewith/Album/ProcessAlbumAsset/{albumAssetId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut$Plain$Response(params: {
    albumAssetId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumProcessAlbumAssetAlbumAssetIdPutPath, 'put');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut$Plain(params: {
    albumAssetId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut$Json$Response(params: {
    albumAssetId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumProcessAlbumAssetAlbumAssetIdPutPath, 'put');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut$Json(params: {
    albumAssetId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumProcessAlbumAssetAlbumAssetIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut
   */
  static readonly InlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPutPath = '/inlivewith/Album/ProcessMediaComentAlbumAsset/{mediaCommentAlbumAssetId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut$Plain$Response(params: {
    mediaCommentAlbumAssetId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPutPath, 'put');
    if (params) {
      rb.path('mediaCommentAlbumAssetId', params.mediaCommentAlbumAssetId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut$Plain(params: {
    mediaCommentAlbumAssetId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut$Json$Response(params: {
    mediaCommentAlbumAssetId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPutPath, 'put');
    if (params) {
      rb.path('mediaCommentAlbumAssetId', params.mediaCommentAlbumAssetId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut$Json(params: {
    mediaCommentAlbumAssetId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumProcessMediaComentAlbumAssetMediaCommentAlbumAssetIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
