import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClubResponseWithTotalMemberAndGoInliveModel } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-list-club-manage',
  templateUrl: './list-club-manage.component.html',
  styleUrls: ['./list-club-manage.component.scss'],
})
export class ListClubManageComponent implements OnInit {
  @Input() accountId!: string;
  listClub: ClubResponseWithTotalMemberAndGoInliveModel[] = [];
  totalItems!: number;
  currentPage = 1;
  defaultAvatar = defaultAvatar;
  constructor(private _router: Router, private _clubService: ClubService) {}

  ngOnInit(): void {
    this.getClubByAdmin();
  }

  getClubByAdmin() {
    return this._clubService
      .inlivewithClubsByAdministratorAdminIdGet$Json({
        adminId: this.accountId,
        limit: 10,
        page: this.currentPage,
      })
      .subscribe((res) => {
        this.listClub = res.data?.page!;
        this.totalItems = res.data?.totalItems!;
      });
  }

  gotoDetail(club: any) {}

  getType(type: string) {
    if (type == 'CLUB_TYPE_FAN') {
      return 'Fan';
    }
    return 'Talent';
  }

  getCategoryImage(category: string) {
    let url;
    if (category == 'CLUB_CATEGORY_MUSIC') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/music.svg';
    }
    if (category == 'CLUB_CATEGORY_DANCE') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/dance.svg';
    }
    if (category == 'CLUB_CATEGORY_HUMOR') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/humor.svg';
    }
    if (category == 'CLUB_CATEGORY_MEDIA') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/media.svg';
    }
    return url;
  }
}
