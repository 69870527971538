import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: false,
  STORAGE_URL: '',
  STORAGE_URL_IDENTITY: 'https://storage.identity.dev.ilwith.io',
  BASE_URL: 'http://www.bo.inlivewith.s3.dev.ilwith.io.s3-website-eu-west-1.amazonaws.com',
  IDENTITY_URL: 'https://dev.identity.ilwith.io',
  API_URL: 'https://dev.inlivewith.api.ilwith.io',
  WEB_PORTAL_URL: 'https://dev.identity.ui.ilwith.io',
  INLIVE_GROUP_URL: 'https://inlive-group.com/',
  EMAIL_API_URL: 'https://email.ilwith.io',
  GOOGLE_API_KEY: 'AIzaSyDtLxBSzPRNpy-u_bALJbNCAcpAlKvky5Y',
  STANDARD_URL: '',
  HASH_TAG_URL: '',
  FCM_TEST_URL: '',
  FCM_APP_ID: '',
  SIGNALR_URL: 'https://signalr.ilwith.io',
  VERSION: 'dev - 0.0.1',
};
