/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GoInliveCreateModel } from '../models/go-inlive-create-model';
import { GoInliveInputModel } from '../models/go-inlive-input-model';
import { GoInliveInviteModel } from '../models/go-inlive-invite-model';
import { GoInliveResponseModelWithClubDetailPaginationModelResponseModel } from '../models/go-inlive-response-model-with-club-detail-pagination-model-response-model';
import { GoInliveResponseModelWithClubDetailResponseModel } from '../models/go-inlive-response-model-with-club-detail-response-model';
import { InvitationGoInliveResponseModelListResponseModel } from '../models/invitation-go-inlive-response-model-list-response-model';
import { InvitationGoInliveResponseModelPaginationModelResponseModel } from '../models/invitation-go-inlive-response-model-pagination-model-response-model';
import { InvitationGoInliveResponseModelResponseModel } from '../models/invitation-go-inlive-response-model-response-model';
import { KickedOutGoInliveAccountResponseModelListResponseModel } from '../models/kicked-out-go-inlive-account-response-model-list-response-model';

@Injectable({
  providedIn: 'root',
})
export class GoInliveService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithGoInlivePageGet
   */
  static readonly InlivewithGoInlivePageGetPath = '/inlivewith/GoInlive/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInlivePageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInlivePageGet$Plain$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInlivePageGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInlivePageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInlivePageGet$Plain(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInlivePageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInlivePageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInlivePageGet$Json$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInlivePageGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInlivePageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInlivePageGet$Json(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInlivePageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetGoInliveByIdGet
   */
  static readonly InlivewithGoInliveGetGoInliveByIdGetPath = '/inlivewith/GoInlive/GetGoInliveById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByIdGet$Plain$Response(params?: {
    goInliveId?: string;
    isStreaming?: boolean;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByIdGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.query('isStreaming', params.isStreaming, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByIdGet$Plain(params?: {
    goInliveId?: string;
    isStreaming?: boolean;
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByIdGet$Json$Response(params?: {
    goInliveId?: string;
    isStreaming?: boolean;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByIdGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.query('isStreaming', params.isStreaming, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByIdGet$Json(params?: {
    goInliveId?: string;
    isStreaming?: boolean;
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetGoInliveByStreamingGet
   */
  static readonly InlivewithGoInliveGetGoInliveByStreamingGetPath = '/inlivewith/GoInlive/GetGoInliveByStreaming';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByStreamingGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByStreamingGet$Plain$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByStreamingGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByStreamingGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByStreamingGet$Plain(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByStreamingGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByStreamingGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByStreamingGet$Json$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByStreamingGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByStreamingGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByStreamingGet$Json(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByStreamingGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetGoInliveByAccountGet
   */
  static readonly InlivewithGoInliveGetGoInliveByAccountGetPath = '/inlivewith/GoInlive/GetGoInliveByAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByAccountGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByAccountGet$Plain$Response(params?: {
    accountId?: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByAccountGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('isPrivate', params.isPrivate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByAccountGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByAccountGet$Plain(params?: {
    accountId?: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByAccountGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByAccountGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByAccountGet$Json$Response(params?: {
    accountId?: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByAccountGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('isPrivate', params.isPrivate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByAccountGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByAccountGet$Json(params?: {
    accountId?: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByAccountGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetGoInliveThatAccountNotInClubGet
   */
  static readonly InlivewithGoInliveGetGoInliveThatAccountNotInClubGetPath = '/inlivewith/GoInlive/GetGoInliveThatAccountNotInClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Plain$Response(params?: {
    accountId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveThatAccountNotInClubGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Plain(params?: {
    accountId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Json$Response(params?: {
    accountId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveThatAccountNotInClubGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Json(params?: {
    accountId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveThatAccountNotInClubGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetGoInliveByClubGet
   */
  static readonly InlivewithGoInliveGetGoInliveByClubGetPath = '/inlivewith/GoInlive/GetGoInliveByClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByClubGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByClubGet$Plain$Response(params?: {
    clubId?: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByClubGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByClubGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByClubGet$Plain(params?: {
    clubId?: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByClubGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetGoInliveByClubGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByClubGet$Json$Response(params?: {
    clubId?: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetGoInliveByClubGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetGoInliveByClubGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetGoInliveByClubGet$Json(params?: {
    clubId?: string;
    accountRequestId?: string;
    isStreaming?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveResponseModelWithClubDetailPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetGoInliveByClubGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailPaginationModelResponseModel>) => r.body as GoInliveResponseModelWithClubDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCreatePost
   */
  static readonly InlivewithGoInliveCreatePostPath = '/inlivewith/GoInlive/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCreatePost$Plain$Response(params?: {
    body?: GoInliveCreateModel
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCreatePost$Plain(params?: {
    body?: GoInliveCreateModel
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCreatePost$Json$Response(params?: {
    body?: GoInliveCreateModel
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCreatePost$Json(params?: {
    body?: GoInliveCreateModel
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveEndGoInlivePut
   */
  static readonly InlivewithGoInliveEndGoInlivePutPath = '/inlivewith/GoInlive/EndGoInlive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveEndGoInlivePut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveEndGoInlivePut$Plain$Response(params?: {
    goInliveId?: string;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveEndGoInlivePutPath, 'put');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveEndGoInlivePut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveEndGoInlivePut$Plain(params?: {
    goInliveId?: string;
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveEndGoInlivePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveEndGoInlivePut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveEndGoInlivePut$Json$Response(params?: {
    goInliveId?: string;
  }): Observable<StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveEndGoInlivePutPath, 'put');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveEndGoInlivePut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveEndGoInlivePut$Json(params?: {
    goInliveId?: string;
  }): Observable<GoInliveResponseModelWithClubDetailResponseModel> {

    return this.inlivewithGoInliveEndGoInlivePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveResponseModelWithClubDetailResponseModel>) => r.body as GoInliveResponseModelWithClubDetailResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost
   */
  static readonly InlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPostPath = '/inlivewith/GoInlive/KickedOutGoInliveAccount/{goInliveId}/{authorId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Plain$Response(params: {
    goInliveId: string;
    authorId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPostPath, 'post');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.path('authorId', params.authorId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Plain(params: {
    goInliveId: string;
    authorId: string;
    body?: Array<string>
  }): Observable<KickedOutGoInliveAccountResponseModelListResponseModel> {

    return this.inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>) => r.body as KickedOutGoInliveAccountResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json$Response(params: {
    goInliveId: string;
    authorId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPostPath, 'post');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.path('authorId', params.authorId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json(params: {
    goInliveId: string;
    authorId: string;
    body?: Array<string>
  }): Observable<KickedOutGoInliveAccountResponseModelListResponseModel> {

    return this.inlivewithGoInliveKickedOutGoInliveAccountGoInliveIdAuthorIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<KickedOutGoInliveAccountResponseModelListResponseModel>) => r.body as KickedOutGoInliveAccountResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetAllInvitationGet
   */
  static readonly InlivewithGoInliveGetAllInvitationGetPath = '/inlivewith/GoInlive/GetAllInvitation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetAllInvitationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAllInvitationGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetAllInvitationGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetAllInvitationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAllInvitationGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetAllInvitationGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetAllInvitationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAllInvitationGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetAllInvitationGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetAllInvitationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetAllInvitationGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetAllInvitationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetInvitationByAccountGet
   */
  static readonly InlivewithGoInliveGetInvitationByAccountGetPath = '/inlivewith/GoInlive/GetInvitationByAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetInvitationByAccountGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByAccountGet$Plain$Response(params?: {
    accountId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetInvitationByAccountGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetInvitationByAccountGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByAccountGet$Plain(params?: {
    accountId?: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetInvitationByAccountGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetInvitationByAccountGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByAccountGet$Json$Response(params?: {
    accountId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetInvitationByAccountGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetInvitationByAccountGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByAccountGet$Json(params?: {
    accountId?: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetInvitationByAccountGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveGetInvitationByGoInliveIdGet
   */
  static readonly InlivewithGoInliveGetInvitationByGoInliveIdGetPath = '/inlivewith/GoInlive/GetInvitationByGoInliveId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetInvitationByGoInliveIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByGoInliveIdGet$Plain$Response(params?: {
    goInliveId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetInvitationByGoInliveIdGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetInvitationByGoInliveIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByGoInliveIdGet$Plain(params?: {
    goInliveId?: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetInvitationByGoInliveIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveGetInvitationByGoInliveIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByGoInliveIdGet$Json$Response(params?: {
    goInliveId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveGetInvitationByGoInliveIdGetPath, 'get');
    if (params) {
      rb.query('goInliveId', params.goInliveId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveGetInvitationByGoInliveIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveGetInvitationByGoInliveIdGet$Json(params?: {
    goInliveId?: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveGetInvitationByGoInliveIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveInviteToGoInlivePost
   */
  static readonly InlivewithGoInliveInviteToGoInlivePostPath = '/inlivewith/GoInlive/InviteToGoInlive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveInviteToGoInlivePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveInviteToGoInlivePost$Plain$Response(params?: {
    body?: GoInliveInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveInviteToGoInlivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveInviteToGoInlivePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveInviteToGoInlivePost$Plain(params?: {
    body?: GoInliveInviteModel
  }): Observable<InvitationGoInliveResponseModelListResponseModel> {

    return this.inlivewithGoInliveInviteToGoInlivePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>) => r.body as InvitationGoInliveResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveInviteToGoInlivePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveInviteToGoInlivePost$Json$Response(params?: {
    body?: GoInliveInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveInviteToGoInlivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveInviteToGoInlivePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveInviteToGoInlivePost$Json(params?: {
    body?: GoInliveInviteModel
  }): Observable<InvitationGoInliveResponseModelListResponseModel> {

    return this.inlivewithGoInliveInviteToGoInlivePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelListResponseModel>) => r.body as InvitationGoInliveResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveSelectGoInlivedInvitePut
   */
  static readonly InlivewithGoInliveSelectGoInlivedInvitePutPath = '/inlivewith/GoInlive/SelectGoInlivedInvite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveSelectGoInlivedInvitePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveSelectGoInlivedInvitePut$Plain$Response(params?: {
    body?: GoInliveInputModel
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveSelectGoInlivedInvitePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveSelectGoInlivedInvitePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveSelectGoInlivedInvitePut$Plain(params?: {
    body?: GoInliveInputModel
  }): Observable<InvitationGoInliveResponseModelResponseModel> {

    return this.inlivewithGoInliveSelectGoInlivedInvitePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>) => r.body as InvitationGoInliveResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveSelectGoInlivedInvitePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveSelectGoInlivedInvitePut$Json$Response(params?: {
    body?: GoInliveInputModel
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveSelectGoInlivedInvitePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveSelectGoInlivedInvitePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveSelectGoInlivedInvitePut$Json(params?: {
    body?: GoInliveInputModel
  }): Observable<InvitationGoInliveResponseModelResponseModel> {

    return this.inlivewithGoInliveSelectGoInlivedInvitePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>) => r.body as InvitationGoInliveResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut
   */
  static readonly InlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPutPath = '/inlivewith/GoInlive/CancelInvitaionGoInlived/{goInliveId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Plain$Response(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPutPath, 'put');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Plain(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<InvitationGoInliveResponseModelResponseModel> {

    return this.inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>) => r.body as InvitationGoInliveResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json$Response(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveService.InlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPutPath, 'put');
    if (params) {
      rb.path('goInliveId', params.goInliveId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json(params: {
    goInliveId: string;
    accountId: string;
  }): Observable<InvitationGoInliveResponseModelResponseModel> {

    return this.inlivewithGoInliveCancelInvitaionGoInlivedGoInliveIdAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveResponseModelResponseModel>) => r.body as InvitationGoInliveResponseModelResponseModel)
    );
  }

}
