import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClubResponseWithTotalMemberAndGoInliveModel, MediaPostResponseModel, ReportModelAllWithDetail, ReportModelWithCommentDetail } from 'src/app/core/api/be/models';
import { ClubService, PostService, ReportService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-card-report',
  templateUrl: './card-report.component.html',
  styleUrls: ['./card-report.component.scss']
})
export class CardReportComponent implements OnInit {

  @Input() report!: ReportModelAllWithDetail;
  @Input() isView!: boolean;
  @Input() ignored!: boolean;

  @Output() viewDetail = new EventEmitter();

  clubId!: string;

  defaultAvatar = defaultAvatar

  constructor(
    private _postService: PostService,
    private _translateService: TranslateService,
    private _reportService: ReportService,
    private _clubService: ClubService,
    private _activeRoute: ActivatedRoute,
  ) {
    this._activeRoute.queryParams.subscribe(res => {
      this.clubId = res?.clubId!;
    })
  }

  ngOnInit(): void {

  }

  timeAgo = (date: string) => {
    let currentLang = LocalStorageService.GetLocalStorage('language').toLowerCase();
    return moment.utc(date).locale(currentLang ? currentLang : 'fr').fromNow();
  };

  getNameByKey(key: string) {
    if (key == "ADULT_CONTENT") {
      return this._translateService.instant('ADULT_CONTENT');
    } else if (key == "HEARSAY") {
      return this._translateService.instant('HEARSAY');
    } else if (key == "HARASSMENT_VIOLENCE") {
      return this._translateService.instant('HARASSMENT_VIOLENCE');
    } else if (key == "SELLING_ILLEGAT") {
      return this._translateService.instant('SELLING_ILLEGAT');
    } else if (key == "INFRINGEMENT_INTELLECTUAL") {
      return this._translateService.instant('INFRINGEMENT_INTELLECTUAL');
    } else if (key == "SUICIDE_SELF_HARM") {
      return this._translateService.instant('SUICIDE_SELF_HARM');
    } else if (key == "INCITING_HATE_SPEECH") {
      return this._translateService.instant('INCITING_HATE_SPEECH');
    } else if (key == "OTHER") {
      return this._translateService.instant('OTHER');
    }
  }


  deleteAndBanReport(report: ReportModelWithCommentDetail) {
    let listIds: string[] = [];
    report.reports?.forEach(x => {
      listIds.push(x.id!);
    });

    return forkJoin([this._reportService.inlivewithReportChangeRepostStatusReportStatusPut$Json({
      reportStatus: 'STATUS_ACCEPTED',
      body: listIds
    }), this.bannedFromClub()]).pipe(
      tap(([res1, res2]) => {
        if (res1.res == 0 && res2.res == 0) {
          window.location.reload();
        }
      })
    ).subscribe();
  }

  bannedFromClub() {
    return this._clubService.inlivewithClubCreateAccountClubBannedPost$Json({
      body: {
        accountBannedId: this.report.type === 'REPORT_POST' ? this.report.post?.author?.id! : this.report.commentPost?.author?.id!,
        authorId: AuthService.GetAccountInfo().id!,
        clubId: this.clubId,
        type: "ADMIN_BANNED_MEMBER"
      }
    })
  }


  changeStatus(report: ReportModelAllWithDetail, status: string) {

    let listIds: string[] = [];
    report.reports?.forEach(x => {
      listIds.push(x.id!);
    });

    this._reportService.inlivewithReportChangeRepostStatusReportStatusPut$Json({
      reportStatus: status,
      body: listIds
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          window.location.reload();
        }
      })
    ).subscribe();
  }


  checkTypeMedia(asset: MediaPostResponseModel): string {

    if (!asset.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        if (
          asset?.url!.endsWith('.aac') ||
          asset?.url!.endsWith('.wav')
        ) {
          return 'AUDIO';
        }
        return 'IMAGE'
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.')
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }

    }
    return asset?.url!;
  }


  urlify(text: any) {
    var urlRegex =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text?.replace(urlRegex, function (url: any) {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

}
