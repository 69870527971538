import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'notifications', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule) },
  { path: 'messages', loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesModule) },
  { path: 'celebrities', loadChildren: () => import('./pages/celebrities/celebrities.module').then(m => m.CelebritiesModule) },
  { path: 'clubs', loadChildren: () => import('./pages/clubs/clubs.module').then(m => m.ClubsModule) },
  { path: 'inlivers', loadChildren: () => import('./pages/inlivers/inlivers.module').then(m => m.InliversModule) },
  { path: 'badges', loadChildren: () => import('./pages/badges/badges.module').then(m => m.BadgesModule) },
  { path: 'hashtags', loadChildren: () => import('./pages/hashtags/hashtags.module').then(m => m.HashtagsModule) },
  { path: 'signalements', loadChildren: () => import('./pages/signalements/signalements.module').then(m => m.SignalementsModule) },
  { path: 'roles', loadChildren: () => import('./pages/roles/roles.module').then(m => m.RolesModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
