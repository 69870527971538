import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengModule } from './primeng/primeng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { httpTranslateLoader } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationService } from '../core/services/translation.service';
import { CustomTranslatePipe } from '../core/services/translation-custom.pipe';
import { SafeHtmlPipe } from '../core/services/safehtml.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CardAlbumComponent } from './widgets/card-album/card-album.component';
import { DetailAlbumComponent } from './components/detail-album/detail-album.component';
import { AccountInfoComponent } from './components/account-info/account-info.component';
import { ListClubManageComponent } from './components/list-club-manage/list-club-manage.component';
import { ListClubFollowComponent } from './components/list-club-follow/list-club-follow.component';
import { ListFriendComponent } from './components/list-friend/list-friend.component';
import { ListClubMembersComponent } from './components/list-club-members/list-club-members.component';
import { DetailMemberComponent } from './components/detail-member/detail-member.component';
import { ListClubScenesComponent } from './components/list-club-scenes/list-club-scenes.component';
import { ListClubEventsComponent } from './components/list-club-events/list-club-events.component';
import { CardInliverComponent } from './widgets/card-inliver/card-inliver.component';
import { ReportAccountComponent } from './report-account/report-account.component';
import { ClubPostsPublicationComponent } from './components/club-posts-publication/club-posts-publication.component';
import { WaveFormAudioComponent } from './components/wave-form-audio/wave-form-audio.component';
import { NgWaveformModule } from 'ng-waveform';
import { CardEventComponent } from './widgets/card-event/card-event.component';
import { DetailEventComponent } from './components/detail-event/detail-event.component';
import { ListClubReportsComponent } from './components/list-club-reports/list-club-reports.component';
import { CardReportComponent } from './widgets/card-report/card-report.component';
import { CardPostComponent } from './widgets/card-post/card-post.component';
import { LimitStringPipe } from '../core/pipe/limit-string.pipe';
import { CardPostDiscussionComponent } from './widgets/card-post-discussion/card-post-discussion.component';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    CustomTranslatePipe,
    SafeHtmlPipe,
    CardAlbumComponent,
    DetailAlbumComponent,
    AccountInfoComponent,
    ListClubManageComponent,
    ListClubFollowComponent,
    ListFriendComponent,
    DetailMemberComponent,
    CardInliverComponent,
    ReportAccountComponent,
    WaveFormAudioComponent,
    CardEventComponent,
    DetailEventComponent,
    CardReportComponent,
    CardPostDiscussionComponent,
    LimitStringPipe,
    CardPostComponent
  ],
  imports: [
    CommonModule,
    PrimengModule,
    NgWaveformModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    PrimengModule,
    FormsModule,
    SafeHtmlPipe,
    NgxIntlTelInputModule,
    TranslateModule,
    CardAlbumComponent,
    DetailAlbumComponent,
    AccountInfoComponent,
    ListClubManageComponent,
    ListClubFollowComponent,
    ListFriendComponent,
    CardInliverComponent,
    ReportAccountComponent,
    CardEventComponent,
    DetailEventComponent,
    CardReportComponent,
    CardPostComponent,
    CardPostDiscussionComponent
  ],
})
export class SharedModule {}
