/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { GoInliveCelebrityCreateModel } from '../models/go-inlive-celebrity-create-model';
import { GoInliveCelebrityInviteModel } from '../models/go-inlive-celebrity-invite-model';
import { GoInliveCelebrityJoining } from '../models/go-inlive-celebrity-joining';
import { GoInliveCelebrityResponseModelPaginationModelResponseModel } from '../models/go-inlive-celebrity-response-model-pagination-model-response-model';
import { GoInliveCelebrityResponseModelResponseModel } from '../models/go-inlive-celebrity-response-model-response-model';
import { InvitationGoInliveCelebrityResponseModelListResponseModel } from '../models/invitation-go-inlive-celebrity-response-model-list-response-model';
import { InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel } from '../models/invitation-go-inlive-celebrity-response-model-pagination-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class GoInliveCelebrityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityPageGet
   */
  static readonly InlivewithGoInliveCelebrityPageGetPath = '/inlivewith/GoInliveCelebrity/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityPageGet$Plain$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityPageGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityPageGet$Plain(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as GoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityPageGet$Json$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityPageGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityPageGet$Json(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as GoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet
   */
  static readonly InlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGetPath = '/inlivewith/GoInliveCelebrity/GetGoInliveCelebrityById/{goInliveCelebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet$Plain$Response(params: {
    goInliveCelebrityId: string;
    isStreaming?: boolean;
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('goInliveCelebrityId', params.goInliveCelebrityId, {});
      rb.query('isStreaming', params.isStreaming, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet$Plain(params: {
    goInliveCelebrityId: string;
    isStreaming?: boolean;
  }): Observable<GoInliveCelebrityResponseModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>) => r.body as GoInliveCelebrityResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet$Json$Response(params: {
    goInliveCelebrityId: string;
    isStreaming?: boolean;
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('goInliveCelebrityId', params.goInliveCelebrityId, {});
      rb.query('isStreaming', params.isStreaming, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet$Json(params: {
    goInliveCelebrityId: string;
    isStreaming?: boolean;
  }): Observable<GoInliveCelebrityResponseModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetGoInliveCelebrityByIdGoInliveCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>) => r.body as GoInliveCelebrityResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet
   */
  static readonly InlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGetPath = '/inlivewith/GoInliveCelebrity/GetGoInliveCelebrityByStreaming';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet$Plain$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet$Plain(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as GoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet$Json$Response(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGetPath, 'get');
    if (params) {
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet$Json(params?: {
    accountRequestId?: string;
    page?: number;
    limit?: number;
  }): Observable<GoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetGoInliveCelebrityByStreamingGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as GoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet
   */
  static readonly InlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGetPath = '/inlivewith/GoInliveCelebrity/GetGoInliveCelebrityByAccount/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet$Plain$Response(params: {
    accountId: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('isPrivate', params.isPrivate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet$Plain(params: {
    accountId: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as GoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet$Json$Response(params: {
    accountId: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('isStreaming', params.isStreaming, {});
      rb.query('isPrivate', params.isPrivate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet$Json(params: {
    accountId: string;
    isStreaming?: boolean;
    isPrivate?: boolean;
    page?: number;
    limit?: number;
  }): Observable<GoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetGoInliveCelebrityByAccountAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as GoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost
   */
  static readonly InlivewithGoInliveCelebrityCreateGoInliveCelebrityPostPath = '/inlivewith/GoInliveCelebrity/CreateGoInliveCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost$Plain$Response(params?: {
    body?: GoInliveCelebrityCreateModel
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityCreateGoInliveCelebrityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost$Plain(params?: {
    body?: GoInliveCelebrityCreateModel
  }): Observable<GoInliveCelebrityResponseModelResponseModel> {

    return this.inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>) => r.body as GoInliveCelebrityResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost$Json$Response(params?: {
    body?: GoInliveCelebrityCreateModel
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityCreateGoInliveCelebrityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost$Json(params?: {
    body?: GoInliveCelebrityCreateModel
  }): Observable<GoInliveCelebrityResponseModelResponseModel> {

    return this.inlivewithGoInliveCelebrityCreateGoInliveCelebrityPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>) => r.body as GoInliveCelebrityResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost
   */
  static readonly InlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePostPath = '/inlivewith/GoInliveCelebrity/NotiCelebrityJoiningGoInlive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost$Plain$Response(params?: {
    body?: GoInliveCelebrityJoining
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost$Plain(params?: {
    body?: GoInliveCelebrityJoining
  }): Observable<BooleanResponseModel> {

    return this.inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost$Json$Response(params?: {
    body?: GoInliveCelebrityJoining
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost$Json(params?: {
    body?: GoInliveCelebrityJoining
  }): Observable<BooleanResponseModel> {

    return this.inlivewithGoInliveCelebrityNotiCelebrityJoiningGoInlivePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut
   */
  static readonly InlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPutPath = '/inlivewith/GoInliveCelebrity/EndGoInliveCelebrity/{goInliveCelebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut$Plain$Response(params: {
    goInliveCelebrityId: string;
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPutPath, 'put');
    if (params) {
      rb.path('goInliveCelebrityId', params.goInliveCelebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut$Plain(params: {
    goInliveCelebrityId: string;
  }): Observable<GoInliveCelebrityResponseModelResponseModel> {

    return this.inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>) => r.body as GoInliveCelebrityResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut$Json$Response(params: {
    goInliveCelebrityId: string;
  }): Observable<StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPutPath, 'put');
    if (params) {
      rb.path('goInliveCelebrityId', params.goInliveCelebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut$Json(params: {
    goInliveCelebrityId: string;
  }): Observable<GoInliveCelebrityResponseModelResponseModel> {

    return this.inlivewithGoInliveCelebrityEndGoInliveCelebrityGoInliveCelebrityIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GoInliveCelebrityResponseModelResponseModel>) => r.body as GoInliveCelebrityResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityGetAllInvitationGet
   */
  static readonly InlivewithGoInliveCelebrityGetAllInvitationGetPath = '/inlivewith/GoInliveCelebrity/GetAllInvitation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetAllInvitationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetAllInvitationGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetAllInvitationGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetAllInvitationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetAllInvitationGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetAllInvitationGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetAllInvitationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetAllInvitationGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetAllInvitationGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetAllInvitationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetAllInvitationGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetAllInvitationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet
   */
  static readonly InlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGetPath = '/inlivewith/GoInliveCelebrity/GetInvitationByAccount/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetInvitationByAccountAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet
   */
  static readonly InlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGetPath = '/inlivewith/GoInliveCelebrity/GetInvitationByGoInliveCelebrityId/{goInliveCelebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet$Plain$Response(params: {
    goInliveCelebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('goInliveCelebrityId', params.goInliveCelebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet$Plain(params: {
    goInliveCelebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet$Json$Response(params: {
    goInliveCelebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('goInliveCelebrityId', params.goInliveCelebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet$Json(params: {
    goInliveCelebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel> {

    return this.inlivewithGoInliveCelebrityGetInvitationByGoInliveCelebrityIdGoInliveCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel>) => r.body as InvitationGoInliveCelebrityResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost
   */
  static readonly InlivewithGoInliveCelebrityInviteToGoInliveCelebrityPostPath = '/inlivewith/GoInliveCelebrity/InviteToGoInliveCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost$Plain$Response(params?: {
    body?: GoInliveCelebrityInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityInviteToGoInliveCelebrityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost$Plain(params?: {
    body?: GoInliveCelebrityInviteModel
  }): Observable<InvitationGoInliveCelebrityResponseModelListResponseModel> {

    return this.inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>) => r.body as InvitationGoInliveCelebrityResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost$Json$Response(params?: {
    body?: GoInliveCelebrityInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityInviteToGoInliveCelebrityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost$Json(params?: {
    body?: GoInliveCelebrityInviteModel
  }): Observable<InvitationGoInliveCelebrityResponseModelListResponseModel> {

    return this.inlivewithGoInliveCelebrityInviteToGoInliveCelebrityPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>) => r.body as InvitationGoInliveCelebrityResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut
   */
  static readonly InlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPutPath = '/inlivewith/GoInliveCelebrity/UnInvitationToGoInliveCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut$Plain$Response(params?: {
    body?: GoInliveCelebrityInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut$Plain(params?: {
    body?: GoInliveCelebrityInviteModel
  }): Observable<InvitationGoInliveCelebrityResponseModelListResponseModel> {

    return this.inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>) => r.body as InvitationGoInliveCelebrityResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut$Json$Response(params?: {
    body?: GoInliveCelebrityInviteModel
  }): Observable<StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, GoInliveCelebrityService.InlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut$Json(params?: {
    body?: GoInliveCelebrityInviteModel
  }): Observable<InvitationGoInliveCelebrityResponseModelListResponseModel> {

    return this.inlivewithGoInliveCelebrityUnInvitationToGoInliveCelebrityPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvitationGoInliveCelebrityResponseModelListResponseModel>) => r.body as InvitationGoInliveCelebrityResponseModelListResponseModel)
    );
  }

}
