import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpXhrBackend,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ROLE_NAME, STORAGE_KEY } from "../constant";

export interface IRefreshTokenResult {
  res: number;
  error: string;
  data: any;
  accessToken: string;
}

export interface LoginResultModelResponseModel {
  res?: number;
  error?: string | null;
  data?: LoginResultModel;
  accessToken?: string | null;
}

export interface LoginResultModel {
  id?: string;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  jwt?: string | null;
  confirmEmailUrl?: string | null;
}

export interface LoginViewModel {
  email?: string | null;
  password?: string | null;
  isPersistent?: boolean;
}

export interface FranceRegion {
  id: string;
  code: string;
  name: string;
  normalizedName: string;
  franceDepartements: string[];
}

export interface FranceCommune {
  id: string;
  franceDepartementId: string;
  code: string;
  insee: string;
  postalCode: string;
  lat: number;
  long: number;
  name: string;
  normalizedName: string;
  franceDepartement: string;
}

export interface Departement {
  id: string;
  franceRegionId: string;
  code: string;
  name: string;
  normalizedName: string;
  franceRegion: FranceRegion;
  franceCommunes: FranceCommune[];
}

export interface GetAllDepartementResult {
  res: number;
  error: string;
  data: Departement[];
  accessToken: string;
}

export interface DepartementCodePostal {
  id: string;
  franceDepartementId: string;
  code: number;
  insee: number;
  postalCode: number;
  lat: number;
  long: number;
  name: string;
  normalizedName: string;
  franceDepartement: null;
}

export interface GetDepartementByCodePostalResult {
  res: number;
  error: string;
  data: DepartementCodePostal;
  accessToken: string;
}

export interface GetLocationsResult {
  res: number;
  error: string;
  data: Location[];
  accessToken: string;
}

export interface ChangePasswordViewModel {
  id: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface RegisterAmbassadorViewModel {
  birthday: string;
  workPosition: string;
  address: string;
  addressDetails: string;
  postalCode: string;
  city: string;
  department: string;
  phoneNumber: string;
  civility: string;
  gender: string;
  userName: string;
  email: string;
  lastName: string;
  role: string;
  firstName: string;
  password: string;
  confirmPassword: string;
  country: string;
  fileUpload : Blob;
}

export interface RegisterAmbassadorResultModel {
  birthday: Date;
  workPosition: string;
  picture: string;
  address: string;
  addressDetails: string;
  postalCode: string;
  city: string;
  department: string;
  phoneNumber: string;
  id: string;
  civility: string;
  gender: string;
  userName: string;
  email: string;
  lastName: string;
  firstName: string;
  confirmEmailUrl: boolean;
  dateCreated: Date;
}

export interface RegisterAmbassadorResultModelResponseModel {
  res?: number;
  error?: string | null;
  data?: RegisterAmbassadorResultModel;
  accessToken?: string | null;
}

export interface ResponsiveModel<T> {
  res?: number | null;
  error?: string | null;
  data?: T;
  accessToken?: string | null;
}

@Injectable({
  providedIn: "root",
})
export abstract class IdentityService {
  private static readonly _urlStandard = environment.STANDARD_URL;

  static readonly httpClient = new HttpClient(
    new HttpXhrBackend({ build: () => new XMLHttpRequest() })
  );

  public static RefreshToken(): void {
    const token = localStorage.getItem(STORAGE_KEY.TOKEN);
    const url = `${environment.IDENTITY_URL}/identity/Account/RefreshToken?token=${token}`;
    this.httpClient.post<IRefreshTokenResult>(url, null).subscribe((res) => {
      if (res.res == 0) {
        const newToken = res.data;
        localStorage.setItem(STORAGE_KEY.TOKEN, newToken);
        location.reload();
      } else {
        localStorage.clear();
        window.open(environment.WEB_PORTAL_URL, "_self");
      }
    });
  }

  public static Login(body: LoginViewModel) {
    const url = `${environment.IDENTITY_URL}/identity/Account/Login`;

    return this.httpClient.post<LoginResultModelResponseModel>(url, body);
  }

  public static ResetPassword(
    email: string,
    token: string,
    password: string,
    confirmPassword: string
  ): Observable<any> {
    const url = `${environment.IDENTITY_URL}/identity/Account/ResetPassword`;
    const body = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      token: token,
    };
    return this.httpClient.post<any>(url, body);
  }

  public static AddToRole(email: string, role: string) {
    const url = `${environment.IDENTITY_URL}/identity/Account/AddToRole`;
    const body = {
      email: email,
      role: role,
    };
    return this.httpClient.post<IRefreshTokenResult>(url, body);
  }

  public static ChangePassword(body: ChangePasswordViewModel) {
    const url = `${environment.IDENTITY_URL}/identity/Account/ChangePassword`;
    let header = new HttpHeaders();
    header = header.append(
      "Authorization",
      `Bearer ${localStorage.getItem(STORAGE_KEY.TOKEN)}`
    );
    return this.httpClient
      .post<IRefreshTokenResult>(url, body, {
        headers: header,
      })
      .pipe(catchError(this._HandleError));
  }

  private static _HandleError = (err: HttpErrorResponse) => {
    if (err.status === 401) {
      IdentityService.RefreshToken();
    }
    throw err.message;
  };

  public static GetAllDepartement() {
    const url = `${this._urlStandard}/standard/France/Departement/all`;

    return this.httpClient.get<GetAllDepartementResult>(url);
  }

  public static GetDepartementByCodePostal(codePostal: string) {
    const url = `${this._urlStandard}/standard/France/Commune/postalcode/${codePostal}/false`;

    return this.httpClient.get<GetDepartementByCodePostalResult>(url);
  }

  public static GetLocations(name: string) {
    const url = `${this._urlStandard}/standard/France/Commune/name/like/${name}`;

    return this.httpClient.get<GetLocationsResult>(url);
  }

  public static RegisterAmbassador(body: any) {
    const url = `${environment.IDENTITY_URL}/identity/Account/Register`;
    let form: FormData = new FormData();
    Object.keys(body).forEach((b) => {
      form.append(b, body[b]);
    });
    return this.httpClient.post<RegisterAmbassadorResultModelResponseModel>(
      url,
      form
    );
  }

  public static ValidateAccount(body: string) {
    const url = `${environment.IDENTITY_URL}/identity/Account/validateaccount/${body}`;
    return this.httpClient.post<ResponsiveModel<boolean>>(url, null);
  }
}
