/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccessLevelsEnumResponseModel } from '../models/access-levels-enum-response-model';
import { AccountAddressUpdateInputModel } from '../models/account-address-update-input-model';
import { AccountBirthdayUpdateInputModel } from '../models/account-birthday-update-input-model';
import { AccountClubInputModel } from '../models/account-club-input-model';
import { AccountClubListResponseModel } from '../models/account-club-list-response-model';
import { AccountClubResponseModel } from '../models/account-club-response-model';
import { AccountGuidInputModel } from '../models/account-guid-input-model';
import { AccountHashtagListResponseModel } from '../models/account-hashtag-list-response-model';
import { AccountInputModel } from '../models/account-input-model';
import { AccountObjectCreateInputModel } from '../models/account-object-create-input-model';
import { AccountObjectUpdateInputModel } from '../models/account-object-update-input-model';
import { AccountPaginationModelResponseModel } from '../models/account-pagination-model-response-model';
import { AccountResponseModel } from '../models/account-response-model';
import { AccountStringUpdateInputModel } from '../models/account-string-update-input-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { ClubPaginationModelResponseModel } from '../models/club-pagination-model-response-model';
import { DateTimeNullableResponseModel } from '../models/date-time-nullable-response-model';
import { GendersEnumResponseModel } from '../models/genders-enum-response-model';
import { HashtagPaginationModelResponseModel } from '../models/hashtag-pagination-model-response-model';
import { RolesEnumResponseModel } from '../models/roles-enum-response-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithAccountsPageGet
   */
  static readonly InlivewithAccountsPageGetPath = '/inlivewith/Accounts/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithAccountsPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithAccountsPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsPrivateGet
   */
  static readonly InlivewithAccountsPrivateGetPath = '/inlivewith/Accounts/Private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPrivateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPrivateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithAccountsPrivateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPrivateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPrivateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithAccountsPrivateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsPublicGet
   */
  static readonly InlivewithAccountsPublicGetPath = '/inlivewith/Accounts/Public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPublicGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPublicGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithAccountsPublicGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPublicGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPublicGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithAccountsPublicGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsDeletedGet
   */
  static readonly InlivewithAccountsDeletedGetPath = '/inlivewith/Accounts/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsDeletedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsDeletedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsDeletedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsDeletedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithAccountsDeletedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsDeletedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsDeletedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsDeletedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsDeletedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithAccountsDeletedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsActivatedGet
   */
  static readonly InlivewithAccountsActivatedGetPath = '/inlivewith/Accounts/Activated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsActivatedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsActivatedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsActivatedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsActivatedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithAccountsActivatedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsActivatedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsActivatedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsActivatedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsActivatedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithAccountsActivatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountByNickNameNicknameGet
   */
  static readonly InlivewithAccountByNickNameNicknameGetPath = '/inlivewith/Account/ByNickName/{nickname}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByNickNameNicknameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByNickNameNicknameGet$Plain$Response(params: {
    nickname: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByNickNameNicknameGetPath, 'get');
    if (params) {
      rb.path('nickname', params.nickname, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByNickNameNicknameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByNickNameNicknameGet$Plain(params: {
    nickname: string;
    details?: boolean;
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountByNickNameNicknameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByNickNameNicknameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByNickNameNicknameGet$Json$Response(params: {
    nickname: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByNickNameNicknameGetPath, 'get');
    if (params) {
      rb.path('nickname', params.nickname, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByNickNameNicknameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByNickNameNicknameGet$Json(params: {
    nickname: string;
    details?: boolean;
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountByNickNameNicknameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountByEmailEmailGet
   */
  static readonly InlivewithAccountByEmailEmailGetPath = '/inlivewith/Account/ByEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByEmailEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Plain$Response(params: {
    email: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByEmailEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Plain(params: {
    email: string;
    details?: boolean;
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountByEmailEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByEmailEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Json$Response(params: {
    email: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByEmailEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Json(params: {
    email: string;
    details?: boolean;
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountByEmailEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountByIdAccountIdGet
   */
  static readonly InlivewithAccountByIdAccountIdGetPath = '/inlivewith/Account/ById/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdAccountIdGet$Plain$Response(params: {
    accountId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdAccountIdGet$Plain(params: {
    accountId: string;
    details?: boolean;
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountByIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdAccountIdGet$Json$Response(params: {
    accountId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdAccountIdGet$Json(params: {
    accountId: string;
    details?: boolean;
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountByIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountCreatePost
   */
  static readonly InlivewithAccountCreatePostPath = '/inlivewith/Account/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountCreatePost$Plain$Response(params?: {
    body?: AccountObjectCreateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountCreatePost$Plain(params?: {
    body?: AccountObjectCreateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountCreatePost$Json$Response(params?: {
    body?: AccountObjectCreateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountCreatePost$Json(params?: {
    body?: AccountObjectCreateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountUpdateAccountIdPut
   */
  static readonly InlivewithAccountUpdateAccountIdPutPath = '/inlivewith/Account/Update/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountUpdateAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountUpdateAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountEmailAccountIdGet
   */
  static readonly InlivewithAccountEmailAccountIdGetPath = '/inlivewith/Account/Email/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountEmailAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountEmailAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountEmailAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountEmailAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountEmailAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountEmailAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountEmailAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountEmailAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountEmailAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountEmailAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountEmailAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountEmailAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountGenderAccountIdGet
   */
  static readonly InlivewithAccountGenderAccountIdGetPath = '/inlivewith/Account/Gender/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGenderAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGenderAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGenderAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGenderAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGenderAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAccountGenderAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGenderAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGenderAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGenderAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGenderAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGenderAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAccountGenderAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountGenderAccountIdPut
   */
  static readonly InlivewithAccountGenderAccountIdPutPath = '/inlivewith/Account/Gender/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGenderAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGenderAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGenderAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGenderAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGenderAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountGenderAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGenderAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGenderAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGenderAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGenderAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGenderAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountGenderAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountBirthdayAccountIdGet
   */
  static readonly InlivewithAccountBirthdayAccountIdGetPath = '/inlivewith/Account/Birthday/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountBirthdayAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountBirthdayAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<DateTimeNullableResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountBirthdayAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeNullableResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountBirthdayAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountBirthdayAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<DateTimeNullableResponseModel> {

    return this.inlivewithAccountBirthdayAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeNullableResponseModel>) => r.body as DateTimeNullableResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountBirthdayAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountBirthdayAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<DateTimeNullableResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountBirthdayAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeNullableResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountBirthdayAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountBirthdayAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<DateTimeNullableResponseModel> {

    return this.inlivewithAccountBirthdayAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeNullableResponseModel>) => r.body as DateTimeNullableResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountBirthdayAccountIdPut
   */
  static readonly InlivewithAccountBirthdayAccountIdPutPath = '/inlivewith/Account/Birthday/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountBirthdayAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountBirthdayAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountBirthdayAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountBirthdayAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountBirthdayAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountBirthdayAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountBirthdayAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountBirthdayAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountAddressAccountIdPut
   */
  static readonly InlivewithAccountAddressAccountIdPutPath = '/inlivewith/Account/Address/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAddressAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAddressAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountAddressUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAddressAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAddressAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAddressAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountAddressUpdateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountAddressAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAddressAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAddressAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountAddressUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAddressAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAddressAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAddressAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountAddressUpdateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountAddressAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountNicknameAccountIdGet
   */
  static readonly InlivewithAccountNicknameAccountIdGetPath = '/inlivewith/Account/Nickname/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountNicknameAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountNicknameAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountNicknameAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountNicknameAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountNicknameAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountNicknameAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountNicknameAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountNicknameAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountNicknameAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountNicknameAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountNicknameAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountNicknameAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountNicknameAccountIdPut
   */
  static readonly InlivewithAccountNicknameAccountIdPutPath = '/inlivewith/Account/Nickname/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountNicknameAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountNicknameAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountNicknameAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountNicknameAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountNicknameAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountNicknameAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountNicknameAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountNicknameAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountNicknameAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountNicknameAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountNicknameAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountNicknameAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountAvatarAccountIdGet
   */
  static readonly InlivewithAccountAvatarAccountIdGetPath = '/inlivewith/Account/Avatar/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountAvatarAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountAvatarAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountAvatarAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountAvatarAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountAvatarAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountAvatarAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountAvatarAccountIdDelete
   */
  static readonly InlivewithAccountAvatarAccountIdDeletePath = '/inlivewith/Account/Avatar/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarAccountIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAvatarAccountIdDelete$Plain$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarAccountIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAvatarAccountIdDelete$Plain(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountAvatarAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarAccountIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAvatarAccountIdDelete$Json$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarAccountIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAvatarAccountIdDelete$Json(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountAvatarAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountAvatarUploadAccountIdPut
   */
  static readonly InlivewithAccountAvatarUploadAccountIdPutPath = '/inlivewith/Account/Avatar/Upload/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarUploadAccountIdPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarUploadAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: {
'AccountId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarUploadAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarUploadAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarUploadAccountIdPut$Plain(params: {
    accountId: string;
    body?: {
'AccountId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountAvatarUploadAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarUploadAccountIdPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarUploadAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: {
'AccountId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarUploadAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarUploadAccountIdPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarUploadAccountIdPut$Json(params: {
    accountId: string;
    body?: {
'AccountId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountAvatarUploadAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountIsPublicAccountIdGet
   */
  static readonly InlivewithAccountIsPublicAccountIdGetPath = '/inlivewith/Account/IsPublic/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsPublicAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPublicAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsPublicAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsPublicAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPublicAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsPublicAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsPublicAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPublicAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsPublicAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsPublicAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPublicAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsPublicAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountPrivateAccountIdPut
   */
  static readonly InlivewithAccountPrivateAccountIdPutPath = '/inlivewith/Account/Private/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPrivateAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPrivateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPrivateAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountPrivateAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPrivateAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPrivateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPrivateAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountPrivateAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountPublicAccountIdPut
   */
  static readonly InlivewithAccountPublicAccountIdPutPath = '/inlivewith/Account/Public/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPublicAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPublicAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPublicAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountPublicAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPublicAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPublicAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPublicAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountPublicAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountHashtagsAccountIdGet
   */
  static readonly InlivewithAccountHashtagsAccountIdGetPath = '/inlivewith/Account/Hashtags/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountHashtagsAccountIdPut
   */
  static readonly InlivewithAccountHashtagsAccountIdPutPath = '/inlivewith/Account/Hashtags/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountHashtagsAccountIdDelete
   */
  static readonly InlivewithAccountHashtagsAccountIdDeletePath = '/inlivewith/Account/Hashtags/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdDelete$Plain$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdDelete$Plain(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdDelete$Json$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdDelete$Json(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountHashtagsAddAccountIdPost
   */
  static readonly InlivewithAccountHashtagsAddAccountIdPostPath = '/inlivewith/Account/Hashtags/Add/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAddAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAddAccountIdPost$Plain$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAddAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAddAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAddAccountIdPost$Plain(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAddAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAddAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAddAccountIdPost$Json$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAddAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAddAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAddAccountIdPost$Json(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAddAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubsAccountIdGet
   */
  static readonly InlivewithAccountClubsAccountIdGetPath = '/inlivewith/Account/Clubs/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubsAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubsAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubsAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubsAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubsAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithAccountClubsAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubsAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubsAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubsAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubsAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubsAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithAccountClubsAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubsAccountIdPost
   */
  static readonly InlivewithAccountClubsAccountIdPostPath = '/inlivewith/Account/Clubs/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubsAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubsAccountIdPost$Plain$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubsAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubsAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubsAccountIdPost$Plain(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithAccountClubsAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubsAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubsAccountIdPost$Json$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubsAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubsAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubsAccountIdPost$Json(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithAccountClubsAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubsAccountIdDelete
   */
  static readonly InlivewithAccountClubsAccountIdDeletePath = '/inlivewith/Account/Clubs/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubsAccountIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubsAccountIdDelete$Plain$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubsAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubsAccountIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubsAccountIdDelete$Plain(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithAccountClubsAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubsAccountIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubsAccountIdDelete$Json$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubsAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubsAccountIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubsAccountIdDelete$Json(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithAccountClubsAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRoleIsAdminAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubRoleIsAdminAccountIdClubIdGetPath = '/inlivewith/Account/Club/Role/IsAdmin/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleIsAdminAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAdminAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleIsAdminAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleIsAdminAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAdminAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubRoleIsAdminAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleIsAdminAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAdminAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleIsAdminAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleIsAdminAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAdminAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubRoleIsAdminAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRoleAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubRoleAccountIdClubIdGetPath = '/inlivewith/Account/Club/Role/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<RolesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RolesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<RolesEnumResponseModel> {

    return this.inlivewithAccountClubRoleAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RolesEnumResponseModel>) => r.body as RolesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<RolesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RolesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<RolesEnumResponseModel> {

    return this.inlivewithAccountClubRoleAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RolesEnumResponseModel>) => r.body as RolesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRoleAnonymousAccountIdClubIdPut
   */
  static readonly InlivewithAccountClubRoleAnonymousAccountIdClubIdPutPath = '/inlivewith/Account/Club/Role/Anonymous/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAnonymousAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAnonymousAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRoleAdministratorAccountIdClubIdPut
   */
  static readonly InlivewithAccountClubRoleAdministratorAccountIdClubIdPutPath = '/inlivewith/Account/Club/Role/Administrator/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAdministratorAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAdministratorAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGetPath = '/inlivewith/Account/Club/AccessLevel/IsPlus/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccessLevelAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubAccessLevelAccountIdClubIdGetPath = '/inlivewith/Account/Club/AccessLevel/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelAccountIdClubIdGet$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccessLevelsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessLevelsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccessLevelsEnumResponseModel> {

    return this.inlivewithAccountClubAccessLevelAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccessLevelsEnumResponseModel>) => r.body as AccessLevelsEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelAccountIdClubIdGet$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccessLevelsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessLevelsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccessLevelsEnumResponseModel> {

    return this.inlivewithAccountClubAccessLevelAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccessLevelsEnumResponseModel>) => r.body as AccessLevelsEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut
   */
  static readonly InlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPutPath = '/inlivewith/Account/Club/AccessLevel/Anonymous/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut
   */
  static readonly InlivewithAccountClubAccessLevelPlusAccountIdClubIdPutPath = '/inlivewith/Account/Club/AccessLevel/Plus/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelPlusAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelPlusAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountIsDeletedAccountIdGet
   */
  static readonly InlivewithAccountIsDeletedAccountIdGetPath = '/inlivewith/Account/IsDeleted/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsDeletedAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsDeletedAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsDeletedAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsDeletedAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsDeletedAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsDeletedAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsDeletedAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsDeletedAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsDeletedAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsDeletedAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsDeletedAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsDeletedAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountDeleteAccountIdDelete
   */
  static readonly InlivewithAccountDeleteAccountIdDeletePath = '/inlivewith/Account/Delete/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountDeleteAccountIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDeleteAccountIdDelete$Plain$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountDeleteAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountDeleteAccountIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDeleteAccountIdDelete$Plain(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountDeleteAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountDeleteAccountIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDeleteAccountIdDelete$Json$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountDeleteAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountDeleteAccountIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDeleteAccountIdDelete$Json(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseModel> {

    return this.inlivewithAccountDeleteAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

}
