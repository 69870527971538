import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { AccountResponseWithRoleInCLubModel, CommentPostResponseModel, MediaPostResponseModel, Post, PostResponseModel, ReactionPostResponseModel } from 'src/app/core/api/be/models';
import { MessageService, PostService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-card-post',
  templateUrl: './card-post.component.html',
  styleUrls: ['./card-post.component.scss']
})
export class CardPostComponent implements OnInit {

  @Input() post!: PostResponseModel;
  @Input() listMember: AccountResponseWithRoleInCLubModel[] = [];
  @Input() selectedPost!: PostResponseModel
  @Input() checkClicked: boolean = false;

  @Output() deletePostEvent = new EventEmitter<string>();
  @Output() selectedPostChange = new EventEmitter();
  listCommentsPost: CommentPostResponseModel[] = [];
  listReactsPost: ReactionPostResponseModel[] = [];

  roleOfPostAuthor!: string;

  defaultAvatar = defaultAvatar;

  created: boolean = false;
  isOverflow!: boolean;
  isHidden!: boolean;
  isAcceptToDelete!: boolean;
  showConfirmDeletePost: boolean = false;
  show: boolean = false;
  openLikesZone: boolean = false;
  openComentsZone: boolean = false;
  completeText!: boolean;

  listIsLandscape: boolean[] = [];

  tribute!: any;
  currentControl: any

  constructor(
    private _postService: PostService,
    private domSanitizer: DomSanitizer,
    public _router: Router,
    private _translateService: TranslateService,
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isOverflow = this.checkOverflow(this.post.id!);
    }, 1000);
  }

  ngOnInit(): void {
    this.roleOfPostAuthor =
      this.post.club?.roleOfPostAuthor! == 'ROLE_ADMIN' &&
        this.post.author?.id! === this.post.club?.authorId!
        ? 'ROLE_CREATOR'
        : this.post.club?.roleOfPostAuthor! == 'ROLE_ADMIN' &&
          this.post.author?.id! !== this.post.club?.author?.id!
          ? 'ROLE_ADMIN'
          : '';
  }


  timeAgo = (date: string) => {
    let currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();
    return moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();
  };

  deletePost() {
    this.deletePostEvent.emit(this.post.id);
    this._postService
      .inlivewithPostDeletePostDelete$Json({
        accountRequestDeleteId: AuthService.GetAccountInfo().id!,
        postId: this.post.id,
      })
      .subscribe();
  }

  openCommentZone(isMobile: boolean = false) {

  }

  updatePost() {
    return this._postService.inlivewithPostGetByIdPostIdAccountRequestIdGet$Json(
      {
        postId: this.post.id!,
        accountRequestId: AuthService.GetAccountInfo().id!,
      }
    );
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '');
    let user = this.listMember?.filter(
      (user) => user!.nickName?.replace(' ', '_') == uname
    )[0];

    if (user) {
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replace('_', ' ')}" target="_blank">${username}</a>`;
    }
  }

  urlify(text: any) {
    var translatePipe = new LimitStringPipe();

    let mentionRegex = /\B@\w+/g;

    text = text?.replace(mentionRegex, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    var urlRegex =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return text?.replace(urlRegex, (url: any) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

  openAccountInfo() {
    if (AuthService.GetAccountInfo().id! !== this.post.author?.id!) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: this.post.author?.id! },
      });
    }
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.')
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }

    }
    return asset?.url!;
  }

  handleText() {
    this.show = !this.show;
    if (this.show) {
      this.completeText = true;
    } else {
      this.completeText = false;
    }
  }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        if (asset?.url!.includes('agora/recording')) {
          return 'RECORD';
        } else {
          return 'VIDEO';
        }
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  selectedPostEvent() {
    this.selectedPostChange.emit(this.post!);
    this.getListComments()
    this.getListReacts();
  }

  getListComments() {
    this._postService.inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Json({
      accountRequestId: this.post.club?.authorId!,
      postId: this.post.id!,
      limit: 10000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listCommentsPost = res.data?.page!
        }
      })
    ).subscribe();
  }

  getListReacts() {
    this._postService.inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Json({
      accountRequestId: this.post.club?.authorId!,
      postId: this.post.id!,
      limit: 10000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listReactsPost = res.data?.page!
        }
      })
    ).subscribe();
  }

  onLoad(event: Event, i: number) {
    const smallImage = event?.target as HTMLImageElement;
    let imageWidth = Number(smallImage.naturalWidth);
    let imageHeight = Number(smallImage.naturalHeight);
    this.listIsLandscape[i] = (imageWidth - 200) / imageHeight >= 1;
    //this.post.mediaPosts![i]['isLandscape'] = this.listIsLandscape[i];
  }

  initZoneControl() {
    var zoneControl: any = [
      {
        id: 0,
        title: this._translateService.instant('LIKES'),
        total: this.post?.totalReact!,
        command: () => {
          this.currentControl = zoneControl[0]
          this.openLikesZone = true
        }
      },
      {
        id: 1,
        title: this._translateService.instant('COMMENTS'),
        total: this.post?.totalComment!,
        command: () => {
          this.currentControl = zoneControl[1]
          this.openComentsZone = true
        }
      }
    ]

    if (!this.currentControl) {
      this.currentControl = zoneControl[1]
    }

    return zoneControl;
  }

  checkOverflow(id: string): boolean {
    const elem = document.getElementById(id);
    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    } else {
      return false;
    }
  }

  scrollTo(id: string, isLeft: boolean = true) {
    let a = document.getElementById(id)!;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }
}
