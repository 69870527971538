<div class="card" *ngIf="report">
  <div class="zone-info-user">
    <div class="flex align-items-center" *ngIf="report.type == 'REPORT_COMMENT_POST'">
      <div class="zone-avt mr-2">
        <img class="fit-size" [src]="report.commentPost!.author!.avatar! ? report.commentPost!.author!.avatar! : defaultAvatar">
      </div>
      <div class="zone-name ml-2">
        <span>{{report.commentPost?.author?.nickName!}}</span>
        <span class="text-red">
          <span>{{report.reports?.length!}} {{(report.reports?.length! > 1 ? 'CLUB_SIGNALEMENTS' :
            'SIGNALEMENT') | translate | lowercase}}</span>
        </span>
      </div>
    </div>
    <div class="flex align-items-center" *ngIf="report.type == 'REPORT_COMMENT_ALBUM'">
      <div class="zone-avt mr-2">
        <img class="fit-size" [src]="report.commentAlbumAsset?.author?.avatar! ? report.commentAlbumAsset?.author?.avatar! : defaultAvatar">
      </div>
      <div class="zone-name ml-2">
        <span>{{report.commentAlbumAsset?.author?.nickName!}}</span>
        <span class="text-red">
          <span>{{report.reports?.length!}} {{(report.reports?.length! > 1 ? 'CLUB_SIGNALEMENTS' :
            'SIGNALEMENT') | translate | lowercase}}</span>
        </span>
      </div>
    </div>
    <div class="flex align-items-center" *ngIf="report.type == 'REPORT_POST'">
      <div class="zone-avt mr-2">
        <img class="fit-size" [src]="report.post?.author?.avatar! ? report.post?.author?.avatar! : defaultAvatar">
      </div>
      <div class="zone-name ml-2">
        <span>{{report.post?.author?.nickName!}}</span>
        <span class="text-red">
          <span>{{report.reports?.length!}} {{(report.reports?.length! > 1 ? 'CLUB_SIGNALEMENTS' :
            'SIGNALEMENT') | translate | lowercase}}</span>
        </span>
      </div>
    </div>
  </div>
  <div class="flex align-items-flex-start mt-3 ml-5">
    <div *ngFor="let reporter of report.reports">
      <div class="flex flex-column ml-4">
        <div class="zone-info-user">
          <div class="zone-avt mr-1">
            <img class="fit-size" [src]="reporter.reporterAvatar! ? reporter.reporterAvatar : defaultAvatar">
          </div>
          <div class="zone-name ml-1">
            <span>{{reporter.reporterNickname!}}</span>
            <span class="text-disabled">
              {{timeAgo(reporter.dateCreated!)}}
            </span>
          </div>
        </div>
        <div class="flex flex-column mt-3">
          <span class="text-gray text-bold">{{getNameByKey(reporter.reasonReport!)}}</span>
          <span class="mt-2 fs-12" *ngIf="reporter.reasonReport! == 'OTHER'">
            {{reporter.description!}}
          </span>
        </div>
      </div>
    </div>
  </div>

</div>
