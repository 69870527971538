/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AlbumAssetListResponseModel } from '../models/album-asset-list-response-model';
import { AlbumDeleteFilesInputModel } from '../models/album-delete-files-input-model';
import { AlbumInputModel } from '../models/album-input-model';
import { AlbumObjectCreateInputModel } from '../models/album-object-create-input-model';
import { AlbumPaginationModelResponseModel } from '../models/album-pagination-model-response-model';
import { AlbumResponseModel } from '../models/album-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { Int64ResponseModel } from '../models/int-64-response-model';

@Injectable({
  providedIn: 'root',
})
export class AlbumService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithAlbumsPageGet
   */
  static readonly InlivewithAlbumsPageGetPath = '/inlivewith/Albums/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPageGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPageGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsClubIdGet
   */
  static readonly InlivewithAlbumsClubIdGetPath = '/inlivewith/Albums/ByClub/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsStageIdGet
   */
  static readonly InlivewithAlbumsStageIdGetPath = '/inlivewith/Albums/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsStageIdGet$Plain$Response(params: {
    stageId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsStageIdGet$Plain(params: {
    stageId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsStageIdGet$Json$Response(params: {
    stageId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsStageIdGet$Json(params: {
    stageId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsPrivateGet
   */
  static readonly InlivewithAlbumsPrivateGetPath = '/inlivewith/Albums/Private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPrivateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPrivateGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPrivateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPrivateGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsPrivateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPrivateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPrivateGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPrivateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPrivateGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsPrivateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsPublicGet
   */
  static readonly InlivewithAlbumsPublicGetPath = '/inlivewith/Albums/Public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPublicGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPublicGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPublicGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPublicGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsPublicGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsPublicGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPublicGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsPublicGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsPublicGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsPublicGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsIsForAllGet
   */
  static readonly InlivewithAlbumsIsForAllGetPath = '/inlivewith/Albums/IsForAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsIsForAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsForAllGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsIsForAllGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsIsForAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsForAllGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsIsForAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsIsForAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsForAllGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsIsForAllGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsIsForAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsForAllGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsIsForAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsIsNotForAllGet
   */
  static readonly InlivewithAlbumsIsNotForAllGetPath = '/inlivewith/Albums/IsNotForAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsIsNotForAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsNotForAllGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsIsNotForAllGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsIsNotForAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsNotForAllGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsIsNotForAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsIsNotForAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsNotForAllGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsIsNotForAllGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsIsNotForAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsIsNotForAllGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsIsNotForAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsDeletedGet
   */
  static readonly InlivewithAlbumsDeletedGetPath = '/inlivewith/Albums/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsDeletedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsDeletedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsDeletedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsDeletedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsDeletedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsDeletedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsDeletedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsDeletedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsDeletedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsDeletedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumsActivatedGet
   */
  static readonly InlivewithAlbumsActivatedGetPath = '/inlivewith/Albums/Activated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsActivatedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsActivatedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsActivatedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsActivatedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsActivatedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsActivatedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsActivatedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsActivatedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsActivatedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumPaginationModelResponseModel> {

    return this.inlivewithAlbumsActivatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumPaginationModelResponseModel>) => r.body as AlbumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetByIdAlbumIdGet
   */
  static readonly InlivewithAlbumGetByIdAlbumIdGetPath = '/inlivewith/Album/GetById/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetByIdAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetByIdAlbumIdGet$Plain$Response(params: {
    albumId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetByIdAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetByIdAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetByIdAlbumIdGet$Plain(params: {
    albumId: string;
    details?: boolean;
  }): Observable<AlbumResponseModel> {

    return this.inlivewithAlbumGetByIdAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseModel>) => r.body as AlbumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetByIdAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetByIdAlbumIdGet$Json$Response(params: {
    albumId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetByIdAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetByIdAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetByIdAlbumIdGet$Json(params: {
    albumId: string;
    details?: boolean;
  }): Observable<AlbumResponseModel> {

    return this.inlivewithAlbumGetByIdAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseModel>) => r.body as AlbumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumCreateAdminIdClubIdPost
   */
  static readonly InlivewithAlbumCreateAdminIdClubIdPostPath = '/inlivewith/Album/Create/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCreateAdminIdClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumCreateAdminIdClubIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: AlbumObjectCreateInputModel
  }): Observable<StrictHttpResponse<AlbumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCreateAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCreateAdminIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumCreateAdminIdClubIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    body?: AlbumObjectCreateInputModel
  }): Observable<AlbumResponseModel> {

    return this.inlivewithAlbumCreateAdminIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseModel>) => r.body as AlbumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumCreateAdminIdClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumCreateAdminIdClubIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: AlbumObjectCreateInputModel
  }): Observable<StrictHttpResponse<AlbumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumCreateAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumCreateAdminIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumCreateAdminIdClubIdPost$Json(params: {
    adminId: string;
    clubId: string;
    body?: AlbumObjectCreateInputModel
  }): Observable<AlbumResponseModel> {

    return this.inlivewithAlbumCreateAdminIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseModel>) => r.body as AlbumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumIsPublicAlbumIdGet
   */
  static readonly InlivewithAlbumIsPublicAlbumIdGetPath = '/inlivewith/Album/IsPublic/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumIsPublicAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPublicAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumIsPublicAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumIsPublicAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPublicAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumIsPublicAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumIsPublicAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPublicAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumIsPublicAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumIsPublicAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumIsPublicAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAlbumIsPublicAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut
   */
  static readonly InlivewithAlbumPrivateAdminIdClubIdAlbumIdPutPath = '/inlivewith/Album/Private/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumPrivateAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseModel> {

    return this.inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseModel>) => r.body as AlbumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumPrivateAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseModel> {

    return this.inlivewithAlbumPrivateAdminIdClubIdAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseModel>) => r.body as AlbumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumPublicAdminIdClubIdAlbumIdPut
   */
  static readonly InlivewithAlbumPublicAdminIdClubIdAlbumIdPutPath = '/inlivewith/Album/Public/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumPublicAdminIdClubIdAlbumIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPublicAdminIdClubIdAlbumIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumPublicAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumPublicAdminIdClubIdAlbumIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPublicAdminIdClubIdAlbumIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseModel> {

    return this.inlivewithAlbumPublicAdminIdClubIdAlbumIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseModel>) => r.body as AlbumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumPublicAdminIdClubIdAlbumIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPublicAdminIdClubIdAlbumIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumPublicAdminIdClubIdAlbumIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumPublicAdminIdClubIdAlbumIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumPublicAdminIdClubIdAlbumIdPut$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumInputModel
  }): Observable<AlbumResponseModel> {

    return this.inlivewithAlbumPublicAdminIdClubIdAlbumIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseModel>) => r.body as AlbumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumFilesCountAlbumIdGet
   */
  static readonly InlivewithAlbumFilesCountAlbumIdGetPath = '/inlivewith/Album/Files/Count/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesCountAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesCountAlbumIdGet$Plain$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<Int64ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesCountAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesCountAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesCountAlbumIdGet$Plain(params: {
    albumId: string;
  }): Observable<Int64ResponseModel> {

    return this.inlivewithAlbumFilesCountAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ResponseModel>) => r.body as Int64ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesCountAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesCountAlbumIdGet$Json$Response(params: {
    albumId: string;
  }): Observable<StrictHttpResponse<Int64ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesCountAlbumIdGetPath, 'get');
    if (params) {
      rb.path('albumId', params.albumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesCountAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesCountAlbumIdGet$Json(params: {
    albumId: string;
  }): Observable<Int64ResponseModel> {

    return this.inlivewithAlbumFilesCountAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ResponseModel>) => r.body as Int64ResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumFilesAdminIdClubIdAlbumIdPost
   */
  static readonly InlivewithAlbumFilesAdminIdClubIdAlbumIdPostPath = '/inlivewith/Album/Files/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAdminIdClubIdAlbumIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAdminIdClubIdAlbumIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'AlbumId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<AlbumAssetListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAdminIdClubIdAlbumIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAdminIdClubIdAlbumIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAdminIdClubIdAlbumIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'AlbumId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<AlbumAssetListResponseModel> {

    return this.inlivewithAlbumFilesAdminIdClubIdAlbumIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetListResponseModel>) => r.body as AlbumAssetListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAdminIdClubIdAlbumIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAdminIdClubIdAlbumIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'AlbumId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<AlbumAssetListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAdminIdClubIdAlbumIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAdminIdClubIdAlbumIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAlbumFilesAdminIdClubIdAlbumIdPost$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'AlbumId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<AlbumAssetListResponseModel> {

    return this.inlivewithAlbumFilesAdminIdClubIdAlbumIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetListResponseModel>) => r.body as AlbumAssetListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete
   */
  static readonly InlivewithAlbumFilesAdminIdClubIdAlbumIdDeletePath = '/inlivewith/Album/Files/{adminId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<StrictHttpResponse<AlbumAssetListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAdminIdClubIdAlbumIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<AlbumAssetListResponseModel> {

    return this.inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetListResponseModel>) => r.body as AlbumAssetListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<StrictHttpResponse<AlbumAssetListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAdminIdClubIdAlbumIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<AlbumAssetListResponseModel> {

    return this.inlivewithAlbumFilesAdminIdClubIdAlbumIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetListResponseModel>) => r.body as AlbumAssetListResponseModel)
    );
  }

}
