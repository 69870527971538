/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { ClientUpdateInputModel } from '../models/client-update-input-model';
import { FirebaseClientCreateInput } from '../models/firebase-client-create-input';
import { Int32ResponseModel } from '../models/int-32-response-model';
import { NotificationCreate } from '../models/notification-create';
import { NotificationForManyReceiverCreate } from '../models/notification-for-many-receiver-create';
import { NotificationResponseListResponseModel } from '../models/notification-response-list-response-model';
import { NotificationResponsePaginationModelResponseModel } from '../models/notification-response-pagination-model-response-model';
import { NotificationResponseResponseModel } from '../models/notification-response-response-model';
import { NotificationUpdate } from '../models/notification-update';
import { TypeNotiInputModel } from '../models/type-noti-input-model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithNotificationCreateFirebaseClientPost
   */
  static readonly InlivewithNotificationCreateFirebaseClientPostPath = '/inlivewith/Notification/CreateFirebaseClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationCreateFirebaseClientPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreateFirebaseClientPost$Plain$Response(params?: {
    body?: Array<FirebaseClientCreateInput>
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationCreateFirebaseClientPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationCreateFirebaseClientPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreateFirebaseClientPost$Plain(params?: {
    body?: Array<FirebaseClientCreateInput>
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationCreateFirebaseClientPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationCreateFirebaseClientPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreateFirebaseClientPost$Json$Response(params?: {
    body?: Array<FirebaseClientCreateInput>
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationCreateFirebaseClientPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationCreateFirebaseClientPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreateFirebaseClientPost$Json(params?: {
    body?: Array<FirebaseClientCreateInput>
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationCreateFirebaseClientPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationUpdateFirebaseClientPut
   */
  static readonly InlivewithNotificationUpdateFirebaseClientPutPath = '/inlivewith/Notification/UpdateFirebaseClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationUpdateFirebaseClientPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationUpdateFirebaseClientPut$Plain$Response(params?: {
    body?: ClientUpdateInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationUpdateFirebaseClientPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationUpdateFirebaseClientPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationUpdateFirebaseClientPut$Plain(params?: {
    body?: ClientUpdateInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationUpdateFirebaseClientPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationUpdateFirebaseClientPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationUpdateFirebaseClientPut$Json$Response(params?: {
    body?: ClientUpdateInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationUpdateFirebaseClientPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationUpdateFirebaseClientPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationUpdateFirebaseClientPut$Json(params?: {
    body?: ClientUpdateInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationUpdateFirebaseClientPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationDeleteFirebaseClientByAccountDelete
   */
  static readonly InlivewithNotificationDeleteFirebaseClientByAccountDeletePath = '/inlivewith/Notification/DeleteFirebaseClientByAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationDeleteFirebaseClientByAccountDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationDeleteFirebaseClientByAccountDelete$Plain$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationDeleteFirebaseClientByAccountDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationDeleteFirebaseClientByAccountDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationDeleteFirebaseClientByAccountDelete$Plain(params?: {
    body?: string
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationDeleteFirebaseClientByAccountDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationDeleteFirebaseClientByAccountDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationDeleteFirebaseClientByAccountDelete$Json$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationDeleteFirebaseClientByAccountDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationDeleteFirebaseClientByAccountDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationDeleteFirebaseClientByAccountDelete$Json(params?: {
    body?: string
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationDeleteFirebaseClientByAccountDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationDeleteFirebaseClientByClientIdDelete
   */
  static readonly InlivewithNotificationDeleteFirebaseClientByClientIdDeletePath = '/inlivewith/Notification/DeleteFirebaseClientByClientId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationDeleteFirebaseClientByClientIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationDeleteFirebaseClientByClientIdDelete$Plain$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationDeleteFirebaseClientByClientIdDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationDeleteFirebaseClientByClientIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationDeleteFirebaseClientByClientIdDelete$Plain(params?: {
    body?: string
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationDeleteFirebaseClientByClientIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationDeleteFirebaseClientByClientIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationDeleteFirebaseClientByClientIdDelete$Json$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationDeleteFirebaseClientByClientIdDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationDeleteFirebaseClientByClientIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationDeleteFirebaseClientByClientIdDelete$Json(params?: {
    body?: string
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationDeleteFirebaseClientByClientIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationCreatePost
   */
  static readonly InlivewithNotificationCreatePostPath = '/inlivewith/Notification/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreatePost$Plain$Response(params?: {
    body?: NotificationCreate
  }): Observable<StrictHttpResponse<NotificationResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreatePost$Plain(params?: {
    body?: NotificationCreate
  }): Observable<NotificationResponseResponseModel> {

    return this.inlivewithNotificationCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponseResponseModel>) => r.body as NotificationResponseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreatePost$Json$Response(params?: {
    body?: NotificationCreate
  }): Observable<StrictHttpResponse<NotificationResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreatePost$Json(params?: {
    body?: NotificationCreate
  }): Observable<NotificationResponseResponseModel> {

    return this.inlivewithNotificationCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponseResponseModel>) => r.body as NotificationResponseResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationCreateNotiForManyPost
   */
  static readonly InlivewithNotificationCreateNotiForManyPostPath = '/inlivewith/Notification/CreateNotiForMany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationCreateNotiForManyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreateNotiForManyPost$Plain$Response(params?: {
    body?: NotificationForManyReceiverCreate
  }): Observable<StrictHttpResponse<NotificationResponseListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationCreateNotiForManyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponseListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationCreateNotiForManyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreateNotiForManyPost$Plain(params?: {
    body?: NotificationForManyReceiverCreate
  }): Observable<NotificationResponseListResponseModel> {

    return this.inlivewithNotificationCreateNotiForManyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponseListResponseModel>) => r.body as NotificationResponseListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationCreateNotiForManyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreateNotiForManyPost$Json$Response(params?: {
    body?: NotificationForManyReceiverCreate
  }): Observable<StrictHttpResponse<NotificationResponseListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationCreateNotiForManyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponseListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationCreateNotiForManyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationCreateNotiForManyPost$Json(params?: {
    body?: NotificationForManyReceiverCreate
  }): Observable<NotificationResponseListResponseModel> {

    return this.inlivewithNotificationCreateNotiForManyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponseListResponseModel>) => r.body as NotificationResponseListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationPageGet
   */
  static readonly InlivewithNotificationPageGetPath = '/inlivewith/Notification/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<NotificationResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationPageGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<NotificationResponsePaginationModelResponseModel> {

    return this.inlivewithNotificationPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponsePaginationModelResponseModel>) => r.body as NotificationResponsePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<NotificationResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationPageGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<NotificationResponsePaginationModelResponseModel> {

    return this.inlivewithNotificationPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponsePaginationModelResponseModel>) => r.body as NotificationResponsePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationGetByIdGet
   */
  static readonly InlivewithNotificationGetByIdGetPath = '/inlivewith/Notification/GetById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationGetByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetByIdGet$Plain$Response(params?: {
    notificationId?: string;
  }): Observable<StrictHttpResponse<NotificationResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationGetByIdGetPath, 'get');
    if (params) {
      rb.query('notificationId', params.notificationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationGetByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetByIdGet$Plain(params?: {
    notificationId?: string;
  }): Observable<NotificationResponseResponseModel> {

    return this.inlivewithNotificationGetByIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponseResponseModel>) => r.body as NotificationResponseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationGetByIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetByIdGet$Json$Response(params?: {
    notificationId?: string;
  }): Observable<StrictHttpResponse<NotificationResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationGetByIdGetPath, 'get');
    if (params) {
      rb.query('notificationId', params.notificationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationGetByIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetByIdGet$Json(params?: {
    notificationId?: string;
  }): Observable<NotificationResponseResponseModel> {

    return this.inlivewithNotificationGetByIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponseResponseModel>) => r.body as NotificationResponseResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationUpdatePut
   */
  static readonly InlivewithNotificationUpdatePutPath = '/inlivewith/Notification/Update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationUpdatePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationUpdatePut$Plain$Response(params?: {
    body?: NotificationUpdate
  }): Observable<StrictHttpResponse<NotificationResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationUpdatePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationUpdatePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationUpdatePut$Plain(params?: {
    body?: NotificationUpdate
  }): Observable<NotificationResponseResponseModel> {

    return this.inlivewithNotificationUpdatePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponseResponseModel>) => r.body as NotificationResponseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationUpdatePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationUpdatePut$Json$Response(params?: {
    body?: NotificationUpdate
  }): Observable<StrictHttpResponse<NotificationResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationUpdatePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationUpdatePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationUpdatePut$Json(params?: {
    body?: NotificationUpdate
  }): Observable<NotificationResponseResponseModel> {

    return this.inlivewithNotificationUpdatePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponseResponseModel>) => r.body as NotificationResponseResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationGetByReceiverGet
   */
  static readonly InlivewithNotificationGetByReceiverGetPath = '/inlivewith/Notification/GetByReceiver';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationGetByReceiverGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetByReceiverGet$Plain$Response(params?: {
    receiverId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<NotificationResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationGetByReceiverGetPath, 'get');
    if (params) {
      rb.query('receiverId', params.receiverId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationGetByReceiverGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetByReceiverGet$Plain(params?: {
    receiverId?: string;
    page?: number;
    limit?: number;
  }): Observable<NotificationResponsePaginationModelResponseModel> {

    return this.inlivewithNotificationGetByReceiverGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponsePaginationModelResponseModel>) => r.body as NotificationResponsePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationGetByReceiverGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetByReceiverGet$Json$Response(params?: {
    receiverId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<NotificationResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationGetByReceiverGetPath, 'get');
    if (params) {
      rb.query('receiverId', params.receiverId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationGetByReceiverGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetByReceiverGet$Json(params?: {
    receiverId?: string;
    page?: number;
    limit?: number;
  }): Observable<NotificationResponsePaginationModelResponseModel> {

    return this.inlivewithNotificationGetByReceiverGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponsePaginationModelResponseModel>) => r.body as NotificationResponsePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationGetNotificationByTypePost
   */
  static readonly InlivewithNotificationGetNotificationByTypePostPath = '/inlivewith/Notification/GetNotificationByType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationGetNotificationByTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationGetNotificationByTypePost$Plain$Response(params?: {
    page?: number;
    limit?: number;
    body?: TypeNotiInputModel
  }): Observable<StrictHttpResponse<NotificationResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationGetNotificationByTypePostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationGetNotificationByTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationGetNotificationByTypePost$Plain(params?: {
    page?: number;
    limit?: number;
    body?: TypeNotiInputModel
  }): Observable<NotificationResponsePaginationModelResponseModel> {

    return this.inlivewithNotificationGetNotificationByTypePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponsePaginationModelResponseModel>) => r.body as NotificationResponsePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationGetNotificationByTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationGetNotificationByTypePost$Json$Response(params?: {
    page?: number;
    limit?: number;
    body?: TypeNotiInputModel
  }): Observable<StrictHttpResponse<NotificationResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationGetNotificationByTypePostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationGetNotificationByTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationGetNotificationByTypePost$Json(params?: {
    page?: number;
    limit?: number;
    body?: TypeNotiInputModel
  }): Observable<NotificationResponsePaginationModelResponseModel> {

    return this.inlivewithNotificationGetNotificationByTypePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponsePaginationModelResponseModel>) => r.body as NotificationResponsePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationGetBySenderGet
   */
  static readonly InlivewithNotificationGetBySenderGetPath = '/inlivewith/Notification/GetBySender';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationGetBySenderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetBySenderGet$Plain$Response(params?: {
    senderId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<NotificationResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationGetBySenderGetPath, 'get');
    if (params) {
      rb.query('senderId', params.senderId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationGetBySenderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetBySenderGet$Plain(params?: {
    senderId?: string;
    page?: number;
    limit?: number;
  }): Observable<NotificationResponsePaginationModelResponseModel> {

    return this.inlivewithNotificationGetBySenderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponsePaginationModelResponseModel>) => r.body as NotificationResponsePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationGetBySenderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetBySenderGet$Json$Response(params?: {
    senderId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<NotificationResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationGetBySenderGetPath, 'get');
    if (params) {
      rb.query('senderId', params.senderId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationGetBySenderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationGetBySenderGet$Json(params?: {
    senderId?: string;
    page?: number;
    limit?: number;
  }): Observable<NotificationResponsePaginationModelResponseModel> {

    return this.inlivewithNotificationGetBySenderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponsePaginationModelResponseModel>) => r.body as NotificationResponsePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationSetIsReadNotificationAccountRequestIdPut
   */
  static readonly InlivewithNotificationSetIsReadNotificationAccountRequestIdPutPath = '/inlivewith/Notification/SetIsReadNotification/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationSetIsReadNotificationAccountRequestIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationSetIsReadNotificationAccountRequestIdPut$Plain$Response(params: {
    accountRequestId: string;
    isRead?: boolean;
    body?: string
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationSetIsReadNotificationAccountRequestIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('isRead', params.isRead, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationSetIsReadNotificationAccountRequestIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationSetIsReadNotificationAccountRequestIdPut$Plain(params: {
    accountRequestId: string;
    isRead?: boolean;
    body?: string
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationSetIsReadNotificationAccountRequestIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationSetIsReadNotificationAccountRequestIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationSetIsReadNotificationAccountRequestIdPut$Json$Response(params: {
    accountRequestId: string;
    isRead?: boolean;
    body?: string
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationSetIsReadNotificationAccountRequestIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('isRead', params.isRead, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationSetIsReadNotificationAccountRequestIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithNotificationSetIsReadNotificationAccountRequestIdPut$Json(params: {
    accountRequestId: string;
    isRead?: boolean;
    body?: string
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationSetIsReadNotificationAccountRequestIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut
   */
  static readonly InlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPutPath = '/inlivewith/Notification/SetIsReadForAllNotifications/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut$Plain$Response(params: {
    accountRequestId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut$Plain(params: {
    accountRequestId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut$Json$Response(params: {
    accountRequestId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut$Json(params: {
    accountRequestId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationSetIsReadForAllNotificationsAccountRequestIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationDeleteDelete
   */
  static readonly InlivewithNotificationDeleteDeletePath = '/inlivewith/Notification/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationDeleteDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationDeleteDelete$Plain$Response(params?: {
    notiId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationDeleteDeletePath, 'delete');
    if (params) {
      rb.query('notiId', params.notiId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationDeleteDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationDeleteDelete$Plain(params?: {
    notiId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationDeleteDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationDeleteDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationDeleteDelete$Json$Response(params?: {
    notiId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationDeleteDeletePath, 'delete');
    if (params) {
      rb.query('notiId', params.notiId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationDeleteDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationDeleteDelete$Json(params?: {
    notiId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithNotificationDeleteDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithNotificationTotalNotisAccountRequestIdGet
   */
  static readonly InlivewithNotificationTotalNotisAccountRequestIdGetPath = '/inlivewith/Notification/TotalNotis/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationTotalNotisAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationTotalNotisAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    isRead?: boolean;
  }): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationTotalNotisAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('isRead', params.isRead, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationTotalNotisAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationTotalNotisAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    isRead?: boolean;
  }): Observable<Int32ResponseModel> {

    return this.inlivewithNotificationTotalNotisAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithNotificationTotalNotisAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationTotalNotisAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    isRead?: boolean;
  }): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.InlivewithNotificationTotalNotisAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('isRead', params.isRead, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithNotificationTotalNotisAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithNotificationTotalNotisAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    isRead?: boolean;
  }): Observable<Int32ResponseModel> {

    return this.inlivewithNotificationTotalNotisAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

}
