/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountEventResponseModelPaginationModelResponseModel } from '../models/account-event-response-model-pagination-model-response-model';
import { AccountEventResponseModelResponseModel } from '../models/account-event-response-model-response-model';
import { AccountResponseWithRoleInCLubModelPaginationModelResponseModel } from '../models/account-response-with-role-in-c-lub-model-pagination-model-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { EventObjectCreateInputModel } from '../models/event-object-create-input-model';
import { EventObjectJoinInputModel } from '../models/event-object-join-input-model';
import { EventResponseModelPaginationModelResponseModel } from '../models/event-response-model-pagination-model-response-model';
import { EventResponseModelResponseModel } from '../models/event-response-model-response-model';
import { EventUpdateInputModel } from '../models/event-update-input-model';
import { EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel } from '../models/event-with-check-account-join-event-response-model-pagination-model-response-model';
import { EventWithCheckAccountJoinEventResponseModelResponseModel } from '../models/event-with-check-account-join-event-response-model-response-model';
import { ProcessFileModel } from '../models/process-file-model';

@Injectable({
  providedIn: 'root',
})
export class EventService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithEventCreatePost
   */
  static readonly InlivewithEventCreatePostPath = '/inlivewith/Event/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventCreatePost$Plain$Response(params?: {
    body?: EventObjectCreateInputModel
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventCreatePost$Plain(params?: {
    body?: EventObjectCreateInputModel
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventCreatePost$Json$Response(params?: {
    body?: EventObjectCreateInputModel
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventCreatePost$Json(params?: {
    body?: EventObjectCreateInputModel
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventInvitesEventIdPost
   */
  static readonly InlivewithEventInvitesEventIdPostPath = '/inlivewith/Event/Invites/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventInvitesEventIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventInvitesEventIdPost$Plain$Response(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventInvitesEventIdPostPath, 'post');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventInvitesEventIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventInvitesEventIdPost$Plain(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventInvitesEventIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>) => r.body as AccountEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventInvitesEventIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventInvitesEventIdPost$Json$Response(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventInvitesEventIdPostPath, 'post');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventInvitesEventIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventInvitesEventIdPost$Json(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventInvitesEventIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>) => r.body as AccountEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventChangeListMemberJoinEventEventIdPut
   */
  static readonly InlivewithEventChangeListMemberJoinEventEventIdPutPath = '/inlivewith/Event/ChangeListMemberJoinEvent/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventChangeListMemberJoinEventEventIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventChangeListMemberJoinEventEventIdPut$Plain$Response(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventChangeListMemberJoinEventEventIdPutPath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventChangeListMemberJoinEventEventIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventChangeListMemberJoinEventEventIdPut$Plain(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventChangeListMemberJoinEventEventIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>) => r.body as AccountEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventChangeListMemberJoinEventEventIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventChangeListMemberJoinEventEventIdPut$Json$Response(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventChangeListMemberJoinEventEventIdPutPath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventChangeListMemberJoinEventEventIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventChangeListMemberJoinEventEventIdPut$Json(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventChangeListMemberJoinEventEventIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>) => r.body as AccountEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventUnInvitesEventIdDelete
   */
  static readonly InlivewithEventUnInvitesEventIdDeletePath = '/inlivewith/Event/UnInvites/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventUnInvitesEventIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUnInvitesEventIdDelete$Plain$Response(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventUnInvitesEventIdDeletePath, 'delete');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventUnInvitesEventIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUnInvitesEventIdDelete$Plain(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventUnInvitesEventIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>) => r.body as AccountEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventUnInvitesEventIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUnInvitesEventIdDelete$Json$Response(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventUnInvitesEventIdDeletePath, 'delete');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventUnInvitesEventIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUnInvitesEventIdDelete$Json(params: {
    eventId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventUnInvitesEventIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountEventResponseModelPaginationModelResponseModel>) => r.body as AccountEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet
   */
  static readonly InlivewithEventGetAccountJoinEventEventIdAccountRequestIdGetPath = '/inlivewith/Event/GetAccountJoinEvent/{eventId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Plain$Response(params: {
    eventId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetAccountJoinEventEventIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Plain(params: {
    eventId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Json$Response(params: {
    eventId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetAccountJoinEventEventIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Json(params: {
    eventId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithEventGetAccountJoinEventEventIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventCheckInvitationEventAccountIdEventIdGet
   */
  static readonly InlivewithEventCheckInvitationEventAccountIdEventIdGetPath = '/inlivewith/Event/CheckInvitationEvent/{accountId}/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventCheckInvitationEventAccountIdEventIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventCheckInvitationEventAccountIdEventIdGet$Plain$Response(params: {
    accountId: string;
    eventId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventCheckInvitationEventAccountIdEventIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventCheckInvitationEventAccountIdEventIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventCheckInvitationEventAccountIdEventIdGet$Plain(params: {
    accountId: string;
    eventId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithEventCheckInvitationEventAccountIdEventIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventCheckInvitationEventAccountIdEventIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventCheckInvitationEventAccountIdEventIdGet$Json$Response(params: {
    accountId: string;
    eventId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventCheckInvitationEventAccountIdEventIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventCheckInvitationEventAccountIdEventIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventCheckInvitationEventAccountIdEventIdGet$Json(params: {
    accountId: string;
    eventId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithEventCheckInvitationEventAccountIdEventIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventAddDescriptionEventIdPut
   */
  static readonly InlivewithEventAddDescriptionEventIdPutPath = '/inlivewith/Event/AddDescription/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventAddDescriptionEventIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventAddDescriptionEventIdPut$Plain$Response(params: {
    eventId: string;
    body?: string
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventAddDescriptionEventIdPutPath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventAddDescriptionEventIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventAddDescriptionEventIdPut$Plain(params: {
    eventId: string;
    body?: string
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventAddDescriptionEventIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventAddDescriptionEventIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventAddDescriptionEventIdPut$Json$Response(params: {
    eventId: string;
    body?: string
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventAddDescriptionEventIdPutPath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventAddDescriptionEventIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventAddDescriptionEventIdPut$Json(params: {
    eventId: string;
    body?: string
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventAddDescriptionEventIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventUploadPosterEventIdPut
   */
  static readonly InlivewithEventUploadPosterEventIdPutPath = '/inlivewith/Event/UploadPoster/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventUploadPosterEventIdPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithEventUploadPosterEventIdPut$Plain$Response(params: {
    eventId: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventUploadPosterEventIdPutPath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventUploadPosterEventIdPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithEventUploadPosterEventIdPut$Plain(params: {
    eventId: string;
    body?: {
'file'?: Blob;
}
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventUploadPosterEventIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventUploadPosterEventIdPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithEventUploadPosterEventIdPut$Json$Response(params: {
    eventId: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventUploadPosterEventIdPutPath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventUploadPosterEventIdPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithEventUploadPosterEventIdPut$Json(params: {
    eventId: string;
    body?: {
'file'?: Blob;
}
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventUploadPosterEventIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventUpdatePut
   */
  static readonly InlivewithEventUpdatePutPath = '/inlivewith/Event/Update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventUpdatePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUpdatePut$Plain$Response(params?: {
    body?: EventUpdateInputModel
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventUpdatePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventUpdatePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUpdatePut$Plain(params?: {
    body?: EventUpdateInputModel
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventUpdatePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventUpdatePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUpdatePut$Json$Response(params?: {
    body?: EventUpdateInputModel
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventUpdatePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventUpdatePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUpdatePut$Json(params?: {
    body?: EventUpdateInputModel
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventUpdatePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetEventLikeNameNameAccountRequestIdGet
   */
  static readonly InlivewithEventGetEventLikeNameNameAccountRequestIdGetPath = '/inlivewith/Event/GetEventLikeName/{name}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Plain$Response(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventLikeNameNameAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Plain(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelPaginationModelResponseModel>) => r.body as EventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Json$Response(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventLikeNameNameAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Json(params: {
    name: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventLikeNameNameAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelPaginationModelResponseModel>) => r.body as EventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet
   */
  static readonly InlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGetPath = '/inlivewith/Event/GetEventByTypeInClub/{type}/{clubId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet$Plain$Response(params: {
    type: string;
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet$Plain(params: {
    type: string;
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelPaginationModelResponseModel>) => r.body as EventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet$Json$Response(params: {
    type: string;
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet$Json(params: {
    type: string;
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventByTypeInClubTypeClubIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelPaginationModelResponseModel>) => r.body as EventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet
   */
  static readonly InlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGetPath = '/inlivewith/Event/GetEventPulbicLikeName/{accountRequestId}/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet$Plain$Response(params: {
    accountRequestId: string;
    name: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet$Plain(params: {
    accountRequestId: string;
    name: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet$Json$Response(params: {
    accountRequestId: string;
    name: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet$Json(params: {
    accountRequestId: string;
    name: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventPulbicLikeNameAccountRequestIdNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetByAuthorAccountRequestIdGet
   */
  static readonly InlivewithEventGetByAuthorAccountRequestIdGetPath = '/inlivewith/Event/GetByAuthor/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetByAuthorAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByAuthorAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetByAuthorAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetByAuthorAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByAuthorAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetByAuthorAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelPaginationModelResponseModel>) => r.body as EventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetByAuthorAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByAuthorAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetByAuthorAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetByAuthorAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByAuthorAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetByAuthorAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelPaginationModelResponseModel>) => r.body as EventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet
   */
  static readonly InlivewithEventGetEventThatAccountNotInClubAccountRequestIdGetPath = '/inlivewith/Event/GetEventThatAccountNotInClub/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventThatAccountNotInClubAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventThatAccountNotInClubAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventThatAccountNotInClubAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet
   */
  static readonly InlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGetPath = '/inlivewith/Event/GetByPeriod/{accountRequestId}/{startTime}/{endTime}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet$Plain$Response(params: {
    accountRequestId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('startTime', params.startTime, {});
      rb.path('endTime', params.endTime, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet$Plain(params: {
    accountRequestId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet$Json$Response(params: {
    accountRequestId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('startTime', params.startTime, {});
      rb.path('endTime', params.endTime, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet$Json(params: {
    accountRequestId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetByPeriodAccountRequestIdStartTimeEndTimeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetByCludIdAccountRequestIdClubIdGet
   */
  static readonly InlivewithEventGetByCludIdAccountRequestIdClubIdGetPath = '/inlivewith/Event/GetByCludId/{accountRequestId}/{ClubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Plain$Response(params: {
    accountRequestId: string;
    ClubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetByCludIdAccountRequestIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('ClubId', params.ClubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Plain(params: {
    accountRequestId: string;
    ClubId: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Json$Response(params: {
    accountRequestId: string;
    ClubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetByCludIdAccountRequestIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('ClubId', params.ClubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Json(params: {
    accountRequestId: string;
    ClubId: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetByCludIdAccountRequestIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet
   */
  static readonly InlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGetPath = '/inlivewith/Event/GetByClubPeriod/{accountRequestId}/{ClubId}/{startTime}/{endTime}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet$Plain$Response(params: {
    accountRequestId: string;
    ClubId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('ClubId', params.ClubId, {});
      rb.path('startTime', params.startTime, {});
      rb.path('endTime', params.endTime, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet$Plain(params: {
    accountRequestId: string;
    ClubId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet$Json$Response(params: {
    accountRequestId: string;
    ClubId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('ClubId', params.ClubId, {});
      rb.path('startTime', params.startTime, {});
      rb.path('endTime', params.endTime, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet$Json(params: {
    accountRequestId: string;
    ClubId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetByClubPeriodAccountRequestIdClubIdStartTimeEndTimeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventByIdAccountRequestIdEventIdGet
   */
  static readonly InlivewithEventByIdAccountRequestIdEventIdGetPath = '/inlivewith/Event/ById/{accountRequestId}/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventByIdAccountRequestIdEventIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventByIdAccountRequestIdEventIdGet$Plain$Response(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventByIdAccountRequestIdEventIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventByIdAccountRequestIdEventIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventByIdAccountRequestIdEventIdGet$Plain(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<EventWithCheckAccountJoinEventResponseModelResponseModel> {

    return this.inlivewithEventByIdAccountRequestIdEventIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventByIdAccountRequestIdEventIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventByIdAccountRequestIdEventIdGet$Json$Response(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventByIdAccountRequestIdEventIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventByIdAccountRequestIdEventIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventByIdAccountRequestIdEventIdGet$Json(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<EventWithCheckAccountJoinEventResponseModelResponseModel> {

    return this.inlivewithEventByIdAccountRequestIdEventIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetHiddenEventAccountRequestIdGet
   */
  static readonly InlivewithEventGetHiddenEventAccountRequestIdGetPath = '/inlivewith/Event/GetHiddenEvent/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetHiddenEventAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetHiddenEventAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetHiddenEventAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetHiddenEventAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetHiddenEventAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetHiddenEventAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelPaginationModelResponseModel>) => r.body as EventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetHiddenEventAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetHiddenEventAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetHiddenEventAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetHiddenEventAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetHiddenEventAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetHiddenEventAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelPaginationModelResponseModel>) => r.body as EventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventPageAccountRequestIdGet
   */
  static readonly InlivewithEventPageAccountRequestIdGetPath = '/inlivewith/Event/Page/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventPageAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventPageAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventPageAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventPageAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventPageAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventPageAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventPageAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventPageAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventPageAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventPageAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventPageAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventPageAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet
   */
  static readonly InlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGetPath = '/inlivewith/Event/GetEventByAccountId/{accountRequestId}/{startTime}/{endTime}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet$Plain$Response(params: {
    accountRequestId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('startTime', params.startTime, {});
      rb.path('endTime', params.endTime, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet$Plain(params: {
    accountRequestId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet$Json$Response(params: {
    accountRequestId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('startTime', params.startTime, {});
      rb.path('endTime', params.endTime, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet$Json(params: {
    accountRequestId: string;
    startTime: string;
    endTime: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventByAccountIdAccountRequestIdStartTimeEndTimeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventGetEventForHomePageAccountRequestIdGet
   */
  static readonly InlivewithEventGetEventForHomePageAccountRequestIdGetPath = '/inlivewith/Event/GetEventForHomePage/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventForHomePageAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventForHomePageAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventForHomePageAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventForHomePageAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventForHomePageAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventForHomePageAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventGetEventForHomePageAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventForHomePageAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventGetEventForHomePageAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventGetEventForHomePageAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventGetEventForHomePageAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel> {

    return this.inlivewithEventGetEventForHomePageAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel>) => r.body as EventWithCheckAccountJoinEventResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventDeleteEventIdAccountIdDelete
   */
  static readonly InlivewithEventDeleteEventIdAccountIdDeletePath = '/inlivewith/Event/Delete/{eventId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventDeleteEventIdAccountIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventDeleteEventIdAccountIdDelete$Plain$Response(params: {
    eventId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventDeleteEventIdAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventDeleteEventIdAccountIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventDeleteEventIdAccountIdDelete$Plain(params: {
    eventId: string;
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithEventDeleteEventIdAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventDeleteEventIdAccountIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventDeleteEventIdAccountIdDelete$Json$Response(params: {
    eventId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventDeleteEventIdAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventDeleteEventIdAccountIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventDeleteEventIdAccountIdDelete$Json(params: {
    eventId: string;
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithEventDeleteEventIdAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventHideEventAccountRequestIdEventIdPut
   */
  static readonly InlivewithEventHideEventAccountRequestIdEventIdPutPath = '/inlivewith/Event/HideEvent/{accountRequestId}/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventHideEventAccountRequestIdEventIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventHideEventAccountRequestIdEventIdPut$Plain$Response(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventHideEventAccountRequestIdEventIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventHideEventAccountRequestIdEventIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventHideEventAccountRequestIdEventIdPut$Plain(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventHideEventAccountRequestIdEventIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventHideEventAccountRequestIdEventIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventHideEventAccountRequestIdEventIdPut$Json$Response(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventHideEventAccountRequestIdEventIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventHideEventAccountRequestIdEventIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventHideEventAccountRequestIdEventIdPut$Json(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventHideEventAccountRequestIdEventIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventUnHideEventAccountRequestIdEventIdPut
   */
  static readonly InlivewithEventUnHideEventAccountRequestIdEventIdPutPath = '/inlivewith/Event/UnHideEvent/{accountRequestId}/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventUnHideEventAccountRequestIdEventIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventUnHideEventAccountRequestIdEventIdPut$Plain$Response(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventUnHideEventAccountRequestIdEventIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventUnHideEventAccountRequestIdEventIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventUnHideEventAccountRequestIdEventIdPut$Plain(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventUnHideEventAccountRequestIdEventIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventUnHideEventAccountRequestIdEventIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventUnHideEventAccountRequestIdEventIdPut$Json$Response(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<StrictHttpResponse<EventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventUnHideEventAccountRequestIdEventIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventUnHideEventAccountRequestIdEventIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithEventUnHideEventAccountRequestIdEventIdPut$Json(params: {
    accountRequestId: string;
    eventId: string;
  }): Observable<EventResponseModelResponseModel> {

    return this.inlivewithEventUnHideEventAccountRequestIdEventIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponseModelResponseModel>) => r.body as EventResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventJoinPut
   */
  static readonly InlivewithEventJoinPutPath = '/inlivewith/Event/Join';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventJoinPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventJoinPut$Plain$Response(params?: {
    body?: EventObjectJoinInputModel
  }): Observable<StrictHttpResponse<AccountEventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventJoinPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountEventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventJoinPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventJoinPut$Plain(params?: {
    body?: EventObjectJoinInputModel
  }): Observable<AccountEventResponseModelResponseModel> {

    return this.inlivewithEventJoinPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountEventResponseModelResponseModel>) => r.body as AccountEventResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventJoinPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventJoinPut$Json$Response(params?: {
    body?: EventObjectJoinInputModel
  }): Observable<StrictHttpResponse<AccountEventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventJoinPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountEventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventJoinPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventJoinPut$Json(params?: {
    body?: EventObjectJoinInputModel
  }): Observable<AccountEventResponseModelResponseModel> {

    return this.inlivewithEventJoinPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountEventResponseModelResponseModel>) => r.body as AccountEventResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventUnJoinPut
   */
  static readonly InlivewithEventUnJoinPutPath = '/inlivewith/Event/UnJoin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventUnJoinPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUnJoinPut$Plain$Response(params?: {
    body?: EventObjectJoinInputModel
  }): Observable<StrictHttpResponse<AccountEventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventUnJoinPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountEventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventUnJoinPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUnJoinPut$Plain(params?: {
    body?: EventObjectJoinInputModel
  }): Observable<AccountEventResponseModelResponseModel> {

    return this.inlivewithEventUnJoinPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountEventResponseModelResponseModel>) => r.body as AccountEventResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventUnJoinPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUnJoinPut$Json$Response(params?: {
    body?: EventObjectJoinInputModel
  }): Observable<StrictHttpResponse<AccountEventResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventUnJoinPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountEventResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventUnJoinPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventUnJoinPut$Json(params?: {
    body?: EventObjectJoinInputModel
  }): Observable<AccountEventResponseModelResponseModel> {

    return this.inlivewithEventUnJoinPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountEventResponseModelResponseModel>) => r.body as AccountEventResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithEventProcessPosterEventIdPut
   */
  static readonly InlivewithEventProcessPosterEventIdPutPath = '/inlivewith/Event/ProcessPoster/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventProcessPosterEventIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventProcessPosterEventIdPut$Plain$Response(params: {
    eventId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventProcessPosterEventIdPutPath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventProcessPosterEventIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventProcessPosterEventIdPut$Plain(params: {
    eventId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithEventProcessPosterEventIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithEventProcessPosterEventIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventProcessPosterEventIdPut$Json$Response(params: {
    eventId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.InlivewithEventProcessPosterEventIdPutPath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithEventProcessPosterEventIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithEventProcessPosterEventIdPut$Json(params: {
    eventId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithEventProcessPosterEventIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
