<div class="flex flex-column flex-start">
  <div class="zone-avatar position-relative">
    <img class="fit-size" [src]="album.coverImage!">
    <div class="stage-name">
      <div class="flex align-items-center">
        <img src="../../../assets/images/svgs/link-stage.svg" style="object-fit: fill;" class="mr-1">
        <span>{{album.stage?.name!}}</span>
      </div>
    </div>
    <div class="album-plus" *ngIf="album.isPrivate!">
      <img class="inliverplus" src="../../../../assets/images/svgs/inliver-plus.svg" alt="" srcset="">
    </div>
    <div class="menu">
      <i class="pi pi-ellipsis-h fs-16" (click)="openDetailAlbum()" style="color: #5884D9;"></i>
    </div>
  </div>
  <div class="zone-content">
    <span class="text-bold fs-16">
      {{album.name}}
    </span>
    <span class="fs-14">
      {{album.totalAsset!}} {{'MEDIAS' | translate}}
    </span>
    <span class="fs-12">
      {{'CREATED_AT' | translate}} {{album.dateCreated | date:'dd/MM/yyyy'}} {{'BY' | translate}} @levi
    </span>
  </div>
</div>
