/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountClubResponseModel } from '../models/account-club-response-model';
import { AccountResponseGeneralPaginationModelResponseModel } from '../models/account-response-general-pagination-model-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { GrantAccessLevelCelbrity } from '../models/grant-access-level-celbrity';
import { ReportModelAllWithDetailPaginationModelResponseModel } from '../models/report-model-all-with-detail-pagination-model-response-model';
import { ReportRequestForBackOffice } from '../models/report-request-for-back-office';
import { RevokeAccessLevelCelbrity } from '../models/revoke-access-level-celbrity';

@Injectable({
  providedIn: 'root',
})
export class BackOfficeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithBackOfficeGrantAccessLevelCelebrityPost
   */
  static readonly InlivewithBackOfficeGrantAccessLevelCelebrityPostPath = '/inlivewith/BackOffice/GrantAccessLevelCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGrantAccessLevelCelebrityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGrantAccessLevelCelebrityPost$Plain$Response(params?: {
    body?: GrantAccessLevelCelbrity
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGrantAccessLevelCelebrityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGrantAccessLevelCelebrityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGrantAccessLevelCelebrityPost$Plain(params?: {
    body?: GrantAccessLevelCelbrity
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithBackOfficeGrantAccessLevelCelebrityPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGrantAccessLevelCelebrityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGrantAccessLevelCelebrityPost$Json$Response(params?: {
    body?: GrantAccessLevelCelbrity
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGrantAccessLevelCelebrityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGrantAccessLevelCelebrityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGrantAccessLevelCelebrityPost$Json(params?: {
    body?: GrantAccessLevelCelbrity
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithBackOfficeGrantAccessLevelCelebrityPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeRevokeAccessLevelCelebrityDelete
   */
  static readonly InlivewithBackOfficeRevokeAccessLevelCelebrityDeletePath = '/inlivewith/BackOffice/RevokeAccessLevelCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Plain$Response(params?: {
    body?: RevokeAccessLevelCelbrity
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeRevokeAccessLevelCelebrityDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Plain(params?: {
    body?: RevokeAccessLevelCelbrity
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Json$Response(params?: {
    body?: RevokeAccessLevelCelbrity
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeRevokeAccessLevelCelebrityDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Json(params?: {
    body?: RevokeAccessLevelCelbrity
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetListCelebrityGet
   */
  static readonly InlivewithBackOfficeGetListCelebrityGetPath = '/inlivewith/BackOffice/GetListCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListCelebrityGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListCelebrityGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListCelebrityGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListCelebrityGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListCelebrityGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListCelebrityGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListCelebrityGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListCelebrityGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeUpgradeToCelebrityPut
   */
  static readonly InlivewithBackOfficeUpgradeToCelebrityPutPath = '/inlivewith/BackOffice/UpgradeToCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeUpgradeToCelebrityPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUpgradeToCelebrityPut$Plain$Response(params?: {
    accountId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeUpgradeToCelebrityPutPath, 'put');
    if (params) {
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeUpgradeToCelebrityPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUpgradeToCelebrityPut$Plain(params?: {
    accountId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeUpgradeToCelebrityPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeUpgradeToCelebrityPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUpgradeToCelebrityPut$Json$Response(params?: {
    accountId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeUpgradeToCelebrityPutPath, 'put');
    if (params) {
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeUpgradeToCelebrityPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUpgradeToCelebrityPut$Json(params?: {
    accountId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeUpgradeToCelebrityPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost
   */
  static readonly InlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPostPath = '/inlivewith/BackOffice/GetAllReportByAccountId/GetAllReportByAccountId/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByAccountIdGetAllReportByAccountIdAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost
   */
  static readonly InlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePostPath = '/inlivewith/BackOffice/GetAllReportByType/GetAllReportByType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost$Plain$Response(params?: {
    page?: number;
    limit?: number;
    body?: ReportRequestForBackOffice
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost$Plain(params?: {
    page?: number;
    limit?: number;
    body?: ReportRequestForBackOffice
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost$Json$Response(params?: {
    page?: number;
    limit?: number;
    body?: ReportRequestForBackOffice
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost$Json(params?: {
    page?: number;
    limit?: number;
    body?: ReportRequestForBackOffice
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByTypeGetAllReportByTypePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost
   */
  static readonly InlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPostPath = '/inlivewith/BackOffice/GetAllReportByClubId/GetAllReportByClubId/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    accountId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPostPath, 'post');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    accountId: string;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    accountId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPostPath, 'post');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    accountId: string;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByClubIdGetAllReportByClubIdAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

}
