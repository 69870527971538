/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountAddressUpdateInputModel } from '../models/account-address-update-input-model';
import { AccountBirthdayUpdateInputModel } from '../models/account-birthday-update-input-model';
import { AccountClubAccessLevelRequestInputModel } from '../models/account-club-access-level-request-input-model';
import { AccountClubCheckingResponseModelResponseModel } from '../models/account-club-checking-response-model-response-model';
import { AccountClubInputModel } from '../models/account-club-input-model';
import { AccountClubResponseModel } from '../models/account-club-response-model';
import { AccountGuidInputModel } from '../models/account-guid-input-model';
import { AccountHashtagListResponseModel } from '../models/account-hashtag-list-response-model';
import { AccountInputModel } from '../models/account-input-model';
import { AccountObjectCreateInputModel } from '../models/account-object-create-input-model';
import { AccountObjectUpdateInputModel } from '../models/account-object-update-input-model';
import { AccountResponseGeneralPaginationModelResponseModel } from '../models/account-response-general-pagination-model-response-model';
import { AccountResponseGeneralResponseModel } from '../models/account-response-general-response-model';
import { AccountResponseWithRoleInCLubModelResponseModel } from '../models/account-response-with-role-in-c-lub-model-response-model';
import { AccountSettingNotificationInputModel } from '../models/account-setting-notification-input-model';
import { AccountSettingNotificationResponseModelResponseModel } from '../models/account-setting-notification-response-model-response-model';
import { AccountStringUpdateInputModel } from '../models/account-string-update-input-model';
import { AccountWithCheckBlockFriendResponseModelResponseModel } from '../models/account-with-check-block-friend-response-model-response-model';
import { AccountWithCheckFriendShipResponseModelPaginationModelResponseModel } from '../models/account-with-check-friend-ship-response-model-pagination-model-response-model';
import { ApprovalInvitaionJoinClub } from '../models/approval-invitaion-join-club';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { ClubResponseWithTotalMemberModelPaginationModelResponseModel } from '../models/club-response-with-total-member-model-pagination-model-response-model';
import { DateTimeNullableResponseModel } from '../models/date-time-nullable-response-model';
import { GendersEnumResponseModel } from '../models/genders-enum-response-model';
import { HashtagPaginationModelResponseModel } from '../models/hashtag-pagination-model-response-model';
import { ProcessFileModel } from '../models/process-file-model';
import { ResultSearchAccountViewModelResponseModel } from '../models/result-search-account-view-model-response-model';
import { RolesEnumResponseModel } from '../models/roles-enum-response-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithAccountsPageGet
   */
  static readonly InlivewithAccountsPageGetPath = '/inlivewith/Accounts/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountByIdAccountIdGet
   */
  static readonly InlivewithAccountByIdAccountIdGetPath = '/inlivewith/Account/ById/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdAccountIdGet$Plain$Response(params: {
    accountId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdAccountIdGet$Plain(params: {
    accountId: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountByIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdAccountIdGet$Json$Response(params: {
    accountId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdAccountIdGet$Json(params: {
    accountId: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountByIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountGetAccountIdentityByEmailEmailGet
   */
  static readonly InlivewithAccountGetAccountIdentityByEmailEmailGetPath = '/inlivewith/Account/GetAccountIdentityByEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetAccountIdentityByEmailEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetAccountIdentityByEmailEmailGet$Plain$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetAccountIdentityByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetAccountIdentityByEmailEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetAccountIdentityByEmailEmailGet$Plain(params: {
    email: string;
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.inlivewithAccountGetAccountIdentityByEmailEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetAccountIdentityByEmailEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetAccountIdentityByEmailEmailGet$Json$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetAccountIdentityByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetAccountIdentityByEmailEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetAccountIdentityByEmailEmailGet$Json(params: {
    email: string;
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.inlivewithAccountGetAccountIdentityByEmailEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet
   */
  static readonly InlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGetPath = '/inlivewith/Accounts/GetProfileIsBlock/{accountId}/{accountRequestId}';

  /**
   * AccountRequestId: The Account that being use on the app, AccountId: The profile of blocked account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet$Plain$Response(params: {
    accountId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<AccountWithCheckBlockFriendResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithCheckBlockFriendResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRequestId: The Account that being use on the app, AccountId: The profile of blocked account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet$Plain(params: {
    accountId: string;
    accountRequestId: string;
  }): Observable<AccountWithCheckBlockFriendResponseModelResponseModel> {

    return this.inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithCheckBlockFriendResponseModelResponseModel>) => r.body as AccountWithCheckBlockFriendResponseModelResponseModel)
    );
  }

  /**
   * AccountRequestId: The Account that being use on the app, AccountId: The profile of blocked account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet$Json$Response(params: {
    accountId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<AccountWithCheckBlockFriendResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithCheckBlockFriendResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRequestId: The Account that being use on the app, AccountId: The profile of blocked account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet$Json(params: {
    accountId: string;
    accountRequestId: string;
  }): Observable<AccountWithCheckBlockFriendResponseModelResponseModel> {

    return this.inlivewithAccountsGetProfileIsBlockAccountIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithCheckBlockFriendResponseModelResponseModel>) => r.body as AccountWithCheckBlockFriendResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet
   */
  static readonly InlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGetPath = '/inlivewith/Account/ByIdWithAccountRequestId/{accountId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet$Plain$Response(params: {
    accountId: string;
    accountRequestId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet$Plain(params: {
    accountId: string;
    accountRequestId: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet$Json$Response(params: {
    accountId: string;
    accountRequestId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet$Json(params: {
    accountId: string;
    accountRequestId: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountByIdWithAccountRequestIdAccountIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountByIdentityUserIdIdentityUserIdGet
   */
  static readonly InlivewithAccountByIdentityUserIdIdentityUserIdGetPath = '/inlivewith/Account/ByIdentityUserId/{identityUserId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByIdentityUserIdIdentityUserIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdentityUserIdIdentityUserIdGet$Plain$Response(params: {
    identityUserId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByIdentityUserIdIdentityUserIdGetPath, 'get');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByIdentityUserIdIdentityUserIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdentityUserIdIdentityUserIdGet$Plain(params: {
    identityUserId: string;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelResponseModel> {

    return this.inlivewithAccountByIdentityUserIdIdentityUserIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByIdentityUserIdIdentityUserIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdentityUserIdIdentityUserIdGet$Json$Response(params: {
    identityUserId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByIdentityUserIdIdentityUserIdGetPath, 'get');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByIdentityUserIdIdentityUserIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByIdentityUserIdIdentityUserIdGet$Json(params: {
    identityUserId: string;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelResponseModel> {

    return this.inlivewithAccountByIdentityUserIdIdentityUserIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountCreatePost
   */
  static readonly InlivewithAccountCreatePostPath = '/inlivewith/Account/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountCreatePost$Plain$Response(params?: {
    body?: AccountObjectCreateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountCreatePost$Plain(params?: {
    body?: AccountObjectCreateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountCreatePost$Json$Response(params?: {
    body?: AccountObjectCreateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountCreatePost$Json(params?: {
    body?: AccountObjectCreateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountUpdateAccountIdPut
   */
  static readonly InlivewithAccountUpdateAccountIdPutPath = '/inlivewith/Account/Update/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountUpdateAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountUpdateAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsLikeNicknameNicknameGet
   */
  static readonly InlivewithAccountsLikeNicknameNicknameGetPath = '/inlivewith/Accounts/LikeNickname/{nickname}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsLikeNicknameNicknameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeNicknameNicknameGet$Plain$Response(params: {
    nickname: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsLikeNicknameNicknameGetPath, 'get');
    if (params) {
      rb.path('nickname', params.nickname, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsLikeNicknameNicknameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeNicknameNicknameGet$Plain(params: {
    nickname: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsLikeNicknameNicknameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsLikeNicknameNicknameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeNicknameNicknameGet$Json$Response(params: {
    nickname: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsLikeNicknameNicknameGetPath, 'get');
    if (params) {
      rb.path('nickname', params.nickname, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsLikeNicknameNicknameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeNicknameNicknameGet$Json(params: {
    nickname: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsLikeNicknameNicknameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountByNicknameNicknameGet
   */
  static readonly InlivewithAccountByNicknameNicknameGetPath = '/inlivewith/Account/ByNickname/{nickname}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByNicknameNicknameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByNicknameNicknameGet$Plain$Response(params: {
    nickname: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByNicknameNicknameGetPath, 'get');
    if (params) {
      rb.path('nickname', params.nickname, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByNicknameNicknameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByNicknameNicknameGet$Plain(params: {
    nickname: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountByNicknameNicknameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByNicknameNicknameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByNicknameNicknameGet$Json$Response(params: {
    nickname: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByNicknameNicknameGetPath, 'get');
    if (params) {
      rb.path('nickname', params.nickname, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByNicknameNicknameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByNicknameNicknameGet$Json(params: {
    nickname: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountByNicknameNicknameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountNicknameAccountIdGet
   */
  static readonly InlivewithAccountNicknameAccountIdGetPath = '/inlivewith/Account/Nickname/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountNicknameAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountNicknameAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountNicknameAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountNicknameAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountNicknameAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountNicknameAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountNicknameAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountNicknameAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountNicknameAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountNicknameAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountNicknameAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountNicknameAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountNicknameAccountIdPut
   */
  static readonly InlivewithAccountNicknameAccountIdPutPath = '/inlivewith/Account/Nickname/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountNicknameAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountNicknameAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountNicknameAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountNicknameAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountNicknameAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountNicknameAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountNicknameAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountNicknameAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountNicknameAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountNicknameAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountNicknameAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountNicknameAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet
   */
  static readonly InlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGetPath = '/inlivewith/Account/SearchInliverLikeNicknameOnDecouvrir/{accountRequestId}/{nickname}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Plain$Response(params: {
    accountRequestId: string;
    nickname: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountWithCheckFriendShipResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('nickname', params.nickname, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithCheckFriendShipResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Plain(params: {
    accountRequestId: string;
    nickname: string;
    page?: number;
    limit?: number;
  }): Observable<AccountWithCheckFriendShipResponseModelPaginationModelResponseModel> {

    return this.inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithCheckFriendShipResponseModelPaginationModelResponseModel>) => r.body as AccountWithCheckFriendShipResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Json$Response(params: {
    accountRequestId: string;
    nickname: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountWithCheckFriendShipResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('nickname', params.nickname, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithCheckFriendShipResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Json(params: {
    accountRequestId: string;
    nickname: string;
    page?: number;
    limit?: number;
  }): Observable<AccountWithCheckFriendShipResponseModelPaginationModelResponseModel> {

    return this.inlivewithAccountSearchInliverLikeNicknameOnDecouvrirAccountRequestIdNicknameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithCheckFriendShipResponseModelPaginationModelResponseModel>) => r.body as AccountWithCheckFriendShipResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsLikeEmailEmailGet
   */
  static readonly InlivewithAccountsLikeEmailEmailGetPath = '/inlivewith/Accounts/LikeEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsLikeEmailEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeEmailEmailGet$Plain$Response(params: {
    email: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsLikeEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsLikeEmailEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeEmailEmailGet$Plain(params: {
    email: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsLikeEmailEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsLikeEmailEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeEmailEmailGet$Json$Response(params: {
    email: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsLikeEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsLikeEmailEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeEmailEmailGet$Json(params: {
    email: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsLikeEmailEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountByEmailEmailGet
   */
  static readonly InlivewithAccountByEmailEmailGetPath = '/inlivewith/Account/ByEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByEmailEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Plain$Response(params: {
    email: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByEmailEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Plain(params: {
    email: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountByEmailEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByEmailEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Json$Response(params: {
    email: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByEmailEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Json(params: {
    email: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountByEmailEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountByEmailsPost
   */
  static readonly InlivewithAccountByEmailsPostPath = '/inlivewith/Account/ByEmails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByEmailsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountByEmailsPost$Plain$Response(params?: {
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByEmailsPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByEmailsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountByEmailsPost$Plain(params?: {
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountByEmailsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByEmailsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountByEmailsPost$Json$Response(params?: {
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByEmailsPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByEmailsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountByEmailsPost$Json(params?: {
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountByEmailsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountEmailAccountIdGet
   */
  static readonly InlivewithAccountEmailAccountIdGetPath = '/inlivewith/Account/Email/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountEmailAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountEmailAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountEmailAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountEmailAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountEmailAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountEmailAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountEmailAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountEmailAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountEmailAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountEmailAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountEmailAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountEmailAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsGenderAllGet
   */
  static readonly InlivewithAccountsGenderAllGetPath = '/inlivewith/Accounts/Gender/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsGenderAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderAllGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsGenderAllGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsGenderAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderAllGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsGenderAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsGenderAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderAllGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsGenderAllGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsGenderAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderAllGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsGenderAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsGenderMaleGet
   */
  static readonly InlivewithAccountsGenderMaleGetPath = '/inlivewith/Accounts/Gender/Male';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsGenderMaleGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderMaleGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsGenderMaleGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsGenderMaleGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderMaleGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsGenderMaleGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsGenderMaleGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderMaleGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsGenderMaleGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsGenderMaleGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderMaleGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsGenderMaleGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsGenderFemaleGet
   */
  static readonly InlivewithAccountsGenderFemaleGetPath = '/inlivewith/Accounts/Gender/Female';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsGenderFemaleGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderFemaleGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsGenderFemaleGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsGenderFemaleGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderFemaleGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsGenderFemaleGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsGenderFemaleGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderFemaleGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsGenderFemaleGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsGenderFemaleGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsGenderFemaleGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsGenderFemaleGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountGenderAccountIdGet
   */
  static readonly InlivewithAccountGenderAccountIdGetPath = '/inlivewith/Account/Gender/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGenderAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGenderAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGenderAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGenderAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGenderAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAccountGenderAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGenderAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGenderAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGenderAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGenderAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGenderAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAccountGenderAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountGenderAccountIdPut
   */
  static readonly InlivewithAccountGenderAccountIdPutPath = '/inlivewith/Account/Gender/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGenderAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGenderAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGenderAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGenderAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGenderAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountGenderAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGenderAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGenderAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGenderAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGenderAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGenderAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountStringUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountGenderAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountBirthdayAccountIdGet
   */
  static readonly InlivewithAccountBirthdayAccountIdGetPath = '/inlivewith/Account/Birthday/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountBirthdayAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountBirthdayAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<DateTimeNullableResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountBirthdayAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeNullableResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountBirthdayAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountBirthdayAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<DateTimeNullableResponseModel> {

    return this.inlivewithAccountBirthdayAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeNullableResponseModel>) => r.body as DateTimeNullableResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountBirthdayAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountBirthdayAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<DateTimeNullableResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountBirthdayAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeNullableResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountBirthdayAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountBirthdayAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<DateTimeNullableResponseModel> {

    return this.inlivewithAccountBirthdayAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeNullableResponseModel>) => r.body as DateTimeNullableResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountBirthdayAccountIdPut
   */
  static readonly InlivewithAccountBirthdayAccountIdPutPath = '/inlivewith/Account/Birthday/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountBirthdayAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountBirthdayAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountBirthdayAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountBirthdayAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountBirthdayAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountBirthdayAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountBirthdayAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountBirthdayAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountAddressAccountIdPut
   */
  static readonly InlivewithAccountAddressAccountIdPutPath = '/inlivewith/Account/Address/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAddressAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAddressAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountAddressUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAddressAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAddressAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAddressAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountAddressUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountAddressAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAddressAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAddressAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountAddressUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAddressAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAddressAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAddressAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountAddressUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountAddressAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountIsOnlineAccountIdIsOnlinePost
   */
  static readonly InlivewithAccountIsOnlineAccountIdIsOnlinePostPath = '/inlivewith/Account/IsOnline/{accountId}/{isOnline}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsOnlineAccountIdIsOnlinePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsOnlineAccountIdIsOnlinePost$Plain$Response(params: {
    accountId: string;
    isOnline: boolean;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsOnlineAccountIdIsOnlinePostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('isOnline', params.isOnline, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsOnlineAccountIdIsOnlinePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsOnlineAccountIdIsOnlinePost$Plain(params: {
    accountId: string;
    isOnline: boolean;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsOnlineAccountIdIsOnlinePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsOnlineAccountIdIsOnlinePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsOnlineAccountIdIsOnlinePost$Json$Response(params: {
    accountId: string;
    isOnline: boolean;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsOnlineAccountIdIsOnlinePostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('isOnline', params.isOnline, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsOnlineAccountIdIsOnlinePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsOnlineAccountIdIsOnlinePost$Json(params: {
    accountId: string;
    isOnline: boolean;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsOnlineAccountIdIsOnlinePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountAvatarAccountIdGet
   */
  static readonly InlivewithAccountAvatarAccountIdGetPath = '/inlivewith/Account/Avatar/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountAvatarAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountAvatarAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountAvatarAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountAvatarAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountAvatarAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountAvatarAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountAvatarAccountIdPost
   */
  static readonly InlivewithAccountAvatarAccountIdPostPath = '/inlivewith/Account/Avatar/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarAccountIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarAccountIdPost$Plain$Response(params: {
    accountId: string;
    body?: {
'AccountId'?: string;
'Files'?: Blob;
}
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarAccountIdPost$Plain(params: {
    accountId: string;
    body?: {
'AccountId'?: string;
'Files'?: Blob;
}
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountAvatarAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarAccountIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarAccountIdPost$Json$Response(params: {
    accountId: string;
    body?: {
'AccountId'?: string;
'Files'?: Blob;
}
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarAccountIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarAccountIdPost$Json(params: {
    accountId: string;
    body?: {
'AccountId'?: string;
'Files'?: Blob;
}
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountAvatarAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountAvatarAccountIdDelete
   */
  static readonly InlivewithAccountAvatarAccountIdDeletePath = '/inlivewith/Account/Avatar/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarAccountIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAvatarAccountIdDelete$Plain$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarAccountIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAvatarAccountIdDelete$Plain(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountAvatarAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarAccountIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAvatarAccountIdDelete$Json$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarAccountIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountAvatarAccountIdDelete$Json(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountAvatarAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountAvatarPut
   */
  static readonly InlivewithAccountAvatarPutPath = '/inlivewith/Account/Avatar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarPut$Plain$Response(params?: {
    body?: {
'AccountId'?: string;
'Files'?: Blob;
}
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarPut$Plain(params?: {
    body?: {
'AccountId'?: string;
'Files'?: Blob;
}
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountAvatarPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountAvatarPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarPut$Json$Response(params?: {
    body?: {
'AccountId'?: string;
'Files'?: Blob;
}
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountAvatarPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountAvatarPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithAccountAvatarPut$Json(params?: {
    body?: {
'AccountId'?: string;
'Files'?: Blob;
}
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountAvatarPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsPublicGet
   */
  static readonly InlivewithAccountsPublicGetPath = '/inlivewith/Accounts/Public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPublicGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPublicGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPublicGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPublicGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPublicGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPublicGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsPrivateGet
   */
  static readonly InlivewithAccountsPrivateGetPath = '/inlivewith/Accounts/Private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPrivateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPrivateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPrivateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPrivateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPrivateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPrivateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountIsPublicAccountIdGet
   */
  static readonly InlivewithAccountIsPublicAccountIdGetPath = '/inlivewith/Account/IsPublic/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsPublicAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPublicAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsPublicAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsPublicAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPublicAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsPublicAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsPublicAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPublicAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsPublicAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsPublicAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPublicAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsPublicAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountIsPrivateAccountIdGet
   */
  static readonly InlivewithAccountIsPrivateAccountIdGetPath = '/inlivewith/Account/IsPrivate/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsPrivateAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPrivateAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsPrivateAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsPrivateAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPrivateAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsPrivateAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsPrivateAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPrivateAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsPrivateAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsPrivateAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsPrivateAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsPrivateAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountPublicAccountIdPut
   */
  static readonly InlivewithAccountPublicAccountIdPutPath = '/inlivewith/Account/Public/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPublicAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPublicAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPublicAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountPublicAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPublicAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPublicAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPublicAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountPublicAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountPrivateAccountIdPut
   */
  static readonly InlivewithAccountPrivateAccountIdPutPath = '/inlivewith/Account/Private/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPrivateAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPrivateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPrivateAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountPrivateAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPrivateAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPrivateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPrivateAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountPrivateAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountHashtagsAccountIdGet
   */
  static readonly InlivewithAccountHashtagsAccountIdGetPath = '/inlivewith/Account/Hashtags/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountHashtagsAccountIdPut
   */
  static readonly InlivewithAccountHashtagsAccountIdPutPath = '/inlivewith/Account/Hashtags/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountHashtagsAccountIdPost
   */
  static readonly InlivewithAccountHashtagsAccountIdPostPath = '/inlivewith/Account/Hashtags/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPost$Plain$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPost$Plain(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPost$Json$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPost$Json(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountHashtagsAccountIdDelete
   */
  static readonly InlivewithAccountHashtagsAccountIdDeletePath = '/inlivewith/Account/Hashtags/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdDelete$Plain$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdDelete$Plain(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdDelete$Json$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdDelete$Json(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubsAccountIdGet
   */
  static readonly InlivewithAccountClubsAccountIdGetPath = '/inlivewith/Account/Clubs/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubsAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubsAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubsAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubsAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubsAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithAccountClubsAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubsAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubsAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubsAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubsAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubsAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithAccountClubsAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccountIdPost
   */
  static readonly InlivewithAccountClubAccountIdPostPath = '/inlivewith/Account/Club/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccountIdPost$Plain$Response(params: {
    accountId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccountIdPost$Plain(params: {
    accountId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccountIdPost$Json$Response(params: {
    accountId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccountIdPost$Json(params: {
    accountId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccountIdDelete
   */
  static readonly InlivewithAccountClubAccountIdDeletePath = '/inlivewith/Account/Club/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccountIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccountIdDelete$Plain$Response(params: {
    accountId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccountIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccountIdDelete$Plain(params: {
    accountId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccountIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccountIdDelete$Json$Response(params: {
    accountId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccountIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccountIdDelete$Json(params: {
    accountId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRequestAccessLevelPost
   */
  static readonly InlivewithAccountClubRequestAccessLevelPostPath = '/inlivewith/Account/Club/RequestAccessLevel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRequestAccessLevelPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRequestAccessLevelPost$Plain$Response(params?: {
    body?: AccountClubAccessLevelRequestInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRequestAccessLevelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRequestAccessLevelPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRequestAccessLevelPost$Plain(params?: {
    body?: AccountClubAccessLevelRequestInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubRequestAccessLevelPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRequestAccessLevelPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRequestAccessLevelPost$Json$Response(params?: {
    body?: AccountClubAccessLevelRequestInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRequestAccessLevelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRequestAccessLevelPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRequestAccessLevelPost$Json(params?: {
    body?: AccountClubAccessLevelRequestInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubRequestAccessLevelPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubCancelRequestAccessLevelPost
   */
  static readonly InlivewithAccountClubCancelRequestAccessLevelPostPath = '/inlivewith/Account/Club/CancelRequestAccessLevel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubCancelRequestAccessLevelPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubCancelRequestAccessLevelPost$Plain$Response(params?: {
    body?: AccountClubAccessLevelRequestInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubCancelRequestAccessLevelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubCancelRequestAccessLevelPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubCancelRequestAccessLevelPost$Plain(params?: {
    body?: AccountClubAccessLevelRequestInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubCancelRequestAccessLevelPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubCancelRequestAccessLevelPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubCancelRequestAccessLevelPost$Json$Response(params?: {
    body?: AccountClubAccessLevelRequestInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubCancelRequestAccessLevelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubCancelRequestAccessLevelPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubCancelRequestAccessLevelPost$Json(params?: {
    body?: AccountClubAccessLevelRequestInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubCancelRequestAccessLevelPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGetPath = '/inlivewith/Account/Club/CheckRequestAccessLevel/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubCheckRequestAccessLevelAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRequestPost
   */
  static readonly InlivewithAccountClubRequestPostPath = '/inlivewith/Account/Club/Request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRequestPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRequestPost$Plain$Response(params?: {
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRequestPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRequestPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRequestPost$Plain(params?: {
    body?: AccountClubInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubRequestPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRequestPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRequestPost$Json$Response(params?: {
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRequestPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRequestPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRequestPost$Json(params?: {
    body?: AccountClubInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubRequestPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubCancelRequestPut
   */
  static readonly InlivewithAccountClubCancelRequestPutPath = '/inlivewith/Account/Club/CancelRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubCancelRequestPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubCancelRequestPut$Plain$Response(params?: {
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubCancelRequestPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubCancelRequestPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubCancelRequestPut$Plain(params?: {
    body?: AccountClubInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubCancelRequestPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubCancelRequestPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubCancelRequestPut$Json$Response(params?: {
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubCancelRequestPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubCancelRequestPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubCancelRequestPut$Json(params?: {
    body?: AccountClubInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubCancelRequestPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubCheckRequestAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubCheckRequestAccountIdClubIdGetPath = '/inlivewith/Account/Club/CheckRequest/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubCheckRequestAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubCheckRequestAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubCheckRequestAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubCheckRequestAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubCheckRequestAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubCheckRequestAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubCheckRequestAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubCheckRequestAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubCheckRequestAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubCheckRequestAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubCheckRequestAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubCheckRequestAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubDetailAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubDetailAccountIdClubIdGetPath = '/inlivewith/Account/Club/Detail/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubDetailAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubDetailAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubDetailAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubDetailAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubDetailAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<AccountResponseWithRoleInCLubModelResponseModel> {

    return this.inlivewithAccountClubDetailAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubDetailAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubDetailAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubDetailAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubDetailAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubDetailAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<AccountResponseWithRoleInCLubModelResponseModel> {

    return this.inlivewithAccountClubDetailAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubRoleIsAnonymousAccountIdClubIdGetPath = '/inlivewith/Account/Club/Role/IsAnonymous/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleIsAnonymousAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleIsAnonymousAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubRoleIsAnonymousAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubRoleIsAdministratorAccountIdClubIdGetPath = '/inlivewith/Account/Club/Role/IsAdministrator/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleIsAdministratorAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleIsAdministratorAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubRoleIsAdministratorAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRoleAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubRoleAccountIdClubIdGetPath = '/inlivewith/Account/Club/Role/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<RolesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RolesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<RolesEnumResponseModel> {

    return this.inlivewithAccountClubRoleAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RolesEnumResponseModel>) => r.body as RolesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<RolesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RolesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubRoleAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<RolesEnumResponseModel> {

    return this.inlivewithAccountClubRoleAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RolesEnumResponseModel>) => r.body as RolesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRoleAnonymousAccountIdClubIdPut
   */
  static readonly InlivewithAccountClubRoleAnonymousAccountIdClubIdPutPath = '/inlivewith/Account/Club/Role/Anonymous/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAnonymousAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAnonymousAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubRoleAnonymousAccountIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubRoleAdministratorAccountIdClubIdPut
   */
  static readonly InlivewithAccountClubRoleAdministratorAccountIdClubIdPutPath = '/inlivewith/Account/Club/Role/Administrator/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAdministratorAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubRoleAdministratorAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubRoleAdministratorAccountIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountApprovalInvitaionJoinClubPost
   */
  static readonly InlivewithAccountApprovalInvitaionJoinClubPostPath = '/inlivewith/Account/ApprovalInvitaionJoinClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountApprovalInvitaionJoinClubPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountApprovalInvitaionJoinClubPost$Plain$Response(params?: {
    body?: ApprovalInvitaionJoinClub
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountApprovalInvitaionJoinClubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountApprovalInvitaionJoinClubPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountApprovalInvitaionJoinClubPost$Plain(params?: {
    body?: ApprovalInvitaionJoinClub
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountApprovalInvitaionJoinClubPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountApprovalInvitaionJoinClubPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountApprovalInvitaionJoinClubPost$Json$Response(params?: {
    body?: ApprovalInvitaionJoinClub
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountApprovalInvitaionJoinClubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountApprovalInvitaionJoinClubPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountApprovalInvitaionJoinClubPost$Json(params?: {
    body?: ApprovalInvitaionJoinClub
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountApprovalInvitaionJoinClubPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGetPath = '/inlivewith/Account/Club/AccessLevel/IsAnonymous/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubAccessLevelIsAnonymousAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGetPath = '/inlivewith/Account/Club/AccessLevel/IsPlus/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountClubAccessLevelIsPlusAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubHasAccessAccountRequestIdClubIdGet
   */
  static readonly InlivewithAccountClubHasAccessAccountRequestIdClubIdGetPath = '/inlivewith/Account/Club/HasAccess/{accountRequestId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Plain$Response(params: {
    accountRequestId: string;
    clubId: string;
    postId?: string;
  }): Observable<StrictHttpResponse<AccountClubCheckingResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubHasAccessAccountRequestIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubCheckingResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Plain(params: {
    accountRequestId: string;
    clubId: string;
    postId?: string;
  }): Observable<AccountClubCheckingResponseModelResponseModel> {

    return this.inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubCheckingResponseModelResponseModel>) => r.body as AccountClubCheckingResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Json$Response(params: {
    accountRequestId: string;
    clubId: string;
    postId?: string;
  }): Observable<StrictHttpResponse<AccountClubCheckingResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubHasAccessAccountRequestIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubCheckingResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Json(params: {
    accountRequestId: string;
    clubId: string;
    postId?: string;
  }): Observable<AccountClubCheckingResponseModelResponseModel> {

    return this.inlivewithAccountClubHasAccessAccountRequestIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubCheckingResponseModelResponseModel>) => r.body as AccountClubCheckingResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut
   */
  static readonly InlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPutPath = '/inlivewith/Account/Club/AccessLevel/Anonymous/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccessLevelAnonymousAccountIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut
   */
  static readonly InlivewithAccountClubAccessLevelPlusAccountIdClubIdPutPath = '/inlivewith/Account/Club/AccessLevel/Plus/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelPlusAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelPlusAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccessLevelPlusAccountIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut
   */
  static readonly InlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPutPath = '/inlivewith/Account/Club/AccessLevel/Celebrity/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut$Plain$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut$Plain(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut$Json$Response(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<StrictHttpResponse<AccountClubResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut$Json(params: {
    accountId: string;
    clubId: string;
    body?: AccountClubInputModel
  }): Observable<AccountClubResponseModel> {

    return this.inlivewithAccountClubAccessLevelCelebrityAccountIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubResponseModel>) => r.body as AccountClubResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountSetEnableConversationAccountIdPut
   */
  static readonly InlivewithAccountSetEnableConversationAccountIdPutPath = '/inlivewith/Account/SetEnableConversation/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountSetEnableConversationAccountIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountSetEnableConversationAccountIdPut$Plain$Response(params: {
    accountId: string;
    isEnableConversation?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountSetEnableConversationAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('isEnableConversation', params.isEnableConversation, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountSetEnableConversationAccountIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountSetEnableConversationAccountIdPut$Plain(params: {
    accountId: string;
    isEnableConversation?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountSetEnableConversationAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountSetEnableConversationAccountIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountSetEnableConversationAccountIdPut$Json$Response(params: {
    accountId: string;
    isEnableConversation?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountSetEnableConversationAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('isEnableConversation', params.isEnableConversation, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountSetEnableConversationAccountIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountSetEnableConversationAccountIdPut$Json(params: {
    accountId: string;
    isEnableConversation?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountSetEnableConversationAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountGetSettingAccountNotificationAccountIdGet
   */
  static readonly InlivewithAccountGetSettingAccountNotificationAccountIdGetPath = '/inlivewith/Account/GetSettingAccountNotification/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetSettingAccountNotificationAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetSettingAccountNotificationAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetSettingAccountNotificationAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetSettingAccountNotificationAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetSettingAccountNotificationAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<AccountSettingNotificationResponseModelResponseModel> {

    return this.inlivewithAccountGetSettingAccountNotificationAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>) => r.body as AccountSettingNotificationResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetSettingAccountNotificationAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetSettingAccountNotificationAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetSettingAccountNotificationAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetSettingAccountNotificationAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetSettingAccountNotificationAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<AccountSettingNotificationResponseModelResponseModel> {

    return this.inlivewithAccountGetSettingAccountNotificationAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>) => r.body as AccountSettingNotificationResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountSettingAccountNotificationPost
   */
  static readonly InlivewithAccountSettingAccountNotificationPostPath = '/inlivewith/Account/SettingAccountNotification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountSettingAccountNotificationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountSettingAccountNotificationPost$Plain$Response(params?: {
    body?: AccountSettingNotificationInputModel
  }): Observable<StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountSettingAccountNotificationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountSettingAccountNotificationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountSettingAccountNotificationPost$Plain(params?: {
    body?: AccountSettingNotificationInputModel
  }): Observable<AccountSettingNotificationResponseModelResponseModel> {

    return this.inlivewithAccountSettingAccountNotificationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>) => r.body as AccountSettingNotificationResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountSettingAccountNotificationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountSettingAccountNotificationPost$Json$Response(params?: {
    body?: AccountSettingNotificationInputModel
  }): Observable<StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountSettingAccountNotificationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountSettingAccountNotificationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountSettingAccountNotificationPost$Json(params?: {
    body?: AccountSettingNotificationInputModel
  }): Observable<AccountSettingNotificationResponseModelResponseModel> {

    return this.inlivewithAccountSettingAccountNotificationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountSettingNotificationResponseModelResponseModel>) => r.body as AccountSettingNotificationResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsDeletedGet
   */
  static readonly InlivewithAccountsDeletedGetPath = '/inlivewith/Accounts/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsDeletedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsDeletedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsDeletedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsDeletedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsDeletedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsDeletedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsDeletedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsDeletedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsDeletedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsDeletedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsActivatedGet
   */
  static readonly InlivewithAccountsActivatedGetPath = '/inlivewith/Accounts/Activated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsActivatedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsActivatedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsActivatedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsActivatedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsActivatedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsActivatedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsActivatedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsActivatedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsActivatedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsActivatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountIsDeletedAccountIdGet
   */
  static readonly InlivewithAccountIsDeletedAccountIdGetPath = '/inlivewith/Account/IsDeleted/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsDeletedAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsDeletedAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsDeletedAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsDeletedAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsDeletedAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsDeletedAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountIsDeletedAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsDeletedAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountIsDeletedAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountIsDeletedAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountIsDeletedAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountIsDeletedAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountDeleteByIdAccountIdDelete
   */
  static readonly InlivewithAccountDeleteByIdAccountIdDeletePath = '/inlivewith/Account/DeleteById/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountDeleteByIdAccountIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDeleteByIdAccountIdDelete$Plain$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountDeleteByIdAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountDeleteByIdAccountIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDeleteByIdAccountIdDelete$Plain(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountDeleteByIdAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountDeleteByIdAccountIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDeleteByIdAccountIdDelete$Json$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountDeleteByIdAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountDeleteByIdAccountIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDeleteByIdAccountIdDelete$Json(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountDeleteByIdAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete
   */
  static readonly InlivewithAccountDeleteByIdentityUserIdIdentityUserIdDeletePath = '/inlivewith/Account/DeleteByIdentityUserId/{identityUserId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete$Plain$Response(params: {
    identityUserId: string;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountDeleteByIdentityUserIdIdentityUserIdDeletePath, 'delete');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete$Plain(params: {
    identityUserId: string;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete$Json$Response(params: {
    identityUserId: string;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountDeleteByIdentityUserIdIdentityUserIdDeletePath, 'delete');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete$Json(params: {
    identityUserId: string;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountDeleteByIdentityUserIdIdentityUserIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountDownloadFilePost
   */
  static readonly InlivewithAccountDownloadFilePostPath = '/inlivewith/Account/DownloadFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountDownloadFilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDownloadFilePost$Plain$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountDownloadFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountDownloadFilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDownloadFilePost$Plain(params?: {
    body?: string
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountDownloadFilePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountDownloadFilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDownloadFilePost$Json$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountDownloadFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountDownloadFilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDownloadFilePost$Json(params?: {
    body?: string
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountDownloadFilePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountProcessAvatarAccountIdPut
   */
  static readonly InlivewithAccountProcessAvatarAccountIdPutPath = '/inlivewith/Account/ProcessAvatar/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountProcessAvatarAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountProcessAvatarAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountProcessAvatarAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountProcessAvatarAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountProcessAvatarAccountIdPut$Plain(params: {
    accountId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountProcessAvatarAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountProcessAvatarAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountProcessAvatarAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountProcessAvatarAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountProcessAvatarAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountProcessAvatarAccountIdPut$Json(params: {
    accountId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountProcessAvatarAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
