<div class="flex w-100">
  <div class="w-70">
    <div class="card">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">Profil de linliver</span>
        </div>
        <div>
          <span class="cursor-pointer text-gray"> Modifier</span>
        </div>
      </div>

      <div class="mt-4 mb-4">
        <div class="zone-avt">
          <img class="fit-size" [src]="account.avatar ?? defaultAvatar" alt="">
        </div>
      </div>

      <div class="flex">
        <div class="col-3 text-bold">
          <div class="mt-2 mb-2">Pseudo</div>
          <div class="mt-2 mb-2">E-mail </div>
          <div class="mt-2 mb-2">Nom </div>
          <div class="mt-2 mb-2">Prénom </div>
          <div class="mt-2 mb-2">Date de naissance </div>
          <div class="mt-2 mb-2">Mot de passe </div>
          <div class="mt-2 mb-2">Numéro de téléphone </div>
          <div class="mt-2 mb-2">Genre </div>
          <div class="mt-5">
            <div class="text-bold text-delete-account">
              Supprimer le compte
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="mt-2 mb-2">{{account.nickName}}</div>
          <div class="mt-2 mb-2">{{account.email}}</div>
          <div class="mt-2 mb-2">{{account.nickName}}</div>
          <div class="mt-2 mb-2">{{account.lastName}}</div>
          <div class="mt-2 mb-2">{{account.firstName}}</div>
          <div class="mt-2 mb-2">{{account.birthday | date:'dd/MM/YYYY'}}</div>
          <div class="mt-2 mb-2">*************
            <span class="ml-5 text-resend-link cursor-pointer">
              Envoyer un lien de réinitialisation
            </span>
          </div>
          <div class="mt-2 mb-2"></div>
          <div class="mt-2 mb-2">{{account.gender}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-30">
    <div class="card-1">
      <div class="flex justify-content-center">
        <div class="hexagon-avatar">
          <img class="hexagon fit-size" [src]="account.avatar ?? defaultAvatar">
        </div>
      </div>

      <div class="flex flex-column justify-content-center align-items-center mt-3">
        <div class="text-bold">
          {{account.nickName}}
        </div>
        <div class="">
          10 125 BPM
        </div>
        <div>
          <span class="text-bold">
            Inlivers depuis le :
          </span>
          {{ account.dateCreated | date:'dd/MM/YYYY'}}
        </div>
      </div>

      <div class="flex flex-column">
        <div class="flex mt-3 fs-14">
          <button pButton label="{{'Booster l’inliver' | translate}}"
            class="w-100 p-button-outlined blue mb-1 p-button-secondary mr-2"></button>

          <div class="flex justify-content-center align-items-center btn-email blue cursor-pointer">
            <i class="pi pi-envelope"></i>
          </div>

        </div>
        <div class="fs-14">

          <button pButton label="{{'Bloquer l’Inliver' | translate}}"
            class="w-100 p-button-outlined red mt-1 p-button-secondary mr-2"></button>
        </div>
      </div>
    </div>

    <div class="card-2">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">Confidentialité</span>
        </div>
        <div>
          <span class="cursor-pointer text-gray"> Modifier</span>
        </div>
      </div>

      <div class="mt-5">
        <span>
          Compte privé
        </span>
      </div>
    </div>

    <div class="card-2">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">Centres d’intêret</span>
        </div>
        <div>
          <span class="cursor-pointer text-gray"> Modifier</span>
        </div>
      </div>

      <div class="mt-5 flex flex-wrap">
        <div class="flex text-white" *ngFor="let item of hashtag">
          <div class="flex hashtag"> {{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
