<div class="ware-form w-100">

  <div class="flex flex align-items-center justify-content-center zone-audio">
      <div class="cursor-pointer flex align-items-center justify-content-center" (click)="onPlayButtonClick()"
          style="width: 3rem;">
          <div class="zone-btn" *ngIf="!isPlayingAudio">
              <i class="btn-play"></i>
          </div>
          <div class="zone-btn" *ngIf="isPlayingAudio">
              <i class="btn-pause"></i>
          </div>
      </div>

      <div class="ware-form">
          <ng-waveform #waveform class="waveform" [src]="mp3Resource$ | async" backgroundColor="#242536"
              regionBackgroundColor="rgba(255, 255, 255, 0.7)" regionStartStickColor="#21f032"
              regionEndStickColor="red" regionTextColor="#09417e" [withRegionLabels]="true" waveColor="#50c9ff"
              (trackLoaded)="onTrackLoaded($event)" (rendered)="onTrackRendered($event)"
              (durationChange)="onDurationChange($event)" (timeUpdate)="onTimeUpdate($event)" (paused)="onPaused()"
              (regionChange)="onRegionChange($event)">
          </ng-waveform>
      </div>

      <div class="m-1" style="width: 4rem; text-align: center; color: #fff;">
          {{timeOffAudio*1000 | date:'mm:ss'}}
      </div>
  </div>
</div>
