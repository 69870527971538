/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { CommentInputAddFileModel } from '../models/comment-input-add-file-model';
import { CommentInputEditContentModel } from '../models/comment-input-edit-content-model';
import { CommentMediaPostInputEditContentModel } from '../models/comment-media-post-input-edit-content-model';
import { CommentMediaPostResponseModelPaginationModelResponseModel } from '../models/comment-media-post-response-model-pagination-model-response-model';
import { CommentMediaPostResponseModelResponseModel } from '../models/comment-media-post-response-model-response-model';
import { CommentPostResponseModelListResponseModel } from '../models/comment-post-response-model-list-response-model';
import { CommentPostResponseModelPaginationModelResponseModel } from '../models/comment-post-response-model-pagination-model-response-model';
import { CommentPostResponseModelResponseModel } from '../models/comment-post-response-model-response-model';
import { CreateCommentPostInputModel } from '../models/create-comment-post-input-model';
import { MediaCommentMediaPostDeleteModel } from '../models/media-comment-media-post-delete-model';
import { MediaCommentPostDeleteModel } from '../models/media-comment-post-delete-model';
import { MediaPostDeleteModel } from '../models/media-post-delete-model';
import { MediaPostResponseModelListResponseModel } from '../models/media-post-response-model-list-response-model';
import { MediaPostResponseModelResponseModel } from '../models/media-post-response-model-response-model';
import { MediaPostWithUrlInputCreateModel } from '../models/media-post-with-url-input-create-model';
import { PostInputEditContentModel } from '../models/post-input-edit-content-model';
import { PostResponseModelListResponseModel } from '../models/post-response-model-list-response-model';
import { PostResponseModelPaginationModelResponseModel } from '../models/post-response-model-pagination-model-response-model';
import { PostResponseModelResponseModel } from '../models/post-response-model-response-model';
import { ProcessFileModel } from '../models/process-file-model';
import { ReactInputModel } from '../models/react-input-model';
import { ReactionCommentMediaPostResponseModelPaginationModelResponseModel } from '../models/reaction-comment-media-post-response-model-pagination-model-response-model';
import { ReactionCommentPostResponseModelPaginationModelResponseModel } from '../models/reaction-comment-post-response-model-pagination-model-response-model';
import { ReactionMediaPostResponseModelPaginationModelResponseModel } from '../models/reaction-media-post-response-model-pagination-model-response-model';
import { ReactionPostResponseModelPaginationModelResponseModel } from '../models/reaction-post-response-model-pagination-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class PostService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithPostPageAccountRequestIdGet
   */
  static readonly InlivewithPostPageAccountRequestIdGetPath = '/inlivewith/Post/Page/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostPageAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPageAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostPageAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostPageAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPageAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostPageAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostPageAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPageAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostPageAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostPageAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostPageAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostPageAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetByIdPostIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetByIdPostIdAccountRequestIdGetPath = '/inlivewith/Post/GetById/{postId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByIdPostIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByIdPostIdAccountRequestIdGet$Plain$Response(params: {
    postId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByIdPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByIdPostIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByIdPostIdAccountRequestIdGet$Plain(params: {
    postId: string;
    accountRequestId: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostGetByIdPostIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByIdPostIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByIdPostIdAccountRequestIdGet$Json$Response(params: {
    postId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByIdPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByIdPostIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByIdPostIdAccountRequestIdGet$Json(params: {
    postId: string;
    accountRequestId: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostGetByIdPostIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetHiddenPostAccountRequestIdGet
   */
  static readonly InlivewithPostGetHiddenPostAccountRequestIdGetPath = '/inlivewith/Post/GetHiddenPost/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetHiddenPostAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetHiddenPostAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetHiddenPostAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetHiddenPostAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetHiddenPostAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetHiddenPostAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetHiddenPostAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetHiddenPostAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetHiddenPostAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetHiddenPostAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetHiddenPostAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetHiddenPostAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetByAuthorAuthorIdAccountRequestIdGetPath = '/inlivewith/Post/GetByAuthor/{authorId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Plain$Response(params: {
    authorId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByAuthorAuthorIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('authorId', params.authorId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Plain(params: {
    authorId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Json$Response(params: {
    authorId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByAuthorAuthorIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('authorId', params.authorId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Json(params: {
    authorId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByAuthorAuthorIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetByStageStageIdAccountRequestIdTypeGet
   */
  static readonly InlivewithPostGetByStageStageIdAccountRequestIdTypeGetPath = '/inlivewith/Post/GetByStage/{stageId}/{accountRequestId}/{type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Plain$Response(params: {
    stageId: string;
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByStageStageIdAccountRequestIdTypeGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Plain(params: {
    stageId: string;
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Json$Response(params: {
    stageId: string;
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByStageStageIdAccountRequestIdTypeGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Json(params: {
    stageId: string;
    accountRequestId: string;
    type: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByStageStageIdAccountRequestIdTypeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetByClubClubIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetByClubClubIdAccountRequestIdGetPath = '/inlivewith/Post/GetByClub/{clubId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByClubClubIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdGet$Plain$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByClubClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByClubClubIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdGet$Plain(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByClubClubIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByClubClubIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdGet$Json$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByClubClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByClubClubIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdGet$Json(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByClubClubIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetPostForNewFeedAccountRequestIdGet
   */
  static readonly InlivewithPostGetPostForNewFeedAccountRequestIdGetPath = '/inlivewith/Post/GetPostForNewFeed/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetPostForNewFeedAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostForNewFeedAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetPostForNewFeedAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetPostForNewFeedAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostForNewFeedAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetPostForNewFeedAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetPostForNewFeedAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostForNewFeedAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetPostForNewFeedAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetPostForNewFeedAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetPostForNewFeedAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetPostForNewFeedAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet
   */
  static readonly InlivewithPostGetCurrentPostDiscussionAccountRequestIdGetPath = '/inlivewith/Post/GetCurrentPostDiscussion/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCurrentPostDiscussionAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCurrentPostDiscussionAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCurrentPostDiscussionAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet
   */
  static readonly InlivewithPostSearchCurrentPostDiscussionAccountRequestIdGetPath = '/inlivewith/Post/SearchCurrentPostDiscussion/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Plain$Response(params: {
    accountRequestId: string;
    title?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostSearchCurrentPostDiscussionAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('title', params.title, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Plain(params: {
    accountRequestId: string;
    title?: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Json$Response(params: {
    accountRequestId: string;
    title?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostSearchCurrentPostDiscussionAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('title', params.title, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Json(params: {
    accountRequestId: string;
    title?: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostSearchCurrentPostDiscussionAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGetPath = '/inlivewith/Post/GetMediaPostById/{postId}/{mediaPostId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet$Plain$Response(params: {
    postId: string;
    mediaPostId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<MediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet$Plain(params: {
    postId: string;
    mediaPostId: string;
    accountRequestId: string;
  }): Observable<MediaPostResponseModelResponseModel> {

    return this.inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelResponseModel>) => r.body as MediaPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet$Json$Response(params: {
    postId: string;
    mediaPostId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<MediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet$Json(params: {
    postId: string;
    mediaPostId: string;
    accountRequestId: string;
  }): Observable<MediaPostResponseModelResponseModel> {

    return this.inlivewithPostGetMediaPostByIdPostIdMediaPostIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelResponseModel>) => r.body as MediaPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetCommentsOnPostPostIdAccountRequestIdGetPath = '/inlivewith/Post/GetCommentsOnPost/{postId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Plain$Response(params: {
    postId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsOnPostPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Plain(params: {
    postId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>) => r.body as CommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Json$Response(params: {
    postId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsOnPostPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Json(params: {
    postId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsOnPostPostIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>) => r.body as CommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGetPath = '/inlivewith/Post/GetCommentsPostById/{postId}/{commentId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet$Plain$Response(params: {
    postId: string;
    commentId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('commentId', params.commentId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet$Plain(params: {
    postId: string;
    commentId: string;
    accountRequestId: string;
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet$Json$Response(params: {
    postId: string;
    commentId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('commentId', params.commentId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet$Json(params: {
    postId: string;
    commentId: string;
    accountRequestId: string;
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostGetCommentsPostByIdPostIdCommentIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGetPath = '/inlivewith/Post/GetCommentsOnMediaPost/{mediaPostId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet$Plain$Response(params: {
    mediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentMediaPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentMediaPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet$Plain(params: {
    mediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentMediaPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentMediaPostResponseModelPaginationModelResponseModel>) => r.body as CommentMediaPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet$Json$Response(params: {
    mediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentMediaPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentMediaPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet$Json(params: {
    mediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentMediaPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsOnMediaPostMediaPostIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentMediaPostResponseModelPaginationModelResponseModel>) => r.body as CommentMediaPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCreateAccountRequestIdPost
   */
  static readonly InlivewithPostCreateAccountRequestIdPostPath = '/inlivewith/Post/Create/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreateAccountRequestIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreateAccountRequestIdPost$Plain$Response(params: {
    accountRequestId: string;
    body?: {
'AuthorId'?: string;
'Message'?: string;
'StageId'?: string;
'ClubId'?: string;
'Title'?: string;
'Type'?: string;
'MediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreateAccountRequestIdPostPath, 'post');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreateAccountRequestIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreateAccountRequestIdPost$Plain(params: {
    accountRequestId: string;
    body?: {
'AuthorId'?: string;
'Message'?: string;
'StageId'?: string;
'ClubId'?: string;
'Title'?: string;
'Type'?: string;
'MediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostCreateAccountRequestIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreateAccountRequestIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreateAccountRequestIdPost$Json$Response(params: {
    accountRequestId: string;
    body?: {
'AuthorId'?: string;
'Message'?: string;
'StageId'?: string;
'ClubId'?: string;
'Title'?: string;
'Type'?: string;
'MediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreateAccountRequestIdPostPath, 'post');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreateAccountRequestIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreateAccountRequestIdPost$Json(params: {
    accountRequestId: string;
    body?: {
'AuthorId'?: string;
'Message'?: string;
'StageId'?: string;
'ClubId'?: string;
'Title'?: string;
'Type'?: string;
'MediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostCreateAccountRequestIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCreatePostForCelebrityAccountRequestIdPost
   */
  static readonly InlivewithPostCreatePostForCelebrityAccountRequestIdPostPath = '/inlivewith/Post/CreatePostForCelebrity/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreatePostForCelebrityAccountRequestIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreatePostForCelebrityAccountRequestIdPost$Plain$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
    body?: {
'AuthorId'?: string;
'Message'?: string;
'ClubsId'?: Array<string>;
'MediaFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<PostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreatePostForCelebrityAccountRequestIdPostPath, 'post');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreatePostForCelebrityAccountRequestIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreatePostForCelebrityAccountRequestIdPost$Plain(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
    body?: {
'AuthorId'?: string;
'Message'?: string;
'ClubsId'?: Array<string>;
'MediaFiles'?: Array<Blob>;
}
  }): Observable<PostResponseModelListResponseModel> {

    return this.inlivewithPostCreatePostForCelebrityAccountRequestIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelListResponseModel>) => r.body as PostResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreatePostForCelebrityAccountRequestIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreatePostForCelebrityAccountRequestIdPost$Json$Response(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
    body?: {
'AuthorId'?: string;
'Message'?: string;
'ClubsId'?: Array<string>;
'MediaFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<PostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreatePostForCelebrityAccountRequestIdPostPath, 'post');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreatePostForCelebrityAccountRequestIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCreatePostForCelebrityAccountRequestIdPost$Json(params: {
    accountRequestId: string;
    page?: number;
    limit?: number;
    body?: {
'AuthorId'?: string;
'Message'?: string;
'ClubsId'?: Array<string>;
'MediaFiles'?: Array<Blob>;
}
  }): Observable<PostResponseModelListResponseModel> {

    return this.inlivewithPostCreatePostForCelebrityAccountRequestIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelListResponseModel>) => r.body as PostResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostEditPostContentAccountRequestIdPut
   */
  static readonly InlivewithPostEditPostContentAccountRequestIdPutPath = '/inlivewith/Post/EditPostContent/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEditPostContentAccountRequestIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentAccountRequestIdPut$Plain$Response(params: {
    accountRequestId: string;
    body?: PostInputEditContentModel
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEditPostContentAccountRequestIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEditPostContentAccountRequestIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentAccountRequestIdPut$Plain(params: {
    accountRequestId: string;
    body?: PostInputEditContentModel
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostEditPostContentAccountRequestIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEditPostContentAccountRequestIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentAccountRequestIdPut$Json$Response(params: {
    accountRequestId: string;
    body?: PostInputEditContentModel
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEditPostContentAccountRequestIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEditPostContentAccountRequestIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentAccountRequestIdPut$Json(params: {
    accountRequestId: string;
    body?: PostInputEditContentModel
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostEditPostContentAccountRequestIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostEndPostDiscusionPostIdPut
   */
  static readonly InlivewithPostEndPostDiscusionPostIdPutPath = '/inlivewith/Post/EndPostDiscusion/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEndPostDiscusionPostIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Plain$Response(params: {
    postId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEndPostDiscusionPostIdPutPath, 'put');
    if (params) {
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEndPostDiscusionPostIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Plain(params: {
    postId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostEndPostDiscusionPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEndPostDiscusionPostIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Json$Response(params: {
    postId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEndPostDiscusionPostIdPutPath, 'put');
    if (params) {
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEndPostDiscusionPostIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Json(params: {
    postId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostEndPostDiscusionPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostHidePostAccountRequestIdPostIdPut
   */
  static readonly InlivewithPostHidePostAccountRequestIdPostIdPutPath = '/inlivewith/Post/HidePost/{accountRequestId}/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostHidePostAccountRequestIdPostIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostHidePostAccountRequestIdPostIdPut$Plain$Response(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostHidePostAccountRequestIdPostIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostHidePostAccountRequestIdPostIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostHidePostAccountRequestIdPostIdPut$Plain(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostHidePostAccountRequestIdPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostHidePostAccountRequestIdPostIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostHidePostAccountRequestIdPostIdPut$Json$Response(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostHidePostAccountRequestIdPostIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostHidePostAccountRequestIdPostIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostHidePostAccountRequestIdPostIdPut$Json(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostHidePostAccountRequestIdPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostUnHidePostAccountRequestIdPostIdPut
   */
  static readonly InlivewithPostUnHidePostAccountRequestIdPostIdPutPath = '/inlivewith/Post/UnHidePost/{accountRequestId}/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostUnHidePostAccountRequestIdPostIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostUnHidePostAccountRequestIdPostIdPut$Plain$Response(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostUnHidePostAccountRequestIdPostIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostUnHidePostAccountRequestIdPostIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostUnHidePostAccountRequestIdPostIdPut$Plain(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostUnHidePostAccountRequestIdPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json$Response(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostUnHidePostAccountRequestIdPostIdPutPath, 'put');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json(params: {
    accountRequestId: string;
    postId: string;
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostUnHidePostAccountRequestIdPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostDeletePostDelete
   */
  static readonly InlivewithPostDeletePostDeletePath = '/inlivewith/Post/DeletePost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeletePostDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Plain$Response(params?: {
    postId?: string;
    accountRequestDeleteId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeletePostDeletePath, 'delete');
    if (params) {
      rb.query('postId', params.postId, {});
      rb.query('accountRequestDeleteId', params.accountRequestDeleteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeletePostDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Plain(params?: {
    postId?: string;
    accountRequestDeleteId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeletePostDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeletePostDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Json$Response(params?: {
    postId?: string;
    accountRequestDeleteId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeletePostDeletePath, 'delete');
    if (params) {
      rb.query('postId', params.postId, {});
      rb.query('accountRequestDeleteId', params.accountRequestDeleteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeletePostDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Json(params?: {
    postId?: string;
    accountRequestDeleteId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeletePostDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetReactOnPostPostIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetReactOnPostPostIdAccountRequestIdGetPath = '/inlivewith/Post/GetReactOnPost/{postId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Plain$Response(params: {
    postId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnPostPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Plain(params: {
    postId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>) => r.body as ReactionPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Json$Response(params: {
    postId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnPostPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Json(params: {
    postId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnPostPostIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>) => r.body as ReactionPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGetPath = '/inlivewith/Post/GetReactOnMediaPost/{mediaPostId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet$Plain$Response(params: {
    mediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionMediaPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionMediaPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet$Plain(params: {
    mediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionMediaPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionMediaPostResponseModelPaginationModelResponseModel>) => r.body as ReactionMediaPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet$Json$Response(params: {
    mediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionMediaPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionMediaPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet$Json(params: {
    mediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionMediaPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnMediaPostMediaPostIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionMediaPostResponseModelPaginationModelResponseModel>) => r.body as ReactionMediaPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGetPath = '/inlivewith/Post/GetReactOnCommentPost/{commentPostId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet$Plain$Response(params: {
    commentPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionCommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('commentPostId', params.commentPostId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionCommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet$Plain(params: {
    commentPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionCommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionCommentPostResponseModelPaginationModelResponseModel>) => r.body as ReactionCommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet$Json$Response(params: {
    commentPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionCommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('commentPostId', params.commentPostId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionCommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet$Json(params: {
    commentPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionCommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnCommentPostCommentPostIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionCommentPostResponseModelPaginationModelResponseModel>) => r.body as ReactionCommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet
   */
  static readonly InlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGetPath = '/inlivewith/Post/GetReactOnCommentMediaPost/{commentMediaPostId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet$Plain$Response(params: {
    commentMediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionCommentMediaPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('commentMediaPostId', params.commentMediaPostId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionCommentMediaPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet$Plain(params: {
    commentMediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionCommentMediaPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionCommentMediaPostResponseModelPaginationModelResponseModel>) => r.body as ReactionCommentMediaPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet$Json$Response(params: {
    commentMediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionCommentMediaPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('commentMediaPostId', params.commentMediaPostId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionCommentMediaPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet$Json(params: {
    commentMediaPostId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionCommentMediaPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnCommentMediaPostCommentMediaPostIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionCommentMediaPostResponseModelPaginationModelResponseModel>) => r.body as ReactionCommentMediaPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostReactReactOnPostPost
   */
  static readonly InlivewithPostReactReactOnPostPostPath = '/inlivewith/Post/React/ReactOnPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostReactReactOnPostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnPostPost$Plain$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostReactReactOnPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostReactReactOnPostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnPostPost$Plain(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostReactReactOnPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostReactReactOnPostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnPostPost$Json$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostReactReactOnPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostReactReactOnPostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnPostPost$Json(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostReactReactOnPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostReactReactOnMediaPostPost
   */
  static readonly InlivewithPostReactReactOnMediaPostPostPath = '/inlivewith/Post/React/ReactOnMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostReactReactOnMediaPostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnMediaPostPost$Plain$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostReactReactOnMediaPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostReactReactOnMediaPostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnMediaPostPost$Plain(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostReactReactOnMediaPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostReactReactOnMediaPostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnMediaPostPost$Json$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostReactReactOnMediaPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostReactReactOnMediaPostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnMediaPostPost$Json(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostReactReactOnMediaPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostReactReactOnCommentPostPost
   */
  static readonly InlivewithPostReactReactOnCommentPostPostPath = '/inlivewith/Post/React/ReactOnCommentPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostReactReactOnCommentPostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnCommentPostPost$Plain$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostReactReactOnCommentPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostReactReactOnCommentPostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnCommentPostPost$Plain(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostReactReactOnCommentPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostReactReactOnCommentPostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnCommentPostPost$Json$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostReactReactOnCommentPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostReactReactOnCommentPostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnCommentPostPost$Json(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostReactReactOnCommentPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostReactReactOnCommentMediaPostPost
   */
  static readonly InlivewithPostReactReactOnCommentMediaPostPostPath = '/inlivewith/Post/React/ReactOnCommentMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostReactReactOnCommentMediaPostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnCommentMediaPostPost$Plain$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostReactReactOnCommentMediaPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostReactReactOnCommentMediaPostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnCommentMediaPostPost$Plain(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostReactReactOnCommentMediaPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostReactReactOnCommentMediaPostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnCommentMediaPostPost$Json$Response(params?: {
    body?: ReactInputModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostReactReactOnCommentMediaPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostReactReactOnCommentMediaPostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostReactReactOnCommentMediaPostPost$Json(params?: {
    body?: ReactInputModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostReactReactOnCommentMediaPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostMediaPostAddMediaPostPut
   */
  static readonly InlivewithPostMediaPostAddMediaPostPutPath = '/inlivewith/Post/MediaPost/AddMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostAddMediaPostPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Plain$Response(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<MediaPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostAddMediaPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostAddMediaPostPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Plain(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<MediaPostResponseModelListResponseModel> {

    return this.inlivewithPostMediaPostAddMediaPostPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelListResponseModel>) => r.body as MediaPostResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostAddMediaPostPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Json$Response(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<MediaPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostAddMediaPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostAddMediaPostPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Json(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<MediaPostResponseModelListResponseModel> {

    return this.inlivewithPostMediaPostAddMediaPostPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelListResponseModel>) => r.body as MediaPostResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostMediaPostAddMediaPostUrlsPut
   */
  static readonly InlivewithPostMediaPostAddMediaPostUrlsPutPath = '/inlivewith/Post/MediaPost/AddMediaPostUrls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostAddMediaPostUrlsPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostAddMediaPostUrlsPut$Plain$Response(params?: {
    body?: MediaPostWithUrlInputCreateModel
  }): Observable<StrictHttpResponse<MediaPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostAddMediaPostUrlsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostAddMediaPostUrlsPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostAddMediaPostUrlsPut$Plain(params?: {
    body?: MediaPostWithUrlInputCreateModel
  }): Observable<MediaPostResponseModelListResponseModel> {

    return this.inlivewithPostMediaPostAddMediaPostUrlsPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelListResponseModel>) => r.body as MediaPostResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostAddMediaPostUrlsPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostAddMediaPostUrlsPut$Json$Response(params?: {
    body?: MediaPostWithUrlInputCreateModel
  }): Observable<StrictHttpResponse<MediaPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostAddMediaPostUrlsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostAddMediaPostUrlsPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostAddMediaPostUrlsPut$Json(params?: {
    body?: MediaPostWithUrlInputCreateModel
  }): Observable<MediaPostResponseModelListResponseModel> {

    return this.inlivewithPostMediaPostAddMediaPostUrlsPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelListResponseModel>) => r.body as MediaPostResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostMediaPostDeleteMediaPostDelete
   */
  static readonly InlivewithPostMediaPostDeleteMediaPostDeletePath = '/inlivewith/Post/MediaPost/DeleteMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostDeleteMediaPostDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Plain$Response(params?: {
    body?: MediaPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostDeleteMediaPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostDeleteMediaPostDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Plain(params?: {
    body?: MediaPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostMediaPostDeleteMediaPostDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostDeleteMediaPostDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Json$Response(params?: {
    body?: MediaPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostDeleteMediaPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostDeleteMediaPostDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Json(params?: {
    body?: MediaPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostMediaPostDeleteMediaPostDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentCreateCommentPostPost
   */
  static readonly InlivewithPostCommentCreateCommentPostPostPath = '/inlivewith/Post/Comment/CreateCommentPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateCommentPostPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentPostPost$Plain$Response(params?: {
    body?: {
'PostId'?: string;
'AuthorId'?: string;
'Message'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateCommentPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateCommentPostPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentPostPost$Plain(params?: {
    body?: {
'PostId'?: string;
'AuthorId'?: string;
'Message'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateCommentPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateCommentPostPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentPostPost$Json$Response(params?: {
    body?: {
'PostId'?: string;
'AuthorId'?: string;
'Message'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateCommentPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateCommentPostPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentPostPost$Json(params?: {
    body?: {
'PostId'?: string;
'AuthorId'?: string;
'Message'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateCommentPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentCreateCommentsPostPostIdPost
   */
  static readonly InlivewithPostCommentCreateCommentsPostPostIdPostPath = '/inlivewith/Post/Comment/CreateCommentsPost/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateCommentsPostPostIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentCreateCommentsPostPostIdPost$Plain$Response(params: {
    postId: string;
    body?: Array<CreateCommentPostInputModel>
  }): Observable<StrictHttpResponse<CommentPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateCommentsPostPostIdPostPath, 'post');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateCommentsPostPostIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentCreateCommentsPostPostIdPost$Plain(params: {
    postId: string;
    body?: Array<CreateCommentPostInputModel>
  }): Observable<CommentPostResponseModelListResponseModel> {

    return this.inlivewithPostCommentCreateCommentsPostPostIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelListResponseModel>) => r.body as CommentPostResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateCommentsPostPostIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentCreateCommentsPostPostIdPost$Json$Response(params: {
    postId: string;
    body?: Array<CreateCommentPostInputModel>
  }): Observable<StrictHttpResponse<CommentPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateCommentsPostPostIdPostPath, 'post');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateCommentsPostPostIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentCreateCommentsPostPostIdPost$Json(params: {
    postId: string;
    body?: Array<CreateCommentPostInputModel>
  }): Observable<CommentPostResponseModelListResponseModel> {

    return this.inlivewithPostCommentCreateCommentsPostPostIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelListResponseModel>) => r.body as CommentPostResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentCreateCommentOnMediaPostPost
   */
  static readonly InlivewithPostCommentCreateCommentOnMediaPostPostPath = '/inlivewith/Post/Comment/CreateCommentOnMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateCommentOnMediaPostPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentOnMediaPostPost$Plain$Response(params?: {
    body?: {
'AuthorId'?: string;
'PostId'?: string;
'MediaPostId'?: string;
'Message'?: string;
'MediaCommentMediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CommentMediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateCommentOnMediaPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentMediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateCommentOnMediaPostPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentOnMediaPostPost$Plain(params?: {
    body?: {
'AuthorId'?: string;
'PostId'?: string;
'MediaPostId'?: string;
'Message'?: string;
'MediaCommentMediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<CommentMediaPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateCommentOnMediaPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentMediaPostResponseModelResponseModel>) => r.body as CommentMediaPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateCommentOnMediaPostPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentOnMediaPostPost$Json$Response(params?: {
    body?: {
'AuthorId'?: string;
'PostId'?: string;
'MediaPostId'?: string;
'Message'?: string;
'MediaCommentMediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CommentMediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateCommentOnMediaPostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentMediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateCommentOnMediaPostPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateCommentOnMediaPostPost$Json(params?: {
    body?: {
'AuthorId'?: string;
'PostId'?: string;
'MediaPostId'?: string;
'Message'?: string;
'MediaCommentMediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<CommentMediaPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateCommentOnMediaPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentMediaPostResponseModelResponseModel>) => r.body as CommentMediaPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost
   */
  static readonly InlivewithPostCommentCreateReplyCommentOnPostCommentIdPostPath = '/inlivewith/Post/Comment/CreateReplyCommentOnPost/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Plain$Response(params: {
    commentId: string;
    body?: {
'PostId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateReplyCommentOnPostCommentIdPostPath, 'post');
    if (params) {
      rb.path('commentId', params.commentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Plain(params: {
    commentId: string;
    body?: {
'PostId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json$Response(params: {
    commentId: string;
    body?: {
'PostId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateReplyCommentOnPostCommentIdPostPath, 'post');
    if (params) {
      rb.path('commentId', params.commentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json(params: {
    commentId: string;
    body?: {
'PostId'?: string;
'AuthorId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateReplyCommentOnPostCommentIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost
   */
  static readonly InlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPostPath = '/inlivewith/Post/Comment/CreateReplyCommentOnMediaPost/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost$Plain$Response(params: {
    commentId: string;
    body?: {
'AuthorId'?: string;
'PostId'?: string;
'MediaPostId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentMediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CommentMediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPostPath, 'post');
    if (params) {
      rb.path('commentId', params.commentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentMediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost$Plain(params: {
    commentId: string;
    body?: {
'AuthorId'?: string;
'PostId'?: string;
'MediaPostId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentMediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<CommentMediaPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentMediaPostResponseModelResponseModel>) => r.body as CommentMediaPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost$Json$Response(params: {
    commentId: string;
    body?: {
'AuthorId'?: string;
'PostId'?: string;
'MediaPostId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentMediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<CommentMediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPostPath, 'post');
    if (params) {
      rb.path('commentId', params.commentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentMediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost$Json(params: {
    commentId: string;
    body?: {
'AuthorId'?: string;
'PostId'?: string;
'MediaPostId'?: string;
'Message'?: string;
'ParentId'?: string;
'MediaCommentMediaFiles'?: Array<Blob>;
'MentionReceiverIds'?: Array<string>;
}
  }): Observable<CommentMediaPostResponseModelResponseModel> {

    return this.inlivewithPostCommentCreateReplyCommentOnMediaPostCommentIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentMediaPostResponseModelResponseModel>) => r.body as CommentMediaPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentEditContentCommentOnPostPut
   */
  static readonly InlivewithPostCommentEditContentCommentOnPostPutPath = '/inlivewith/Post/Comment/EditContentCommentOnPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentEditContentCommentOnPostPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnPostPut$Plain$Response(params?: {
    body?: CommentInputEditContentModel
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentEditContentCommentOnPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentEditContentCommentOnPostPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnPostPut$Plain(params?: {
    body?: CommentInputEditContentModel
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentEditContentCommentOnPostPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentEditContentCommentOnPostPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnPostPut$Json$Response(params?: {
    body?: CommentInputEditContentModel
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentEditContentCommentOnPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentEditContentCommentOnPostPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnPostPut$Json(params?: {
    body?: CommentInputEditContentModel
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentEditContentCommentOnPostPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentEditContentCommentOnMediaPostPut
   */
  static readonly InlivewithPostCommentEditContentCommentOnMediaPostPutPath = '/inlivewith/Post/Comment/EditContentCommentOnMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentEditContentCommentOnMediaPostPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnMediaPostPut$Plain$Response(params?: {
    body?: CommentMediaPostInputEditContentModel
  }): Observable<StrictHttpResponse<CommentMediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentEditContentCommentOnMediaPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentMediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentEditContentCommentOnMediaPostPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnMediaPostPut$Plain(params?: {
    body?: CommentMediaPostInputEditContentModel
  }): Observable<CommentMediaPostResponseModelResponseModel> {

    return this.inlivewithPostCommentEditContentCommentOnMediaPostPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentMediaPostResponseModelResponseModel>) => r.body as CommentMediaPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentEditContentCommentOnMediaPostPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnMediaPostPut$Json$Response(params?: {
    body?: CommentMediaPostInputEditContentModel
  }): Observable<StrictHttpResponse<CommentMediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentEditContentCommentOnMediaPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentMediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentEditContentCommentOnMediaPostPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentEditContentCommentOnMediaPostPut$Json(params?: {
    body?: CommentMediaPostInputEditContentModel
  }): Observable<CommentMediaPostResponseModelResponseModel> {

    return this.inlivewithPostCommentEditContentCommentOnMediaPostPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentMediaPostResponseModelResponseModel>) => r.body as CommentMediaPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentAddMediaCommentOnPostPut
   */
  static readonly InlivewithPostCommentAddMediaCommentOnPostPutPath = '/inlivewith/Post/Comment/AddMediaCommentOnPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentAddMediaCommentOnPostPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentAddMediaCommentOnPostPut$Plain$Response(params?: {
    body?: {
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentAddMediaCommentOnPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentAddMediaCommentOnPostPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentAddMediaCommentOnPostPut$Plain(params?: {
    body?: {
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentAddMediaCommentOnPostPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentAddMediaCommentOnPostPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentAddMediaCommentOnPostPut$Json$Response(params?: {
    body?: {
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<CommentPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentAddMediaCommentOnPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentAddMediaCommentOnPostPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostCommentAddMediaCommentOnPostPut$Json(params?: {
    body?: {
'CommentId'?: string;
'MediaCommentFiles'?: Array<Blob>;
}
  }): Observable<CommentPostResponseModelResponseModel> {

    return this.inlivewithPostCommentAddMediaCommentOnPostPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelResponseModel>) => r.body as CommentPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentAddMediaCommentOnMediaPostPut
   */
  static readonly InlivewithPostCommentAddMediaCommentOnMediaPostPutPath = '/inlivewith/Post/Comment/AddMediaCommentOnMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentAddMediaCommentOnMediaPostPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentAddMediaCommentOnMediaPostPut$Plain$Response(params?: {
    body?: CommentInputAddFileModel
  }): Observable<StrictHttpResponse<CommentMediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentAddMediaCommentOnMediaPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentMediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentAddMediaCommentOnMediaPostPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentAddMediaCommentOnMediaPostPut$Plain(params?: {
    body?: CommentInputAddFileModel
  }): Observable<CommentMediaPostResponseModelResponseModel> {

    return this.inlivewithPostCommentAddMediaCommentOnMediaPostPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentMediaPostResponseModelResponseModel>) => r.body as CommentMediaPostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentAddMediaCommentOnMediaPostPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentAddMediaCommentOnMediaPostPut$Json$Response(params?: {
    body?: CommentInputAddFileModel
  }): Observable<StrictHttpResponse<CommentMediaPostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentAddMediaCommentOnMediaPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentMediaPostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentAddMediaCommentOnMediaPostPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentAddMediaCommentOnMediaPostPut$Json(params?: {
    body?: CommentInputAddFileModel
  }): Observable<CommentMediaPostResponseModelResponseModel> {

    return this.inlivewithPostCommentAddMediaCommentOnMediaPostPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentMediaPostResponseModelResponseModel>) => r.body as CommentMediaPostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete
   */
  static readonly InlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDeletePath = '/inlivewith/Post/Comment/DeleteCommentOnPost/{commentPostId}/{accountRequestDeleteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Plain$Response(params: {
    commentPostId: string;
    accountRequestDeleteId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('commentPostId', params.commentPostId, {});
      rb.path('accountRequestDeleteId', params.accountRequestDeleteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Plain(params: {
    commentPostId: string;
    accountRequestDeleteId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json$Response(params: {
    commentPostId: string;
    accountRequestDeleteId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('commentPostId', params.commentPostId, {});
      rb.path('accountRequestDeleteId', params.accountRequestDeleteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json(params: {
    commentPostId: string;
    accountRequestDeleteId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteCommentOnPostCommentPostIdAccountRequestDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete
   */
  static readonly InlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDeletePath = '/inlivewith/Post/Comment/DeleteCommentOnMediaPost/{commentMediaPostId}/{accountRequestDeleteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete$Plain$Response(params: {
    commentMediaPostId: string;
    accountRequestDeleteId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('commentMediaPostId', params.commentMediaPostId, {});
      rb.path('accountRequestDeleteId', params.accountRequestDeleteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete$Plain(params: {
    commentMediaPostId: string;
    accountRequestDeleteId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete$Json$Response(params: {
    commentMediaPostId: string;
    accountRequestDeleteId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('commentMediaPostId', params.commentMediaPostId, {});
      rb.path('accountRequestDeleteId', params.accountRequestDeleteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete$Json(params: {
    commentMediaPostId: string;
    accountRequestDeleteId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteCommentOnMediaPostCommentMediaPostIdAccountRequestDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentDeleteMediaOnCommentPostDelete
   */
  static readonly InlivewithPostCommentDeleteMediaOnCommentPostDeletePath = '/inlivewith/Post/Comment/DeleteMediaOnCommentPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteMediaOnCommentPostDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentPostDelete$Plain$Response(params?: {
    body?: MediaCommentPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteMediaOnCommentPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteMediaOnCommentPostDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentPostDelete$Plain(params?: {
    body?: MediaCommentPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteMediaOnCommentPostDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json$Response(params?: {
    body?: MediaCommentPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteMediaOnCommentPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json(params?: {
    body?: MediaCommentPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteMediaOnCommentPostDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete
   */
  static readonly InlivewithPostCommentDeleteMediaOnCommentMediaPostDeletePath = '/inlivewith/Post/Comment/DeleteMediaOnCommentMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete$Plain$Response(params?: {
    body?: MediaCommentMediaPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteMediaOnCommentMediaPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete$Plain(params?: {
    body?: MediaCommentMediaPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete$Json$Response(params?: {
    body?: MediaCommentMediaPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCommentDeleteMediaOnCommentMediaPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete$Json(params?: {
    body?: MediaCommentMediaPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostCommentDeleteMediaOnCommentMediaPostDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostProcessMediaMediaPostIdPut
   */
  static readonly InlivewithPostProcessMediaMediaPostIdPutPath = '/inlivewith/Post/ProcessMedia/{mediaPostId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaMediaPostIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Plain$Response(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaMediaPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaMediaPostIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Plain(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaMediaPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaMediaPostIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Json$Response(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaMediaPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaMediaPostIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Json(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaMediaPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostProcessMediaCommentMediaCommentPostIdPut
   */
  static readonly InlivewithPostProcessMediaCommentMediaCommentPostIdPutPath = '/inlivewith/Post/ProcessMediaComment/{mediaCommentPostId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Plain$Response(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaCommentMediaCommentPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaCommentPostId', params.mediaCommentPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Plain(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Json$Response(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaCommentMediaCommentPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaCommentPostId', params.mediaCommentPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Json(params: {
    mediaCommentPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaCommentMediaCommentPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut
   */
  static readonly InlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPutPath = '/inlivewith/Post/ProcessMediaCommentMedia/{mediaCommentMediaPostId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut$Plain$Response(params: {
    mediaCommentMediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaCommentMediaPostId', params.mediaCommentMediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut$Plain(params: {
    mediaCommentMediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut$Json$Response(params: {
    mediaCommentMediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaCommentMediaPostId', params.mediaCommentMediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut$Json(params: {
    mediaCommentMediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaCommentMediaMediaCommentMediaPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
