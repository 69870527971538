import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { IdentityService } from "../services/identity.service";
import { STORAGE_KEY } from "../constant";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let req;
    if (request.url.indexOf("/sofanmedia/lang") >= 0) {
      req = request.clone();
    } else
      req = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem(STORAGE_KEY.TOKEN)}`,
        },
      });

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          IdentityService.RefreshToken();
        }
        throw err.message;
      })
    );
  }
}
