/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccessLevelsEnumPaginationModelResponseModel } from '../models/access-levels-enum-pagination-model-response-model';
import { AccessLevelsEnumResponseModel } from '../models/access-levels-enum-response-model';
import { AppEnumAllModelResponseModel } from '../models/app-enum-all-model-response-model';
import { ClubCategoriesEnumPaginationModelResponseModel } from '../models/club-categories-enum-pagination-model-response-model';
import { ClubCategoriesEnumResponseModel } from '../models/club-categories-enum-response-model';
import { ClubTypesEnumPaginationModelResponseModel } from '../models/club-types-enum-pagination-model-response-model';
import { ClubTypesEnumResponseModel } from '../models/club-types-enum-response-model';
import { GendersEnumPaginationModelResponseModel } from '../models/genders-enum-pagination-model-response-model';
import { GendersEnumResponseModel } from '../models/genders-enum-response-model';
import { ReactionsEnumPaginationModelResponseModel } from '../models/reactions-enum-pagination-model-response-model';
import { ReactionsEnumResponseModel } from '../models/reactions-enum-response-model';
import { RolesEnumPaginationModelResponseModel } from '../models/roles-enum-pagination-model-response-model';
import { RolesEnumResponseModel } from '../models/roles-enum-response-model';

@Injectable({
  providedIn: 'root',
})
export class AppEnumService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithAppEnumAllGet
   */
  static readonly InlivewithAppEnumAllGetPath = '/inlivewith/AppEnum/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAllGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<AppEnumAllModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AppEnumAllModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAllGet$Plain(params?: {
  }): Observable<AppEnumAllModelResponseModel> {

    return this.inlivewithAppEnumAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AppEnumAllModelResponseModel>) => r.body as AppEnumAllModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAllGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<AppEnumAllModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AppEnumAllModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAllGet$Json(params?: {
  }): Observable<AppEnumAllModelResponseModel> {

    return this.inlivewithAppEnumAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AppEnumAllModelResponseModel>) => r.body as AppEnumAllModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumGendersGet
   */
  static readonly InlivewithAppEnumGendersGetPath = '/inlivewith/AppEnum/Genders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGendersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGendersGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GendersEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGendersGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGendersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGendersGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<GendersEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumGendersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumPaginationModelResponseModel>) => r.body as GendersEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGendersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGendersGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<GendersEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGendersGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGendersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGendersGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<GendersEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumGendersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumPaginationModelResponseModel>) => r.body as GendersEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumGenderByNameNameGet
   */
  static readonly InlivewithAppEnumGenderByNameNameGetPath = '/inlivewith/AppEnum/GenderByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGenderByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGenderByNameNameGet$Plain$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGenderByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGenderByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGenderByNameNameGet$Plain(params: {
    name: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAppEnumGenderByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGenderByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGenderByNameNameGet$Json$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGenderByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGenderByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGenderByNameNameGet$Json(params: {
    name: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAppEnumGenderByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumGenderByValueValueGet
   */
  static readonly InlivewithAppEnumGenderByValueValueGetPath = '/inlivewith/AppEnum/GenderByValue/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGenderByValueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGenderByValueValueGet$Plain$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGenderByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGenderByValueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGenderByValueValueGet$Plain(params: {
    value: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAppEnumGenderByValueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumGenderByValueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGenderByValueValueGet$Json$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<GendersEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumGenderByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GendersEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumGenderByValueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumGenderByValueValueGet$Json(params: {
    value: string;
  }): Observable<GendersEnumResponseModel> {

    return this.inlivewithAppEnumGenderByValueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GendersEnumResponseModel>) => r.body as GendersEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumReactionsGet
   */
  static readonly InlivewithAppEnumReactionsGetPath = '/inlivewith/AppEnum/Reactions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumReactionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionsGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionsEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumReactionsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionsEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumReactionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionsGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ReactionsEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumReactionsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionsEnumPaginationModelResponseModel>) => r.body as ReactionsEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumReactionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionsGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionsEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumReactionsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionsEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumReactionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionsGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ReactionsEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumReactionsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionsEnumPaginationModelResponseModel>) => r.body as ReactionsEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumReactionByNameNameGet
   */
  static readonly InlivewithAppEnumReactionByNameNameGetPath = '/inlivewith/AppEnum/ReactionByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumReactionByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionByNameNameGet$Plain$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<ReactionsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumReactionByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumReactionByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionByNameNameGet$Plain(params: {
    name: string;
  }): Observable<ReactionsEnumResponseModel> {

    return this.inlivewithAppEnumReactionByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionsEnumResponseModel>) => r.body as ReactionsEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumReactionByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionByNameNameGet$Json$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<ReactionsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumReactionByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumReactionByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionByNameNameGet$Json(params: {
    name: string;
  }): Observable<ReactionsEnumResponseModel> {

    return this.inlivewithAppEnumReactionByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionsEnumResponseModel>) => r.body as ReactionsEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumReactionByValueValueGet
   */
  static readonly InlivewithAppEnumReactionByValueValueGetPath = '/inlivewith/AppEnum/ReactionByValue/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumReactionByValueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionByValueValueGet$Plain$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<ReactionsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumReactionByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumReactionByValueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionByValueValueGet$Plain(params: {
    value: string;
  }): Observable<ReactionsEnumResponseModel> {

    return this.inlivewithAppEnumReactionByValueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionsEnumResponseModel>) => r.body as ReactionsEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumReactionByValueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionByValueValueGet$Json$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<ReactionsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumReactionByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumReactionByValueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReactionByValueValueGet$Json(params: {
    value: string;
  }): Observable<ReactionsEnumResponseModel> {

    return this.inlivewithAppEnumReactionByValueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionsEnumResponseModel>) => r.body as ReactionsEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumRolesGet
   */
  static readonly InlivewithAppEnumRolesGetPath = '/inlivewith/AppEnum/Roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumRolesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRolesGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<RolesEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumRolesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RolesEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumRolesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRolesGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<RolesEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumRolesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RolesEnumPaginationModelResponseModel>) => r.body as RolesEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumRolesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRolesGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<RolesEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumRolesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RolesEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumRolesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRolesGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<RolesEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumRolesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RolesEnumPaginationModelResponseModel>) => r.body as RolesEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumRoleByNameNameGet
   */
  static readonly InlivewithAppEnumRoleByNameNameGetPath = '/inlivewith/AppEnum/RoleByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumRoleByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRoleByNameNameGet$Plain$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<RolesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumRoleByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RolesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumRoleByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRoleByNameNameGet$Plain(params: {
    name: string;
  }): Observable<RolesEnumResponseModel> {

    return this.inlivewithAppEnumRoleByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RolesEnumResponseModel>) => r.body as RolesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumRoleByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRoleByNameNameGet$Json$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<RolesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumRoleByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RolesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumRoleByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRoleByNameNameGet$Json(params: {
    name: string;
  }): Observable<RolesEnumResponseModel> {

    return this.inlivewithAppEnumRoleByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RolesEnumResponseModel>) => r.body as RolesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumRoleByValueValueGet
   */
  static readonly InlivewithAppEnumRoleByValueValueGetPath = '/inlivewith/AppEnum/RoleByValue/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumRoleByValueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRoleByValueValueGet$Plain$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<RolesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumRoleByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RolesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumRoleByValueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRoleByValueValueGet$Plain(params: {
    value: string;
  }): Observable<RolesEnumResponseModel> {

    return this.inlivewithAppEnumRoleByValueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RolesEnumResponseModel>) => r.body as RolesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumRoleByValueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRoleByValueValueGet$Json$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<RolesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumRoleByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RolesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumRoleByValueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumRoleByValueValueGet$Json(params: {
    value: string;
  }): Observable<RolesEnumResponseModel> {

    return this.inlivewithAppEnumRoleByValueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RolesEnumResponseModel>) => r.body as RolesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumClubTypesGet
   */
  static readonly InlivewithAppEnumClubTypesGetPath = '/inlivewith/AppEnum/ClubTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypesGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubTypesEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubTypesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypesGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubTypesEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumClubTypesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumPaginationModelResponseModel>) => r.body as ClubTypesEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypesGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubTypesEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubTypesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubTypesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypesGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubTypesEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumClubTypesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumPaginationModelResponseModel>) => r.body as ClubTypesEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumClubTypeByNameNameGet
   */
  static readonly InlivewithAppEnumClubTypeByNameNameGetPath = '/inlivewith/AppEnum/ClubTypeByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubTypeByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypeByNameNameGet$Plain$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<ClubTypesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubTypeByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubTypeByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypeByNameNameGet$Plain(params: {
    name: string;
  }): Observable<ClubTypesEnumResponseModel> {

    return this.inlivewithAppEnumClubTypeByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumResponseModel>) => r.body as ClubTypesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubTypeByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypeByNameNameGet$Json$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<ClubTypesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubTypeByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubTypeByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypeByNameNameGet$Json(params: {
    name: string;
  }): Observable<ClubTypesEnumResponseModel> {

    return this.inlivewithAppEnumClubTypeByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumResponseModel>) => r.body as ClubTypesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumClubTypeByValueValueGet
   */
  static readonly InlivewithAppEnumClubTypeByValueValueGetPath = '/inlivewith/AppEnum/ClubTypeByValue/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubTypeByValueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypeByValueValueGet$Plain$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<ClubTypesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubTypeByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubTypeByValueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypeByValueValueGet$Plain(params: {
    value: string;
  }): Observable<ClubTypesEnumResponseModel> {

    return this.inlivewithAppEnumClubTypeByValueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumResponseModel>) => r.body as ClubTypesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubTypeByValueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypeByValueValueGet$Json$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<ClubTypesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubTypeByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubTypesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubTypeByValueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubTypeByValueValueGet$Json(params: {
    value: string;
  }): Observable<ClubTypesEnumResponseModel> {

    return this.inlivewithAppEnumClubTypeByValueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubTypesEnumResponseModel>) => r.body as ClubTypesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumClubCategoriesGet
   */
  static readonly InlivewithAppEnumClubCategoriesGetPath = '/inlivewith/AppEnum/ClubCategories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubCategoriesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoriesGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubCategoriesEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubCategoriesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubCategoriesEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubCategoriesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoriesGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubCategoriesEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumClubCategoriesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubCategoriesEnumPaginationModelResponseModel>) => r.body as ClubCategoriesEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubCategoriesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoriesGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubCategoriesEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubCategoriesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubCategoriesEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubCategoriesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoriesGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubCategoriesEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumClubCategoriesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubCategoriesEnumPaginationModelResponseModel>) => r.body as ClubCategoriesEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumClubCategoryByNameNameGet
   */
  static readonly InlivewithAppEnumClubCategoryByNameNameGetPath = '/inlivewith/AppEnum/ClubCategoryByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubCategoryByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoryByNameNameGet$Plain$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<ClubCategoriesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubCategoryByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubCategoriesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubCategoryByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoryByNameNameGet$Plain(params: {
    name: string;
  }): Observable<ClubCategoriesEnumResponseModel> {

    return this.inlivewithAppEnumClubCategoryByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubCategoriesEnumResponseModel>) => r.body as ClubCategoriesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubCategoryByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoryByNameNameGet$Json$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<ClubCategoriesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubCategoryByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubCategoriesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubCategoryByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoryByNameNameGet$Json(params: {
    name: string;
  }): Observable<ClubCategoriesEnumResponseModel> {

    return this.inlivewithAppEnumClubCategoryByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubCategoriesEnumResponseModel>) => r.body as ClubCategoriesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumClubCategoryByValueValueGet
   */
  static readonly InlivewithAppEnumClubCategoryByValueValueGetPath = '/inlivewith/AppEnum/ClubCategoryByValue/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubCategoryByValueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoryByValueValueGet$Plain$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<ClubCategoriesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubCategoryByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubCategoriesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubCategoryByValueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoryByValueValueGet$Plain(params: {
    value: string;
  }): Observable<ClubCategoriesEnumResponseModel> {

    return this.inlivewithAppEnumClubCategoryByValueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubCategoriesEnumResponseModel>) => r.body as ClubCategoriesEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumClubCategoryByValueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoryByValueValueGet$Json$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<ClubCategoriesEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumClubCategoryByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubCategoriesEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumClubCategoryByValueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumClubCategoryByValueValueGet$Json(params: {
    value: string;
  }): Observable<ClubCategoriesEnumResponseModel> {

    return this.inlivewithAppEnumClubCategoryByValueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubCategoriesEnumResponseModel>) => r.body as ClubCategoriesEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumAccessLevelsGet
   */
  static readonly InlivewithAppEnumAccessLevelsGetPath = '/inlivewith/AppEnum/AccessLevels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumAccessLevelsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelsGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccessLevelsEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumAccessLevelsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessLevelsEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumAccessLevelsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelsGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccessLevelsEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumAccessLevelsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccessLevelsEnumPaginationModelResponseModel>) => r.body as AccessLevelsEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumAccessLevelsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelsGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccessLevelsEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumAccessLevelsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessLevelsEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumAccessLevelsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelsGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccessLevelsEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumAccessLevelsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccessLevelsEnumPaginationModelResponseModel>) => r.body as AccessLevelsEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumAccessLevelByNameNameGet
   */
  static readonly InlivewithAppEnumAccessLevelByNameNameGetPath = '/inlivewith/AppEnum/AccessLevelByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumAccessLevelByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelByNameNameGet$Plain$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<AccessLevelsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumAccessLevelByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessLevelsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumAccessLevelByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelByNameNameGet$Plain(params: {
    name: string;
  }): Observable<AccessLevelsEnumResponseModel> {

    return this.inlivewithAppEnumAccessLevelByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccessLevelsEnumResponseModel>) => r.body as AccessLevelsEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumAccessLevelByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelByNameNameGet$Json$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<AccessLevelsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumAccessLevelByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessLevelsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumAccessLevelByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelByNameNameGet$Json(params: {
    name: string;
  }): Observable<AccessLevelsEnumResponseModel> {

    return this.inlivewithAppEnumAccessLevelByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccessLevelsEnumResponseModel>) => r.body as AccessLevelsEnumResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumAccessLevelByValueValueGet
   */
  static readonly InlivewithAppEnumAccessLevelByValueValueGetPath = '/inlivewith/AppEnum/AccessLevelByValue/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumAccessLevelByValueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelByValueValueGet$Plain$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<AccessLevelsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumAccessLevelByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessLevelsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumAccessLevelByValueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelByValueValueGet$Plain(params: {
    value: string;
  }): Observable<AccessLevelsEnumResponseModel> {

    return this.inlivewithAppEnumAccessLevelByValueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccessLevelsEnumResponseModel>) => r.body as AccessLevelsEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumAccessLevelByValueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelByValueValueGet$Json$Response(params: {
    value: string;
  }): Observable<StrictHttpResponse<AccessLevelsEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumAccessLevelByValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessLevelsEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumAccessLevelByValueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAccessLevelByValueValueGet$Json(params: {
    value: string;
  }): Observable<AccessLevelsEnumResponseModel> {

    return this.inlivewithAppEnumAccessLevelByValueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccessLevelsEnumResponseModel>) => r.body as AccessLevelsEnumResponseModel)
    );
  }

}
