/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountPaginationModelResponseModel } from '../models/account-pagination-model-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { ClubPaginationModelResponseModel } from '../models/club-pagination-model-response-model';
import { DeleteInputModel } from '../models/delete-input-model';
import { HashtagListResponseModel } from '../models/hashtag-list-response-model';
import { HashtagPaginationModelResponseModel } from '../models/hashtag-pagination-model-response-model';
import { HashtagResponseModel } from '../models/hashtag-response-model';

@Injectable({
  providedIn: 'root',
})
export class HashtagService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithHashtagsPageGet
   */
  static readonly InlivewithHashtagsPageGetPath = '/inlivewith/Hashtags/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithHashtagsPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithHashtagsPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagsDeletedGet
   */
  static readonly InlivewithHashtagsDeletedGetPath = '/inlivewith/Hashtags/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsDeletedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsDeletedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsDeletedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsDeletedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithHashtagsDeletedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsDeletedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsDeletedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsDeletedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsDeletedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithHashtagsDeletedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagsActivatedGet
   */
  static readonly InlivewithHashtagsActivatedGetPath = '/inlivewith/Hashtags/Activated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsActivatedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsActivatedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsActivatedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsActivatedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithHashtagsActivatedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsActivatedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsActivatedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsActivatedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsActivatedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithHashtagsActivatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagsLikeNameNameGet
   */
  static readonly InlivewithHashtagsLikeNameNameGetPath = '/inlivewith/Hashtags/LikeName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsLikeNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsLikeNameNameGet$Plain$Response(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsLikeNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsLikeNameNameGet$Plain(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithHashtagsLikeNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsLikeNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsLikeNameNameGet$Json$Response(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsLikeNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsLikeNameNameGet$Json(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithHashtagsLikeNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagByNameNameGet
   */
  static readonly InlivewithHashtagByNameNameGetPath = '/inlivewith/Hashtag/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByNameNameGet$Plain$Response(params: {
    name: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByNameNameGet$Plain(params: {
    name: string;
    details?: boolean;
  }): Observable<HashtagResponseModel> {

    return this.inlivewithHashtagByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModel>) => r.body as HashtagResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByNameNameGet$Json$Response(params: {
    name: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByNameNameGet$Json(params: {
    name: string;
    details?: boolean;
  }): Observable<HashtagResponseModel> {

    return this.inlivewithHashtagByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModel>) => r.body as HashtagResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagByIdHashtagIdGet
   */
  static readonly InlivewithHashtagByIdHashtagIdGetPath = '/inlivewith/Hashtag/ById/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagByIdHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Plain$Response(params: {
    hashtagId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagByIdHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagByIdHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Plain(params: {
    hashtagId: string;
    details?: boolean;
  }): Observable<HashtagResponseModel> {

    return this.inlivewithHashtagByIdHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModel>) => r.body as HashtagResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagByIdHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Json$Response(params: {
    hashtagId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagByIdHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagByIdHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Json(params: {
    hashtagId: string;
    details?: boolean;
  }): Observable<HashtagResponseModel> {

    return this.inlivewithHashtagByIdHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModel>) => r.body as HashtagResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagClubsHashtagIdGet
   */
  static readonly InlivewithHashtagClubsHashtagIdGetPath = '/inlivewith/Hashtag/Clubs/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagClubsHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagClubsHashtagIdGet$Plain$Response(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagClubsHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagClubsHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagClubsHashtagIdGet$Plain(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithHashtagClubsHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagClubsHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagClubsHashtagIdGet$Json$Response(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagClubsHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagClubsHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagClubsHashtagIdGet$Json(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubPaginationModelResponseModel> {

    return this.inlivewithHashtagClubsHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubPaginationModelResponseModel>) => r.body as ClubPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagMembersHashtagIdGet
   */
  static readonly InlivewithHashtagMembersHashtagIdGetPath = '/inlivewith/Hashtag/Members/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagMembersHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagMembersHashtagIdGet$Plain$Response(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagMembersHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagMembersHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagMembersHashtagIdGet$Plain(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithHashtagMembersHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagMembersHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagMembersHashtagIdGet$Json$Response(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagMembersHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagMembersHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagMembersHashtagIdGet$Json(params: {
    hashtagId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountPaginationModelResponseModel> {

    return this.inlivewithHashtagMembersHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagCreatePost
   */
  static readonly InlivewithHashtagCreatePostPath = '/inlivewith/Hashtag/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagCreatePost$Plain$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<HashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagCreatePost$Plain(params?: {
    body?: Array<string>
  }): Observable<HashtagListResponseModel> {

    return this.inlivewithHashtagCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagListResponseModel>) => r.body as HashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagCreatePost$Json$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<HashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagCreatePost$Json(params?: {
    body?: Array<string>
  }): Observable<HashtagListResponseModel> {

    return this.inlivewithHashtagCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagListResponseModel>) => r.body as HashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagIsDeletedHashtagIdGet
   */
  static readonly InlivewithHashtagIsDeletedHashtagIdGetPath = '/inlivewith/Hashtag/IsDeleted/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagIsDeletedHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagIsDeletedHashtagIdGet$Plain$Response(params: {
    hashtagId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagIsDeletedHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagIsDeletedHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagIsDeletedHashtagIdGet$Plain(params: {
    hashtagId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithHashtagIsDeletedHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagIsDeletedHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagIsDeletedHashtagIdGet$Json$Response(params: {
    hashtagId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagIsDeletedHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagIsDeletedHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagIsDeletedHashtagIdGet$Json(params: {
    hashtagId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithHashtagIsDeletedHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagDeleteHashtagIdDelete
   */
  static readonly InlivewithHashtagDeleteHashtagIdDeletePath = '/inlivewith/Hashtag/Delete/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagDeleteHashtagIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagDeleteHashtagIdDelete$Plain$Response(params: {
    hashtagId: string;
    body?: DeleteInputModel
  }): Observable<StrictHttpResponse<HashtagResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagDeleteHashtagIdDeletePath, 'delete');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagDeleteHashtagIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagDeleteHashtagIdDelete$Plain(params: {
    hashtagId: string;
    body?: DeleteInputModel
  }): Observable<HashtagResponseModel> {

    return this.inlivewithHashtagDeleteHashtagIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModel>) => r.body as HashtagResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagDeleteHashtagIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagDeleteHashtagIdDelete$Json$Response(params: {
    hashtagId: string;
    body?: DeleteInputModel
  }): Observable<StrictHttpResponse<HashtagResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagDeleteHashtagIdDeletePath, 'delete');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagDeleteHashtagIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagDeleteHashtagIdDelete$Json(params: {
    hashtagId: string;
    body?: DeleteInputModel
  }): Observable<HashtagResponseModel> {

    return this.inlivewithHashtagDeleteHashtagIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModel>) => r.body as HashtagResponseModel)
    );
  }

}
