/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountBlockCheckingResponseModelResponseModel } from '../models/account-block-checking-response-model-response-model';
import { AccountFriendRequestResponseModelPaginationModelResponseModel } from '../models/account-friend-request-response-model-pagination-model-response-model';
import { AccountRelationshipResponseModelResponseModel } from '../models/account-relationship-response-model-response-model';
import { AccountResponseGeneralPaginationModelResponseModel } from '../models/account-response-general-pagination-model-response-model';
import { AccountWithRelationshipResponseModelPaginationModelResponseModel } from '../models/account-with-relationship-response-model-pagination-model-response-model';
import { AccountWithRelationshipResponseModelResponseModel } from '../models/account-with-relationship-response-model-response-model';
import { ApprovalFriendRequest } from '../models/approval-friend-request';
import { BooleanResponseModel } from '../models/boolean-response-model';

@Injectable({
  providedIn: 'root',
})
export class FriendRelationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithFriendRelationGetFriendListAccountRelatingIdGet
   */
  static readonly InlivewithFriendRelationGetFriendListAccountRelatingIdGetPath = '/inlivewith/FriendRelation/GetFriendList/{accountRelatingId}';

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Plain$Response(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetFriendListAccountRelatingIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Plain(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json$Response(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetFriendListAccountRelatingIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet
   */
  static readonly InlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGetPath = '/inlivewith/FriendRelation/GetFriendListWithCheckingRelationship/{accountRequestId}/{accountDestinationId}';

  /**
   * accountRequestId: The Account that being use on the app, accountDestinationId: The Account that show profile on.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet$Plain$Response(params: {
    accountRequestId: string;
    accountDestinationId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('accountDestinationId', params.accountDestinationId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * accountRequestId: The Account that being use on the app, accountDestinationId: The Account that show profile on.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet$Plain(params: {
    accountRequestId: string;
    accountDestinationId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountWithRelationshipResponseModelPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>) => r.body as AccountWithRelationshipResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * accountRequestId: The Account that being use on the app, accountDestinationId: The Account that show profile on.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet$Json$Response(params: {
    accountRequestId: string;
    accountDestinationId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('accountDestinationId', params.accountDestinationId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * accountRequestId: The Account that being use on the app, accountDestinationId: The Account that show profile on.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet$Json(params: {
    accountRequestId: string;
    accountDestinationId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountWithRelationshipResponseModelPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetFriendListWithCheckingRelationshipAccountRequestIdAccountDestinationIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>) => r.body as AccountWithRelationshipResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet
   */
  static readonly InlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGetPath = '/inlivewith/FriendRelation/GetSuggestFriendList/{accountRelatingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet$Plain$Response(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet$Plain(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet$Json$Response(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet$Json(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetSuggestFriendListAccountRelatingIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet
   */
  static readonly InlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGetPath = '/inlivewith/FriendRelation/GetAccountWithRelation/{accountRelatingId}/{accountRelatedId}';

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Plain$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<AccountWithRelationshipResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithRelationshipResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Plain(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<AccountWithRelationshipResponseModelResponseModel> {

    return this.inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithRelationshipResponseModelResponseModel>) => r.body as AccountWithRelationshipResponseModelResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Json$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<AccountWithRelationshipResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithRelationshipResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Json(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<AccountWithRelationshipResponseModelResponseModel> {

    return this.inlivewithFriendRelationGetAccountWithRelationAccountRelatingIdAccountRelatedIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithRelationshipResponseModelResponseModel>) => r.body as AccountWithRelationshipResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet
   */
  static readonly InlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGetPath = '/inlivewith/FriendRelation/CheckFriendRelationship/{accountRelatingId}/{accountRelatedId}';

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet$Plain$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet$Plain(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet$Json$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet$Json(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithFriendRelationCheckFriendRelationshipAccountRelatingIdAccountRelatedIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet
   */
  static readonly InlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGetPath = '/inlivewith/FriendRelation/CheckBlockRelationship/{accountRelatingId}/{accountRelatedId}';

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet$Plain$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet$Plain(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet$Json$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet$Json(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithFriendRelationCheckBlockRelationshipAccountRelatingIdAccountRelatedIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet
   */
  static readonly InlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGetPath = '/inlivewith/FriendRelation/CheckWhoIsBlock/{accountRequestId}/{accountId}';

  /**
   * AccountRequestId: The Account that being use on the app, AccountId: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet$Plain$Response(params: {
    accountRequestId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<AccountBlockCheckingResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountBlockCheckingResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRequestId: The Account that being use on the app, AccountId: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet$Plain(params: {
    accountRequestId: string;
    accountId: string;
  }): Observable<AccountBlockCheckingResponseModelResponseModel> {

    return this.inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountBlockCheckingResponseModelResponseModel>) => r.body as AccountBlockCheckingResponseModelResponseModel)
    );
  }

  /**
   * AccountRequestId: The Account that being use on the app, AccountId: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet$Json$Response(params: {
    accountRequestId: string;
    accountId: string;
  }): Observable<StrictHttpResponse<AccountBlockCheckingResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountBlockCheckingResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRequestId: The Account that being use on the app, AccountId: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet$Json(params: {
    accountRequestId: string;
    accountId: string;
  }): Observable<AccountBlockCheckingResponseModelResponseModel> {

    return this.inlivewithFriendRelationCheckWhoIsBlockAccountRequestIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountBlockCheckingResponseModelResponseModel>) => r.body as AccountBlockCheckingResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationAddFriendPost
   */
  static readonly InlivewithFriendRelationAddFriendPostPath = '/inlivewith/FriendRelation/AddFriend';

  /**
   * AccountRelating: The Account that sent the request, AccountRelated: The Account was requested.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationAddFriendPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationAddFriendPost$Plain$Response(params?: {
    accountRelatingId?: string;
    accountRelatedId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationAddFriendPostPath, 'post');
    if (params) {
      rb.query('accountRelatingId', params.accountRelatingId, {});
      rb.query('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that sent the request, AccountRelated: The Account was requested.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationAddFriendPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationAddFriendPost$Plain(params?: {
    accountRelatingId?: string;
    accountRelatedId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithFriendRelationAddFriendPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that sent the request, AccountRelated: The Account was requested.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationAddFriendPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationAddFriendPost$Json$Response(params?: {
    accountRelatingId?: string;
    accountRelatedId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationAddFriendPostPath, 'post');
    if (params) {
      rb.query('accountRelatingId', params.accountRelatingId, {});
      rb.query('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that sent the request, AccountRelated: The Account was requested.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationAddFriendPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationAddFriendPost$Json(params?: {
    accountRelatingId?: string;
    accountRelatedId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithFriendRelationAddFriendPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete
   */
  static readonly InlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDeletePath = '/inlivewith/FriendRelation/UnFriend/{accountRelatingId}/{accountRelatedId}';

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Plain$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<AccountRelationshipResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDeletePath, 'delete');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRelationshipResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Plain(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<AccountRelationshipResponseModelResponseModel> {

    return this.inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountRelationshipResponseModelResponseModel>) => r.body as AccountRelationshipResponseModelResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Json$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<AccountRelationshipResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDeletePath, 'delete');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRelationshipResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app, AccountRelated: The Account destination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Json(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<AccountRelationshipResponseModelResponseModel> {

    return this.inlivewithFriendRelationUnFriendAccountRelatingIdAccountRelatedIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountRelationshipResponseModelResponseModel>) => r.body as AccountRelationshipResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut
   */
  static readonly InlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPutPath = '/inlivewith/FriendRelation/BlockFriend/{accountRelatingId}/{accountRelatedId}';

  /**
   * AccountRelating: The Account that want to block, AccountRelated: The Account was block.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut$Plain$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<AccountRelationshipResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPutPath, 'put');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRelationshipResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that want to block, AccountRelated: The Account was block.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut$Plain(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<AccountRelationshipResponseModelResponseModel> {

    return this.inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountRelationshipResponseModelResponseModel>) => r.body as AccountRelationshipResponseModelResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that want to block, AccountRelated: The Account was block.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut$Json$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<AccountRelationshipResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPutPath, 'put');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRelationshipResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that want to block, AccountRelated: The Account was block.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut$Json(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<AccountRelationshipResponseModelResponseModel> {

    return this.inlivewithFriendRelationBlockFriendAccountRelatingIdAccountRelatedIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountRelationshipResponseModelResponseModel>) => r.body as AccountRelationshipResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut
   */
  static readonly InlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPutPath = '/inlivewith/FriendRelation/UnBlockFriend/{accountRelatingId}/{accountRelatedId}';

  /**
   * AccountRelating: The Account that being want to unblock, AccountRelated: The Account was blocked.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Plain$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<AccountRelationshipResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPutPath, 'put');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRelationshipResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being want to unblock, AccountRelated: The Account was blocked.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Plain(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<AccountRelationshipResponseModelResponseModel> {

    return this.inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountRelationshipResponseModelResponseModel>) => r.body as AccountRelationshipResponseModelResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that being want to unblock, AccountRelated: The Account was blocked.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Json$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<AccountRelationshipResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPutPath, 'put');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRelationshipResponseModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being want to unblock, AccountRelated: The Account was blocked.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Json(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<AccountRelationshipResponseModelResponseModel> {

    return this.inlivewithFriendRelationUnBlockFriendAccountRelatingIdAccountRelatedIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountRelationshipResponseModelResponseModel>) => r.body as AccountRelationshipResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet
   */
  static readonly InlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGetPath = '/inlivewith/FriendRelation/GetListBlockedAccount/{accountRelatingId}';

  /**
   * AccountRelating: The Account that proactive block .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Plain$Response(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that proactive block .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Plain(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that proactive block .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Json$Response(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that proactive block .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Json(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetListBlockedAccountAccountRelatingIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationApprovalRequestAddFriendPut
   */
  static readonly InlivewithFriendRelationApprovalRequestAddFriendPutPath = '/inlivewith/FriendRelation/ApprovalRequestAddFriend';

  /**
   * AccountRelating: The Account that waiting to be accepted, AccountRelated: The Account was requested.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationApprovalRequestAddFriendPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithFriendRelationApprovalRequestAddFriendPut$Plain$Response(params?: {
    body?: ApprovalFriendRequest
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationApprovalRequestAddFriendPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that waiting to be accepted, AccountRelated: The Account was requested.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationApprovalRequestAddFriendPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithFriendRelationApprovalRequestAddFriendPut$Plain(params?: {
    body?: ApprovalFriendRequest
  }): Observable<BooleanResponseModel> {

    return this.inlivewithFriendRelationApprovalRequestAddFriendPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that waiting to be accepted, AccountRelated: The Account was requested.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationApprovalRequestAddFriendPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithFriendRelationApprovalRequestAddFriendPut$Json$Response(params?: {
    body?: ApprovalFriendRequest
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationApprovalRequestAddFriendPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that waiting to be accepted, AccountRelated: The Account was requested.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationApprovalRequestAddFriendPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithFriendRelationApprovalRequestAddFriendPut$Json(params?: {
    body?: ApprovalFriendRequest
  }): Observable<BooleanResponseModel> {

    return this.inlivewithFriendRelationApprovalRequestAddFriendPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete
   */
  static readonly InlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDeletePath = '/inlivewith/FriendRelation/UnAddFriendRequest/{accountRelatingId}/{accountRelatedId}';

  /**
   * AccountRelating: The Account that was sent the request, AccountRelated: The Account that was received the request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete$Plain$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDeletePath, 'delete');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that was sent the request, AccountRelated: The Account that was received the request.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete$Plain(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that was sent the request, AccountRelated: The Account that was received the request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete$Json$Response(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDeletePath, 'delete');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.path('accountRelatedId', params.accountRelatedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that was sent the request, AccountRelated: The Account that was received the request.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete$Json(params: {
    accountRelatingId: string;
    accountRelatedId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithFriendRelationUnAddFriendRequestAccountRelatingIdAccountRelatedIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet
   */
  static readonly InlivewithFriendRelationGetFriendRequestAccountRelatingIdGetPath = '/inlivewith/FriendRelation/GetFriendRequest/{accountRelatingId}';

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet$Plain$Response(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountFriendRequestResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetFriendRequestAccountRelatingIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountFriendRequestResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet$Plain(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountFriendRequestResponseModelPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountFriendRequestResponseModelPaginationModelResponseModel>) => r.body as AccountFriendRequestResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet$Json$Response(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountFriendRequestResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetFriendRequestAccountRelatingIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountFriendRequestResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet$Json(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountFriendRequestResponseModelPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetFriendRequestAccountRelatingIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountFriendRequestResponseModelPaginationModelResponseModel>) => r.body as AccountFriendRequestResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet
   */
  static readonly InlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGetPath = '/inlivewith/FriendRelation/SearchFriendLikeNickName/{accountRequestId}/{nickName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Plain$Response(params: {
    accountRequestId: string;
    nickName: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('nickName', params.nickName, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Plain(params: {
    accountRequestId: string;
    nickName: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Json$Response(params: {
    accountRequestId: string;
    nickName: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('nickName', params.nickName, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Json(params: {
    accountRequestId: string;
    nickName: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet
   */
  static readonly InlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGetPath = '/inlivewith/FriendRelation/GetFriendListFromAdminClub/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Plain$Response(params: {
    adminId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Plain(params: {
    adminId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Json$Response(params: {
    adminId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Json(params: {
    adminId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetFriendListFromAdminClubAdminIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

}
