/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { MessageResponseModelPaginationModelResponseModel } from '../models/message-response-model-pagination-model-response-model';
import { MessageResponseModelResponseModel } from '../models/message-response-model-response-model';
import { MessageUpdate } from '../models/message-update';
import { ProcessFileModel } from '../models/process-file-model';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithMessagePageGet
   */
  static readonly InlivewithMessagePageGetPath = '/inlivewith/Message/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessagePageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessagePageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessagePageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessagePageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessagePageGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<MessageResponseModelPaginationModelResponseModel> {

    return this.inlivewithMessagePageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>) => r.body as MessageResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessagePageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessagePageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessagePageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessagePageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessagePageGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<MessageResponseModelPaginationModelResponseModel> {

    return this.inlivewithMessagePageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>) => r.body as MessageResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMessageCreatePost
   */
  static readonly InlivewithMessageCreatePostPath = '/inlivewith/Message/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageCreatePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithMessageCreatePost$Plain$Response(params?: {
    body?: {
'SenderId'?: string;
'ConversationId'?: string;
'Content'?: string;
'Files'?: Array<Blob>;
'Type'?: string;
}
  }): Observable<StrictHttpResponse<MessageResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageCreatePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithMessageCreatePost$Plain(params?: {
    body?: {
'SenderId'?: string;
'ConversationId'?: string;
'Content'?: string;
'Files'?: Array<Blob>;
'Type'?: string;
}
  }): Observable<MessageResponseModelResponseModel> {

    return this.inlivewithMessageCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelResponseModel>) => r.body as MessageResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageCreatePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithMessageCreatePost$Json$Response(params?: {
    body?: {
'SenderId'?: string;
'ConversationId'?: string;
'Content'?: string;
'Files'?: Array<Blob>;
'Type'?: string;
}
  }): Observable<StrictHttpResponse<MessageResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageCreatePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithMessageCreatePost$Json(params?: {
    body?: {
'SenderId'?: string;
'ConversationId'?: string;
'Content'?: string;
'Files'?: Array<Blob>;
'Type'?: string;
}
  }): Observable<MessageResponseModelResponseModel> {

    return this.inlivewithMessageCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelResponseModel>) => r.body as MessageResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMessageUpdateContentPost
   */
  static readonly InlivewithMessageUpdateContentPostPath = '/inlivewith/Message/UpdateContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageUpdateContentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageUpdateContentPost$Plain$Response(params?: {
    body?: MessageUpdate
  }): Observable<StrictHttpResponse<MessageResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageUpdateContentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageUpdateContentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageUpdateContentPost$Plain(params?: {
    body?: MessageUpdate
  }): Observable<MessageResponseModelResponseModel> {

    return this.inlivewithMessageUpdateContentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelResponseModel>) => r.body as MessageResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageUpdateContentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageUpdateContentPost$Json$Response(params?: {
    body?: MessageUpdate
  }): Observable<StrictHttpResponse<MessageResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageUpdateContentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageUpdateContentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageUpdateContentPost$Json(params?: {
    body?: MessageUpdate
  }): Observable<MessageResponseModelResponseModel> {

    return this.inlivewithMessageUpdateContentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelResponseModel>) => r.body as MessageResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMessageDeleteMessageIdDelete
   */
  static readonly InlivewithMessageDeleteMessageIdDeletePath = '/inlivewith/Message/Delete/{messageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageDeleteMessageIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageDeleteMessageIdDelete$Plain$Response(params: {
    messageId: string;
  }): Observable<StrictHttpResponse<MessageResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageDeleteMessageIdDeletePath, 'delete');
    if (params) {
      rb.path('messageId', params.messageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageDeleteMessageIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageDeleteMessageIdDelete$Plain(params: {
    messageId: string;
  }): Observable<MessageResponseModelResponseModel> {

    return this.inlivewithMessageDeleteMessageIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelResponseModel>) => r.body as MessageResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageDeleteMessageIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageDeleteMessageIdDelete$Json$Response(params: {
    messageId: string;
  }): Observable<StrictHttpResponse<MessageResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageDeleteMessageIdDeletePath, 'delete');
    if (params) {
      rb.path('messageId', params.messageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageDeleteMessageIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageDeleteMessageIdDelete$Json(params: {
    messageId: string;
  }): Observable<MessageResponseModelResponseModel> {

    return this.inlivewithMessageDeleteMessageIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelResponseModel>) => r.body as MessageResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMessageDeleteMessagesDelete
   */
  static readonly InlivewithMessageDeleteMessagesDeletePath = '/inlivewith/Message/DeleteMessages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageDeleteMessagesDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageDeleteMessagesDelete$Plain$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageDeleteMessagesDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageDeleteMessagesDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageDeleteMessagesDelete$Plain(params?: {
    body?: Array<string>
  }): Observable<BooleanResponseModel> {

    return this.inlivewithMessageDeleteMessagesDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageDeleteMessagesDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageDeleteMessagesDelete$Json$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageDeleteMessagesDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageDeleteMessagesDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageDeleteMessagesDelete$Json(params?: {
    body?: Array<string>
  }): Observable<BooleanResponseModel> {

    return this.inlivewithMessageDeleteMessagesDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMessageGetByIdMessageIdGet
   */
  static readonly InlivewithMessageGetByIdMessageIdGetPath = '/inlivewith/Message/GetById/{messageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageGetByIdMessageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByIdMessageIdGet$Plain$Response(params: {
    messageId: string;
  }): Observable<StrictHttpResponse<MessageResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageGetByIdMessageIdGetPath, 'get');
    if (params) {
      rb.path('messageId', params.messageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageGetByIdMessageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByIdMessageIdGet$Plain(params: {
    messageId: string;
  }): Observable<MessageResponseModelResponseModel> {

    return this.inlivewithMessageGetByIdMessageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelResponseModel>) => r.body as MessageResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageGetByIdMessageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByIdMessageIdGet$Json$Response(params: {
    messageId: string;
  }): Observable<StrictHttpResponse<MessageResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageGetByIdMessageIdGetPath, 'get');
    if (params) {
      rb.path('messageId', params.messageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageGetByIdMessageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByIdMessageIdGet$Json(params: {
    messageId: string;
  }): Observable<MessageResponseModelResponseModel> {

    return this.inlivewithMessageGetByIdMessageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelResponseModel>) => r.body as MessageResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMessageGetBySenderSenderIdGet
   */
  static readonly InlivewithMessageGetBySenderSenderIdGetPath = '/inlivewith/Message/GetBySender/{senderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageGetBySenderSenderIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetBySenderSenderIdGet$Plain$Response(params: {
    senderId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageGetBySenderSenderIdGetPath, 'get');
    if (params) {
      rb.path('senderId', params.senderId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageGetBySenderSenderIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetBySenderSenderIdGet$Plain(params: {
    senderId: string;
    page?: number;
    limit?: number;
  }): Observable<MessageResponseModelPaginationModelResponseModel> {

    return this.inlivewithMessageGetBySenderSenderIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>) => r.body as MessageResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageGetBySenderSenderIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetBySenderSenderIdGet$Json$Response(params: {
    senderId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageGetBySenderSenderIdGetPath, 'get');
    if (params) {
      rb.path('senderId', params.senderId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageGetBySenderSenderIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetBySenderSenderIdGet$Json(params: {
    senderId: string;
    page?: number;
    limit?: number;
  }): Observable<MessageResponseModelPaginationModelResponseModel> {

    return this.inlivewithMessageGetBySenderSenderIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>) => r.body as MessageResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMessageGetByConversationIdConversationIdAccountIdGet
   */
  static readonly InlivewithMessageGetByConversationIdConversationIdAccountIdGetPath = '/inlivewith/Message/GetByConversationId/{conversationId}/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageGetByConversationIdConversationIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByConversationIdConversationIdAccountIdGet$Plain$Response(params: {
    conversationId: string;
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageGetByConversationIdConversationIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('conversationId', params.conversationId, {});
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageGetByConversationIdConversationIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByConversationIdConversationIdAccountIdGet$Plain(params: {
    conversationId: string;
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<MessageResponseModelPaginationModelResponseModel> {

    return this.inlivewithMessageGetByConversationIdConversationIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>) => r.body as MessageResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageGetByConversationIdConversationIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByConversationIdConversationIdAccountIdGet$Json$Response(params: {
    conversationId: string;
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageGetByConversationIdConversationIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('conversationId', params.conversationId, {});
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageGetByConversationIdConversationIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByConversationIdConversationIdAccountIdGet$Json(params: {
    conversationId: string;
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<MessageResponseModelPaginationModelResponseModel> {

    return this.inlivewithMessageGetByConversationIdConversationIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>) => r.body as MessageResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMessageGetByDateConversationIdStartDateEndDateGet
   */
  static readonly InlivewithMessageGetByDateConversationIdStartDateEndDateGetPath = '/inlivewith/Message/GetByDate/{conversationId}/{startDate}/{endDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageGetByDateConversationIdStartDateEndDateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByDateConversationIdStartDateEndDateGet$Plain$Response(params: {
    conversationId: string;
    startDate: string;
    endDate: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageGetByDateConversationIdStartDateEndDateGetPath, 'get');
    if (params) {
      rb.path('conversationId', params.conversationId, {});
      rb.path('startDate', params.startDate, {});
      rb.path('endDate', params.endDate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageGetByDateConversationIdStartDateEndDateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByDateConversationIdStartDateEndDateGet$Plain(params: {
    conversationId: string;
    startDate: string;
    endDate: string;
    page?: number;
    limit?: number;
  }): Observable<MessageResponseModelPaginationModelResponseModel> {

    return this.inlivewithMessageGetByDateConversationIdStartDateEndDateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>) => r.body as MessageResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageGetByDateConversationIdStartDateEndDateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByDateConversationIdStartDateEndDateGet$Json$Response(params: {
    conversationId: string;
    startDate: string;
    endDate: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageGetByDateConversationIdStartDateEndDateGetPath, 'get');
    if (params) {
      rb.path('conversationId', params.conversationId, {});
      rb.path('startDate', params.startDate, {});
      rb.path('endDate', params.endDate, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageGetByDateConversationIdStartDateEndDateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithMessageGetByDateConversationIdStartDateEndDateGet$Json(params: {
    conversationId: string;
    startDate: string;
    endDate: string;
    page?: number;
    limit?: number;
  }): Observable<MessageResponseModelPaginationModelResponseModel> {

    return this.inlivewithMessageGetByDateConversationIdStartDateEndDateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageResponseModelPaginationModelResponseModel>) => r.body as MessageResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithMessageProcessMediaMessageMediaMessageIdPut
   */
  static readonly InlivewithMessageProcessMediaMessageMediaMessageIdPutPath = '/inlivewith/Message/ProcessMediaMessage/{mediaMessageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageProcessMediaMessageMediaMessageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageProcessMediaMessageMediaMessageIdPut$Plain$Response(params: {
    mediaMessageId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageProcessMediaMessageMediaMessageIdPutPath, 'put');
    if (params) {
      rb.path('mediaMessageId', params.mediaMessageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageProcessMediaMessageMediaMessageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageProcessMediaMessageMediaMessageIdPut$Plain(params: {
    mediaMessageId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithMessageProcessMediaMessageMediaMessageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithMessageProcessMediaMessageMediaMessageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageProcessMediaMessageMediaMessageIdPut$Json$Response(params: {
    mediaMessageId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessageService.InlivewithMessageProcessMediaMessageMediaMessageIdPutPath, 'put');
    if (params) {
      rb.path('mediaMessageId', params.mediaMessageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithMessageProcessMediaMessageMediaMessageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithMessageProcessMediaMessageMediaMessageIdPut$Json(params: {
    mediaMessageId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithMessageProcessMediaMessageMediaMessageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
