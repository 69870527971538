/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CelebrityAliasResponseModelPaginationModelResponseModel } from '../models/celebrity-alias-response-model-pagination-model-response-model';
import { CelebrityAnalyzeActivitiesResponseModelResponseModel } from '../models/celebrity-analyze-activities-response-model-response-model';
import { CelebrityAnalyzeInliverResponseModelResponseModel } from '../models/celebrity-analyze-inliver-response-model-response-model';
import { ClubResponseWithTotalMemberModelPaginationModelResponseModel } from '../models/club-response-with-total-member-model-pagination-model-response-model';
import { PostResponseModelPaginationModelResponseModel } from '../models/post-response-model-pagination-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class CelebrityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithCelebrityGetTheClubHaveTheMostMemberGet
   */
  static readonly InlivewithCelebrityGetTheClubHaveTheMostMemberGetPath = '/inlivewith/Celebrity/GetTheClubHaveTheMostMember';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubHaveTheMostMemberGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubHaveTheMostMemberGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubHaveTheMostMemberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet
   */
  static readonly InlivewithCelebrityGetTheMostRecentClubWithRecentPostsGetPath = '/inlivewith/Celebrity/GetTheMostRecentClubWithRecentPosts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheMostRecentClubWithRecentPostsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheMostRecentClubWithRecentPostsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheMostRecentClubWithRecentPostsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTheClubsHaveTheMostActivesGet
   */
  static readonly InlivewithCelebrityGetTheClubsHaveTheMostActivesGetPath = '/inlivewith/Celebrity/GetTheClubsHaveTheMostActives';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubsHaveTheMostActivesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubsHaveTheMostActivesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubsHaveTheMostActivesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetListCelebrityGet
   */
  static readonly InlivewithCelebrityGetListCelebrityGetPath = '/inlivewith/Celebrity/GetListCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListCelebrityGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListCelebrityGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListCelebrityGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListCelebrityGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListCelebrityGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListCelebrityGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListCelebrityGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListCelebrityGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListCelebrityGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGetPath = '/inlivewith/Celebrity/GetTotalInliverForCelebrity/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Plain(params: {
    celebrityId: string;
  }): Observable<CelebrityAnalyzeInliverResponseModelResponseModel> {

    return this.inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>) => r.body as CelebrityAnalyzeInliverResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Json(params: {
    celebrityId: string;
  }): Observable<CelebrityAnalyzeInliverResponseModelResponseModel> {

    return this.inlivewithCelebrityGetTotalInliverForCelebrityCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAnalyzeInliverResponseModelResponseModel>) => r.body as CelebrityAnalyzeInliverResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGetPath = '/inlivewith/Celebrity/GetTheClubHaveTheMostMemberThatCelebLinked/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Plain(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Json(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTheClubHaveTheMostMemberThatCelebLinkedCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGetPath = '/inlivewith/Celebrity/GetTotalAnalyzeForCelebrity/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Plain(params: {
    celebrityId: string;
  }): Observable<CelebrityAnalyzeActivitiesResponseModelResponseModel> {

    return this.inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>) => r.body as CelebrityAnalyzeActivitiesResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
  }): Observable<StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Json(params: {
    celebrityId: string;
  }): Observable<CelebrityAnalyzeActivitiesResponseModelResponseModel> {

    return this.inlivewithCelebrityGetTotalAnalyzeForCelebrityCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAnalyzeActivitiesResponseModelResponseModel>) => r.body as CelebrityAnalyzeActivitiesResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGetPath = '/inlivewith/Celebrity/GetTopPublicationForCelebrity/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Plain(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Json(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetTopPublicationForCelebrityCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

}
