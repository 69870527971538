/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { StageGuidsInputModel } from '../models/stage-guids-input-model';
import { StageInputModel } from '../models/stage-input-model';
import { StageObjectCreateInputModel } from '../models/stage-object-create-input-model';
import { StagePaginationModelResponseModel } from '../models/stage-pagination-model-response-model';
import { StageResponseModel } from '../models/stage-response-model';
import { StageStringInputModel } from '../models/stage-string-input-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class StageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithStagesPageGet
   */
  static readonly InlivewithStagesPageGetPath = '/inlivewith/Stages/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPageGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesPageGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesDefaultGet
   */
  static readonly InlivewithStagesDefaultGetPath = '/inlivewith/Stages/Default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesDefaultGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDefaultGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesDefaultGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesDefaultGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDefaultGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesDefaultGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesDefaultGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDefaultGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesDefaultGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesDefaultGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDefaultGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesDefaultGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesNonDefaultGet
   */
  static readonly InlivewithStagesNonDefaultGetPath = '/inlivewith/Stages/NonDefault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesNonDefaultGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesNonDefaultGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesNonDefaultGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesNonDefaultGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesNonDefaultGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesNonDefaultGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesNonDefaultGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesNonDefaultGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesNonDefaultGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesNonDefaultGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesNonDefaultGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesNonDefaultGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesDeletedGet
   */
  static readonly InlivewithStagesDeletedGetPath = '/inlivewith/Stages/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesDeletedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDeletedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesDeletedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDeletedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesDeletedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesDeletedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDeletedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesDeletedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesDeletedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesDeletedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesDeletedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesActivatedGet
   */
  static readonly InlivewithStagesActivatedGetPath = '/inlivewith/Stages/Activated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesActivatedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesActivatedGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesActivatedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesActivatedGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesActivatedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesActivatedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesActivatedGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesActivatedGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesActivatedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesActivatedGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesActivatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagesByClubIdClubIdGet
   */
  static readonly InlivewithStagesByClubIdClubIdGetPath = '/inlivewith/Stages/ByClubId/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesByClubIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesByClubIdClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesByClubIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesByClubIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagesByClubIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<StagePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagesByClubIdClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StagePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagesByClubIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStagesByClubIdClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StagePaginationModelResponseModel> {

    return this.inlivewithStagesByClubIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StagePaginationModelResponseModel>) => r.body as StagePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageByIdStageIdGet
   */
  static readonly InlivewithStageByIdStageIdGetPath = '/inlivewith/Stage/ById/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageByIdStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByIdStageIdGet$Plain$Response(params: {
    stageId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageByIdStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageByIdStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByIdStageIdGet$Plain(params: {
    stageId: string;
    details?: boolean;
  }): Observable<StageResponseModel> {

    return this.inlivewithStageByIdStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageByIdStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByIdStageIdGet$Json$Response(params: {
    stageId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageByIdStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageByIdStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByIdStageIdGet$Json(params: {
    stageId: string;
    details?: boolean;
  }): Observable<StageResponseModel> {

    return this.inlivewithStageByIdStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageByNameNameGet
   */
  static readonly InlivewithStageByNameNameGetPath = '/inlivewith/Stage/ByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageByNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByNameNameGet$Plain$Response(params: {
    name: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageByNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByNameNameGet$Plain(params: {
    name: string;
    details?: boolean;
  }): Observable<StageResponseModel> {

    return this.inlivewithStageByNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageByNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByNameNameGet$Json$Response(params: {
    name: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageByNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageByNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageByNameNameGet$Json(params: {
    name: string;
    details?: boolean;
  }): Observable<StageResponseModel> {

    return this.inlivewithStageByNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageHasAccessAccountIdClubIdStageIdGet
   */
  static readonly InlivewithStageHasAccessAccountIdClubIdStageIdGetPath = '/inlivewith/Stage/HasAccess/{accountId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageHasAccessAccountIdClubIdStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageHasAccessAccountIdClubIdStageIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageHasAccessAccountIdClubIdStageIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageHasAccessAccountIdClubIdStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageHasAccessAccountIdClubIdStageIdGet$Plain(params: {
    accountId: string;
    clubId: string;
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageHasAccessAccountIdClubIdStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageHasAccessAccountIdClubIdStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageHasAccessAccountIdClubIdStageIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageHasAccessAccountIdClubIdStageIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageHasAccessAccountIdClubIdStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageHasAccessAccountIdClubIdStageIdGet$Json(params: {
    accountId: string;
    clubId: string;
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageHasAccessAccountIdClubIdStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageCreateAdminIdClubIdPost
   */
  static readonly InlivewithStageCreateAdminIdClubIdPostPath = '/inlivewith/Stage/Create/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageCreateAdminIdClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageCreateAdminIdClubIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: StageObjectCreateInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageCreateAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageCreateAdminIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageCreateAdminIdClubIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    body?: StageObjectCreateInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageCreateAdminIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageCreateAdminIdClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageCreateAdminIdClubIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: StageObjectCreateInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageCreateAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageCreateAdminIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageCreateAdminIdClubIdPost$Json(params: {
    adminId: string;
    clubId: string;
    body?: StageObjectCreateInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageCreateAdminIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageNameStageIdGet
   */
  static readonly InlivewithStageNameStageIdGetPath = '/inlivewith/Stage/Name/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageNameStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageNameStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageNameStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageNameStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageNameStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithStageNameStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageNameStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageNameStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageNameStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageNameStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageNameStageIdGet$Json(params: {
    stageId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithStageNameStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageNameAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStageNameAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/Name/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageNameAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageNameAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageNameAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageNameAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageNameAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageNameAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageNameAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageNameAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageNameAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageNameAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageNameAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageNameAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageDescriptionStageIdGet
   */
  static readonly InlivewithStageDescriptionStageIdGetPath = '/inlivewith/Stage/Description/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDescriptionStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageDescriptionStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDescriptionStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDescriptionStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageDescriptionStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithStageDescriptionStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDescriptionStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageDescriptionStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDescriptionStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDescriptionStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageDescriptionStageIdGet$Json(params: {
    stageId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithStageDescriptionStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageDescriptionAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStageDescriptionAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/Description/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDescriptionAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDescriptionAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDescriptionAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDescriptionAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDescriptionAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageDescriptionAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDescriptionAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDescriptionAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDescriptionAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDescriptionAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDescriptionAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageStringInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageDescriptionAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageIsDefaultStageIdGet
   */
  static readonly InlivewithStageIsDefaultStageIdGetPath = '/inlivewith/Stage/IsDefault/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsDefaultStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDefaultStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsDefaultStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsDefaultStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDefaultStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsDefaultStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsDefaultStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDefaultStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsDefaultStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsDefaultStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDefaultStageIdGet$Json(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsDefaultStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageDefaultAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStageDefaultAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/Default/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDefaultAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDefaultAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDefaultAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDefaultAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDefaultAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageDefaultAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDefaultAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDefaultAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDefaultAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDefaultAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDefaultAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageDefaultAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageIsPublicStageIdGet
   */
  static readonly InlivewithStageIsPublicStageIdGetPath = '/inlivewith/Stage/IsPublic/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsPublicStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPublicStageIdGet$Plain$Response(params: {
    clubId?: string;
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsPublicStageIdGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsPublicStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPublicStageIdGet$Plain(params: {
    clubId?: string;
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsPublicStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsPublicStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPublicStageIdGet$Json$Response(params: {
    clubId?: string;
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsPublicStageIdGetPath, 'get');
    if (params) {
      rb.query('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsPublicStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsPublicStageIdGet$Json(params: {
    clubId?: string;
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsPublicStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagePrivateAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStagePrivateAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/Private/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagePrivateAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePrivateAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagePrivateAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagePrivateAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePrivateAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStagePrivateAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagePrivateAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePrivateAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagePrivateAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagePrivateAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePrivateAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStagePrivateAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStagePublicAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStagePublicAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/Public/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagePublicAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePublicAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagePublicAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagePublicAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePublicAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStagePublicAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStagePublicAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePublicAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStagePublicAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStagePublicAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStagePublicAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStagePublicAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageAccessLevelIsAnonymousStageIdGet
   */
  static readonly InlivewithStageAccessLevelIsAnonymousStageIdGetPath = '/inlivewith/Stage/AccessLevel/IsAnonymous/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelIsAnonymousStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsAnonymousStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelIsAnonymousStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelIsAnonymousStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsAnonymousStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageAccessLevelIsAnonymousStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelIsAnonymousStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsAnonymousStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelIsAnonymousStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelIsAnonymousStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsAnonymousStageIdGet$Json(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageAccessLevelIsAnonymousStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageAccessLevelIsPlusStageIdGet
   */
  static readonly InlivewithStageAccessLevelIsPlusStageIdGetPath = '/inlivewith/Stage/AccessLevel/IsPlus/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelIsPlusStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsPlusStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelIsPlusStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelIsPlusStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsPlusStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageAccessLevelIsPlusStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelIsPlusStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsPlusStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelIsPlusStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelIsPlusStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageAccessLevelIsPlusStageIdGet$Json(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageAccessLevelIsPlusStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/AccessLevel/Anonymous/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageAccessLevelAnonymousAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut
   */
  static readonly InlivewithStageAccessLevelPlusAdminIdClubIdStageIdPutPath = '/inlivewith/Stage/AccessLevel/Plus/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelPlusAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageAccessLevelPlusAdminIdClubIdStageIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageAccessLevelPlusAdminIdClubIdStageIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageMembersAdminIdClubIdStageIdPost
   */
  static readonly InlivewithStageMembersAdminIdClubIdStageIdPostPath = '/inlivewith/Stage/Members/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageMembersAdminIdClubIdStageIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageMembersAdminIdClubIdStageIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageMembersAdminIdClubIdStageIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageMembersAdminIdClubIdStageIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageMembersAdminIdClubIdStageIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageMembersAdminIdClubIdStageIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageMembersAdminIdClubIdStageIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageMembersAdminIdClubIdStageIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageMembersAdminIdClubIdStageIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageMembersAdminIdClubIdStageIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageMembersAdminIdClubIdStageIdPost$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageGuidsInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageMembersAdminIdClubIdStageIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageIsDeletedStageIdGet
   */
  static readonly InlivewithStageIsDeletedStageIdGetPath = '/inlivewith/Stage/IsDeleted/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsDeletedStageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDeletedStageIdGet$Plain$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsDeletedStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsDeletedStageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDeletedStageIdGet$Plain(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsDeletedStageIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageIsDeletedStageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDeletedStageIdGet$Json$Response(params: {
    stageId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageIsDeletedStageIdGetPath, 'get');
    if (params) {
      rb.path('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageIsDeletedStageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithStageIsDeletedStageIdGet$Json(params: {
    stageId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithStageIsDeletedStageIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithStageDeleteAdminIdClubIdStageIdDelete
   */
  static readonly InlivewithStageDeleteAdminIdClubIdStageIdDeletePath = '/inlivewith/Stage/Delete/{adminId}/{clubId}/{stageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDeleteAdminIdClubIdStageIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDeleteAdminIdClubIdStageIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDeleteAdminIdClubIdStageIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDeleteAdminIdClubIdStageIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDeleteAdminIdClubIdStageIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageDeleteAdminIdClubIdStageIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StrictHttpResponse<StageResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, StageService.InlivewithStageDeleteAdminIdClubIdStageIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('stageId', params.stageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StageResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    stageId: string;
    body?: StageInputModel
  }): Observable<StageResponseModel> {

    return this.inlivewithStageDeleteAdminIdClubIdStageIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StageResponseModel>) => r.body as StageResponseModel)
    );
  }

}
