import { Component, Input, OnInit } from '@angular/core';
import { Account, AccountResponseGeneral, Hashtag } from 'src/app/core/api/be/models';
import { AccountService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnInit {
  @Input() account!: AccountResponseGeneral;
  defaultAvatar = defaultAvatar;
  hashtag!: Hashtag[];

  constructor(private _accountService: AccountService) {}

  ngOnInit(): void {
    this.getHashTagByAccount();
  }

  getHashTagByAccount() {
    this._accountService
      .inlivewithAccountHashtagsAccountIdGet$Json({
        accountId: this.account!.id!,
      })
      .subscribe((res) => {
        this.hashtag = res.data?.page!;
      });
  }
}
