/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { Int32ResponseModel } from '../models/int-32-response-model';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithDatabaseCreatePost
   */
  static readonly InlivewithDatabaseCreatePostPath = '/inlivewith/Database/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithDatabaseCreatePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseCreatePost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.InlivewithDatabaseCreatePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithDatabaseCreatePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseCreatePost$Plain(params?: {
  }): Observable<BooleanResponseModel> {

    return this.inlivewithDatabaseCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithDatabaseCreatePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseCreatePost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.InlivewithDatabaseCreatePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithDatabaseCreatePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseCreatePost$Json(params?: {
  }): Observable<BooleanResponseModel> {

    return this.inlivewithDatabaseCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithDatabaseDeleteDelete
   */
  static readonly InlivewithDatabaseDeleteDeletePath = '/inlivewith/Database/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithDatabaseDeleteDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseDeleteDelete$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.InlivewithDatabaseDeleteDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithDatabaseDeleteDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseDeleteDelete$Plain(params?: {
  }): Observable<BooleanResponseModel> {

    return this.inlivewithDatabaseDeleteDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithDatabaseDeleteDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseDeleteDelete$Json$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.InlivewithDatabaseDeleteDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithDatabaseDeleteDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseDeleteDelete$Json(params?: {
  }): Observable<BooleanResponseModel> {

    return this.inlivewithDatabaseDeleteDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithDatabaseClearDelete
   */
  static readonly InlivewithDatabaseClearDeletePath = '/inlivewith/Database/Clear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithDatabaseClearDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseClearDelete$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.InlivewithDatabaseClearDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithDatabaseClearDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseClearDelete$Plain(params?: {
  }): Observable<Int32ResponseModel> {

    return this.inlivewithDatabaseClearDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithDatabaseClearDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseClearDelete$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.InlivewithDatabaseClearDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithDatabaseClearDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithDatabaseClearDelete$Json(params?: {
  }): Observable<Int32ResponseModel> {

    return this.inlivewithDatabaseClearDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

}
