<div class="card">
  <div class="zone-avatar" [id]="inliver.id" [ngClass]="{selected: inliver.id == accountId}">
    <img class="fit-size" [src]="inliver.avatar ?? defaultAvatar">
  </div>
  <div class="mt-2">
    <div class="text-bold text-gray">
      {{inliver.nickName}}
    </div>
  </div>
  <div class="mt-2">
    {{inliver.totalMutualCluds}} <span>Clubs en commun</span>
  </div>
</div>
