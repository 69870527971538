import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CarouselModule } from 'primeng/carousel';
import { ChipsModule } from 'primeng/chips';
import { BadgeModule } from 'primeng/badge';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { TreeSelectModule } from 'primeng/treeselect';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { GalleriaModule } from 'primeng/galleria';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { ChartModule } from 'primeng/chart';
import { TimelineModule } from 'primeng/timeline';
import { FileUploadModule } from 'primeng/fileupload';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { RippleModule } from 'primeng/ripple';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ImageModule } from 'primeng/image';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';

const ListModule = [
  ButtonModule,
  SplitButtonModule,
  CarouselModule,
  ChipsModule,
  BadgeModule,
  DropdownModule,
  DialogModule,
  InputSwitchModule,
  MultiSelectModule,
  InputTextareaModule,
  InputNumberModule,
  RadioButtonModule,
  CalendarModule,
  TreeSelectModule,
  MenuModule,
  TabViewModule,
  CheckboxModule,
  GalleriaModule,
  InputTextModule,
  SliderModule,
  TimelineModule,
  ChartModule,
  FileUploadModule,
  SkeletonModule,
  ProgressSpinnerModule,
  AutoCompleteModule,
  TableModule,
  TabMenuModule,
  ImageModule,
  TabMenuModule,
  ToggleButtonModule,
  SlideMenuModule,
  RippleModule,
  OverlayPanelModule,
  ImageModule,
  ToastModule,
  SidebarModule,
  TableModule
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ListModule],
  exports: [ListModule],
})
export class PrimengModule {}
